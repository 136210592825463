


type Props = {
    component: any
}

export default function Meets(props: Props) {



    return <div className='Brands'>
        {
            props?.component?.content?.map((file: any, index: number) => {
                return <div key={index} className='brand'>
                    <div className="image" style={{ backgroundImage: `url(/graphics/images/brands/${file.brand}.jpg)` }}></div>
                    <a className="web" href={file.web}>{file.web}</a>
                </div>
            })
        }
    </div >
}


