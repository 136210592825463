

import { Widget } from "../../componets/Widget";
import Table from "../../componets/Table";
import Tabs from "../../componets/Tabs";

function Projects() {
    return (

        <>
            <h1>Archivos</h1>
            <h2>Documentación, contratos y todo tipo de archivo</h2>
            <Tabs tabs={[{
                title: "Desarrollo",
                path: '/archives/budgeted',
                icon: 'las la-dollar-sign'
            },
            {
                title: "Contratos",
                path: '/projects/pending',
                icon: 'las la-clock'
            }

            ]} />
            <div className="Widgets">
                <Widget title="Archivos">
                    <Table columns={
                        [
                            {
                                name: 'company',
                                title: "Compañía",
                                width: 200

                            },
                            {
                                name: 'name',
                                title: "Proyecto",
                                width: 200

                            },
                            {
                                name: 'email',
                                title: "Responsable",
                                width: 300
                            },
                            {
                                name: 'type',
                                title: "Tipo",
                                width: 300
                            },
                            {
                                name: 'status',
                                title: "Estado",
                                width: 90
                            },
                            {
                                name: 'creation',
                                title: "Creación",
                                width: 90
                            },
                            {
                                name: 'since',
                                title: "Hace...",
                                width: 90
                            }

                        ]
                    } items={[{
                        company: "Biolan",
                        name: "Salbatore Munuera",
                        description: "Campaña de software para la gestión de pacientes médicos",
                        projects: 25,
                        budgets: 20,
                        developments: 10,
                        since: '3 días'
                    }]} />
                </Widget>

            </div>
        </>

    );
}

export default Projects
