

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Accounts() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header title="Accountos" description="Listado de oportunidades de desarrollo" buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/account?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    path={'/account'}
                    method={'post'}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Titular",
                                type: 'text',
                            },
                            {
                                name: 'bank',
                                title: "Banco",
                                type: 'text',
                            },
                            {
                                name: 'iban',
                                title: "Iban",
                                type: 'text',
                            },
                            {
                                name: 'swift',
                                title: "SWIFT",
                                type: 'text',
                            },
                            {
                                name: 'bic',
                                title: "BIC",
                                type: 'text',
                            },
                            {
                                name: 'country',
                                title: "País",
                                type: 'text',
                            },
                            {
                                name: 'instructions',
                                title: "Instrucciones de pago",
                                type: 'text',
                            }
                        ]
                    } />}
                {update && <Form
                    path={'/account?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [{
                            name: 'name',
                            title: "Titular",
                            type: 'text',
                        },
                        {
                            name: 'bank',
                            title: "Banco",
                            type: 'text',
                        },
                        {
                            name: 'iban',
                            title: "Iban",
                            type: 'text',
                        },
                        {
                            name: 'swift',
                            title: "SWIFT",
                            type: 'text',
                        },
                        {
                            name: 'bic',
                            title: "BIC",
                            type: 'text',
                        },
                        {
                            name: 'country',
                            title: "País",
                            type: 'text',
                        },
                        {
                            name: 'instructions',
                            title: "Instrucciones de pago",
                            type: 'text',
                        }
                        ]
                    } />}
                <Widget title="Proyectos">
                    <Table reload={tableKey}
                        path={'/account'}
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 150

                                },
                                {
                                    name: 'bank',
                                    title: "Banco",
                                    width: 300

                                }, {
                                    name: 'iban',
                                    title: "IBAN",
                                    width: 300

                                },

                                {
                                    name: 'creation',
                                    title: "Creación",
                                    type: 'date',
                                    width: 200
                                },

                                {
                                    name: 'creation',
                                    type: 'since',
                                    title: "Creación",
                                    width: 200
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Accounts
