
import { connect } from "react-redux";
import { setLanguage, setProfile, setProfileGithub, setScheme, setSchemeGithub, setLanguages, setLanguagesGithub } from '../redux/actions'

// Schemes:
import Menu from "../componets/Menu";


import {
    Routes,
    Route,
    useResolvedPath,
    useMatch
} from "react-router-dom";
import Users from "./scenes/users/scenes/home";
import Resources from "./scenes/resources/scenes/home";
import Positions from "./scenes/users/scenes/positions";
import Organizations from "./scenes/organizations/scenes/home";
import OrganizationsMeets from "./scenes/organizations/scenes/meets";
import Movements from "./scenes/accounting/scenes/movements";
import Salaries from "./scenes/accounting/scenes/salaries";
import Accounting from "./scenes/accounting/scenes/home";
import Goals from "./scenes/accounting/scenes/goals";
import Treasures from "./scenes/accounting/scenes/treasures";

import Brands from "./scenes/brands/scenes/home";
import Accounts from "./scenes/brands/scenes/accounts";
import Dashboard from "./scenes/dashboard";
import Campaigns from "./scenes/campaigns";
import Calendar from "./scenes/calendar";
import Tasks from "./scenes/tasks/home";
import Projects from "./scenes/projects/scenes/home";
import Technologies from "./scenes/technologies/scenes/home";
import Technological from "./scenes/projects/scenes/technological";
import Stories from "./scenes/projects/scenes/stories";
import Files from "./scenes/projects/scenes/files";
import Budgets from "./scenes/projects/scenes/budgets";
import Invoices from "./scenes/accounting/scenes/invoices";

import Archives from "./scenes/archives";
import Contacts from "./scenes/organizations/scenes/contacts";
import Collections from "./scenes/organizations/scenes/collections";
import Integrations from "./scenes/integrations";
import Objects from "./scenes/projects/scenes/objects";
import Specifications from "./scenes/projects/scenes/specifications";
import Meets from "./scenes/projects/scenes/meets";
import Requeriments from "./scenes/projects/scenes/requeriments";
import Audits from "./scenes/projects/scenes/audits";
import Cases from "./scenes/projects/scenes/cases";
import Products from "./scenes/products/scenes/home";
import Productions from "./scenes/projects/scenes/productions";
// connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setLanguage, setProfile, setProfileGithub, setScheme, setSchemeGithub, setLanguages, setLanguagesGithub
}
interface DispatchProps {
    user: any,
    organization: any,
    repository: any,
    scheme: any,
    language: any,
    theme: any,
    setProfile: (s: any) => void
    setLanguage: (s: any) => void
    setProfileGithub: (s: any) => void
    setScheme: (s: any) => void
    setSchemeGithub: (s: any) => void
    setLanguages: (s: any) => void
    setLanguagesGithub: (s: any) => void


}
type Props = DispatchProps
function Scenes(props: Props) {
    //  pathname is calendar
    let resolved = useResolvedPath('/calendar');
    let match = useMatch({ path: resolved.pathname, end: false });
    let className: string = 'Content'

    if (match) className = "Content-big"

    return (

        <div className="App">

            <Menu brand={'alicunde'} logotype={{ title: 'Alicunde', subtitle: 'Manager' }} />


            {props.user && <div className={className}>
                <Routes>

                    <Route path="/resources" element={<Resources />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/organizations" element={<Organizations />} />
                    <Route path="/organizations/" element={<Organizations />} />
                    <Route path="/organizations" element={<Organizations />} />
                    <Route path="/organizations" element={<Organizations />} />
                    <Route path="/organizations" element={<Organizations />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/positions" element={<Positions />} />

                    <Route path="/accounting" element={<Accounting />} />
                    <Route path="/accounting/goals" element={<Goals />} />
                    <Route path="/accounting/movements" element={<Movements />} />
                    <Route path="/accounting/salaries" element={<Salaries />} />
                    <Route path="/accounting/treasures" element={<Treasures />} />

                    <Route path="/brands" element={<Brands />} />
                    <Route path="/brands/accounts" element={<Accounts />} />
                    <Route path="/organizations" element={<Organizations />} />
                    <Route path="/organizations/contacts" element={<Contacts />} />
                    <Route path="/organizations/collections" element={<Collections />} />
                    <Route path="/organizations/meets" element={<OrganizationsMeets />} />

                    <Route path="/accounts" element={<Accounts />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/projects/" element={<Projects />} />
                    <Route path="/projects/budgeted" element={<Projects />} />
                    <Route path="/projects/pending" element={<Projects />} />
                    <Route path="/projects/development" element={<Projects />} />
                    <Route path="/projects/finished" element={<Projects />} />
                    <Route path="/projects/tracking" element={<Projects />} />
                    <Route path="/projects/rejected" element={<Projects />} />

                    <Route path="/projects/archives" element={<Archives />} />
                    <Route path="/projects/files" element={<Files />} />
                    <Route path="/projects/integrations" element={<Integrations />} />
                    <Route path="/projects/objects" element={<Objects />} />
                    <Route path="/technologies" element={<Technologies />} />
                    <Route path="/projects/technological" element={<Technological />} />
                    <Route path="/projects/productions" element={<Productions />} />
                    <Route path="/projects/integrations" element={<Integrations />} />
                    <Route path="/projects/budgets" element={<Budgets />} />
                    <Route path="/projects/specifications" element={<Specifications />} />
                    <Route path="/projects/requeriments" element={<Requeriments />} />
                    <Route path="/projects/audits" element={<Audits />} />
                    <Route path="/projects/stories" element={<Stories />} />

                    <Route path="/projects/cases" element={<Cases />} />

                    <Route path="/projects/meets" element={<Meets />} />
                    <Route path="/tasks" element={<Tasks />} />
                    <Route path="/campaigns" element={<Campaigns />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/accounting/invoices" element={<Invoices />} />

                </Routes>

            </div>}
            {
                !props.user && <div className="Video">
                    <video autoPlay loop muted>
                        <source src="/graphics/videos/alicunde.mp4" type="video/mp4" />
                    </video>


                </div>
            }

        </div>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Scenes)
