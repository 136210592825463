

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";
import Cards from "../../../../componets/Cards";

function Projects() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    const [update, setUpdate] = useState<any>(false)



    return (

        <>
            <Header icon="las la-building" title="Organizaciones" description="Base de Datos de Clientes de Alicunde Systems" buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/organization?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        header={{
                            title: "Eliminamos?",
                            description: "Esta acción no se puede deshacer",
                            icon: 'las la-trash'
                        }}
                        inputs={
                            [
                                {
                                    name: 'collection_id',
                                    title: "Collection",
                                    type: 'select',
                                    path: '/collection',
                                    placeholder: 'Nombre de la Empresa S.L',
                                    columns: [
                                        {
                                            name: 'name',
                                            title: 'Nombre',
                                        }
                                    ]
                                },
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    method="post"
                    header={
                        {
                            icon: "las la-pen",
                            title: "Organización",
                            description: "Formulario de nuevo cliente o entidad"
                        }
                    }
                    path="/organization"
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Compañía",
                                type: 'text',
                                placeholder: 'Nombre de la Empresa S.L',
                            },
                            {
                                name: 'collection_id',
                                title: "Collection",
                                type: 'select',
                                path: '/collection',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },

                            {
                                type: 'tab',
                                title: 'Contacto',
                                inputs: [

                                    {
                                        name: 'web',
                                        title: "Dominio Web",
                                        type: 'text',
                                        placeholder: 'web.com',
                                    },
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Responsables',
                                inputs: [
                                    {
                                        name: 'commercial_id',
                                        title: "Commercial",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                    {
                                        name: 'manager_id',
                                        title: "Manager",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                    {
                                        name: 'accounter_id',
                                        title: "Contable",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Configuración',
                                inputs: [

                                    {
                                        name: 'importance',
                                        title: "Importancia",
                                        type: 'select',
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'important',
                                                label: 'Cliente de Referencia'
                                            },
                                            {
                                                id: 'normal',
                                                label: 'Cliente normal'
                                            }
                                        ]
                                    },
                                ]
                            }, {
                                type: 'tab',
                                title: 'Configuración',
                                inputs: [

                                    {
                                        name: 'catchment',
                                        title: "Captación",
                                        type: 'select',
                                        placeholder: 'Método del primer contacto',
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'sender',
                                                label: 'Sender'
                                            },
                                            {
                                                id: 'email',
                                                label: 'Email'
                                            },
                                            {
                                                id: 'form',
                                                label: 'Formulario de Alicunde.com'
                                            }
                                        ]
                                    }]
                            },

                        ]
                    } />}
                {update && <Form
                    path={'/organization?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Compañía",
                                type: 'text',
                                placeholder: 'Nombre de la Empresa S.L',
                            },
                            {
                                name: 'web',
                                title: "Dominio Web",
                                type: 'text',
                                placeholder: 'web.com',
                            },
                            {
                                name: 'importance',
                                title: "Importancia",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'important',
                                        label: 'Cliente de Referencia'
                                    },
                                    {
                                        id: 'normal',
                                        label: 'Cliente normal'
                                    }
                                ]
                            },
                            {
                                name: 'catchment',
                                title: "Captación",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'sender',
                                        label: 'Sender'
                                    },
                                    {
                                        id: 'email',
                                        label: 'Email'
                                    },
                                    {
                                        id: 'form',
                                        label: 'Formulario de Alicunde.com'
                                    }
                                ]
                            },
                            {
                                name: 'collection_id',
                                title: "Collection",
                                type: 'select',
                                path: '/collection',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                title: "Responsables",
                                type: 'divisor',
                            },
                            {
                                name: 'commercial_id',
                                title: "Commercial",
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'manager_id',
                                title: "Manager",
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'accounter_id',
                                title: "Contable",
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'logotype',
                                title: "Logotype",
                                type: 'file'
                            },
                            {
                                name: 'square',
                                title: "Logotype de Rerencia",
                                type: 'file'
                            },
                        ]
                    } />}
                <Widget >
                   
                    <Table title="Listado de Organizaciones" reload={tableKey} columns={
                        [
                            {
                                title: "",
                                width: 40,
                                paddingLeft: 0,
                                render: (item: any) => {
                                    // eslint-disable-next-line jsx-a11y/alt-text
                                    return <span className="avatar"><span className="avatar-image" style={{ backgroundImage: `url(${item?.commercial?.selfie})` }} /></span>
                                }
                            },
                            {
                                onPress: (data: any) => {
                                    setDestruction(data)
                                },
                                icon: 'las la-trash',
                                width: 50

                            }, {
                                onPress: (data: any) => {
                                    setUpdate(data)
                                },
                                icon: 'las la-pen',
                                width: 50

                            },

                            {
                                name: 'name',
                                title: "Nombre",
                                width: 200,


                            },
                            {
                                name: 'web',
                                title: "Web",
                                width: 200,
                            },
                            {
                                name: 'contacts',
                                title: "Contactos",
                                width: 100,
                                path: '/organizations/contacts?organization_id=',
                                render: (item: any) => {
                                    return item?.contacts?.length
                                }
                            },
                            {
                                name: 'meets',
                                title: "Reuniones",
                                width: 100,
                                path: '/organizations/meets?organization_id=',
                                render: (item: any) => {
                                    return item?.meets?.length
                                }
                            },
                            {
                                name: 'creation',
                                title: "Creación",
                                type: 'date',
                                width: 200
                            },

                            {
                                name: 'creation',
                                type: 'since',
                                title: "Creación",
                                width: 200
                            }

                        ]
                    }
                        method="get"
                        path="/organization"
                    />
                </Widget>

            </div>
        </>

    );
}

export default Projects
