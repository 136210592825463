

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";
import { setProject } from "../../../../redux/actions";
import { connect } from "react-redux";
// connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setProject
}
interface DispatchProps {
    project: any,
    setProject: (s: any) => void


}
type Props = DispatchProps
function Projects(props: Props) {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header icon="las la-pencil-ruler" title="Proyectos" description="A veces, el futuro necesita cientos de borradores." buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/project?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    path={'/project'}
                    method={'post'}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'type',
                                title: "Tipo de Proyecto",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        label: 'Desarrollo',
                                        id: 'development'
                                    },
                                    {
                                        label: 'Mantenimiento',
                                        id: 'maintenance'
                                    }
                                ]
                            },
                            {
                                name: 'organization_id',
                                title: "Organización",
                                type: 'select',
                                path: '/organization',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'brand_id',
                                title: "Marca Corporativa",
                                type: 'select',
                                path: '/brand',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre del Proyecto",
                                type: 'text',
                                placeholder: '',
                            },
                            {
                                name: 'technical_id',
                                title: "Tecnico",
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'manager_id',
                                title: "Proyect Manager",
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'cover',
                                title: "Imagen de Portada",
                                type: 'file',
                                compress: 'image-splash'

                            },
                            {
                                name: 'icon',
                                title: "Icono",
                                type: 'icon',
                                placeholder: '',
                            },

                        ]
                    } />}
                {update && <Form
                    path={'/project?id=' + update.id}
                    method={'put'}
                    recovery={true}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'type',
                                title: "Tipo de Proyecto",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        label: 'Desarrollo',
                                        id: 'development'
                                    },
                                    {
                                        label: 'Mantenimiento',
                                        id: 'maintenance'
                                    }
                                ]
                            },
                            {
                                name: 'organization_id',
                                title: "Organización",
                                type: 'select',
                                path: '/organization',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'brand_id',
                                title: "Marca Corporativa",
                                type: 'select',
                                path: '/brand',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre del Proyecto",
                                type: 'text',
                                placeholder: '',
                            },
                            {
                                name: 'technical_id',
                                title: "Tecnico",
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'manager_id',
                                title: "Proyect Manager",
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'cover',
                                title: "Imagen de Portada",
                                type: 'file',
                                compress: 'image-splash'
                            },
                            {
                                name: 'icon',
                                title: "Icono",
                                type: 'icon',
                                placeholder: '',
                            }, {
                                name: 'introduction',
                                title: "Introducción",
                                type: 'textarea',
                                placeholder: '',
                            },

                        ]
                    } />}
                <Widget title="Listado de Proyectos">
                    <Table reload={tableKey}
                        path={'/project'}
                        method={'get'}
                        columns={
                            [
                                {
                                    operator: 'project',
                                    onPress: (data: any) => {
                                        props.setProject(data)
                                    },
                                    iconDesactive: 'las la-circle',
                                    iconActive: 'lab la-stumbleupon-circle',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },

                                {
                                    name: 'organization.name',
                                    title: "Organización",
                                    width: 200

                                },

                                {
                                    name: 'name',
                                    title: "Proyecto",
                                    render: (item: any) => {
                                        return <div className="identifier">
                                            <b>
                                                {
                                                    item.name.substring(0, 3)
                                                }
                                            </b>

                                            {
                                                item.name.substring(3, 300)
                                            }
                                        </div>
                                    },
                                    width: 300

                                },
                                {
                                    name: 'brand.name',
                                    title: "Marca",
                                    width: 200

                                },
                                {
                                    name: 'creation',
                                    title: "Creación",
                                    type: 'datetime',
                                    width: 260
                                },
                                {
                                    type: 'since',
                                    name: 'creation',
                                    title: "Hace...",
                                    width: 120
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
