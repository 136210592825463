


type Props = {
    document: any
}

export default function Splash(props: Props) {
    let document: any = {}
    if (props.document) {
        document = props.document
    }
    let splashStyle = {}
    if (document.project) {
        splashStyle = {
            backgroundImage: `url(${document.project.cover})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }
    }



    // order specifications by position
    const objects = document?.project?.objects.sort((a: any, b: any) => {
        // convert string in int
        let aPosition = (a.position) + 1
        let bPosition = (b.position) + 1
        if (a.position === 'None') aPosition = 0;
        if (b.position === 'None') bPosition = 0;

        return aPosition - bPosition
    })

    return <div className='Splash' style={splashStyle}>


        <div className='top'>
            <div className="icon" style={{ backgroundColor: document.color_primary }}>

                <i className={document.project.icon}></i>
            </div>
            <div className='Logotype'>
                <b>{document?.project?.brand?.title}</b>
                <i>{document?.project?.brand?.subtitle}</i>
            </div>
            <div className='For'>
                Documento para <b>{document?.project?.organization?.name}</b>
            </div>
            <div className='Generation'>
                Fecha de Creación <b>{document?.generation.split('T')[0]}</b>
            </div>
            <div className='Author'>
                Autor <b>{document?.author.name} {document?.author.surname}</b>
            </div>
            <div className='Barcode'>
                {document?.id.substring(0, document?.id.length - 10)}
            </div>
            <div className='Name'>
                {document?.project?.name}
            </div>
            <div className='Description'>
                {document?.name}
            </div>
            <div className="signature">
                <b>Patrick Munuera</b>
                <i>Chief Operating Officer</i>
                <img alt='s' src='/graphics/images/signature.svg' />
            </div>
        </div>
        {
            document?.project?.objects && document?.project?.objects.length > 0 && <>

                <div className="Desktop">
                    <div className="menu">
                        <div className="logo">
                            <img alt='s' src={document?.project?.organization?.logotype} />
                        </div>

                        {
                            objects?.map((item: any, index: number) => {
                                return <div className="item" key={index} style={{ backgroundColor: index === 0 ? document.color_primary : 'transparent' }}>
                                    <i className={item.icon}></i>
                                    <b>{item.name}</b>
                                    <span>{item.detail}</span>
                                </div>
                            })
                        }
                    </div>
                    <div className="dashboard"></div>
                </div>
                <div className="Phone">
                    <div className="filter">
                        <div className="canvas">
                            <div className="border">
                                <div className="volumen"></div>
                                <div className="sound"></div>
                                <div className="lock"></div>

                                <div className="date">10:32</div> <div className="tab">
                                    <div className="micro"></div>
                                </div>
                                <div className="frame">


                                    <div className="menu">
                                        <div className="logo">
                                            <img alt='s' src={document?.project?.organization?.logotype} />
                                        </div>

                                        {
                                            objects?.map((item: any, index: number) => {
                                                return <div className="item" key={index} style={{ backgroundColor: index === 0 ? document.color_primary : 'transparent' }}>
                                                    <i className={item.icon}></i>
                                                    <b>{item.name}</b>
                                                    <span>{item.detail}</span>
                                                </div>
                                            })
                                        }
                                    </div></div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        }

    </div >
}
