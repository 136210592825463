import { useEffect, useState } from "react"



type Props = {
    component: any
}

export default function Flow(props: Props) {

    const makeid = (length: any) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
    const [random] = useState(makeid(5))


    //     let i = 0;
    //     while (i < $(".flow").length) {
    //         let flow = $(".flow")[i];
    //         // console.log('Flow System', flow);
    //         if (flow.innerHTML) {
    //             // console.log('Flow Content', flow.innerHTML);

    //             let data = JSON.parse(flow.innerHTML)
    //             // console.log('Flow Json', data);

    //             flow.innerHTML = "";
    //             // console.log(flow.innerHTML);
    //             let random = makeid(5);

    //             data.map(row => {

    //                 var rowElement = document.createElement('div');
    //                 rowElement.className = 'flow-row';
    //                 row.map(item => {
    //                     var node = document.createElement('div');
    //                     node.className = 'flow-column';
    //                     if (item.child) {
    //                         item.child.map(child => {
    //                             var nodeChild = document.createElement('div');

    //                             if (child.type != 'icon') {
    //                                 nodeChild.id = random + child.id;
    //                             }
    //                             nodeChild.className = ' flow-' + child.type;
    //                             if (child.icon) {
    //                                 let nodeIcon = document.createElement('i');
    //                                 nodeIcon.className = child.icon;
    //                                 if (child.type == 'icon') nodeIcon.id = random + child.id;
    //                                 nodeChild.appendChild(nodeIcon);
    //                             }
    //                             if (child.name) {
    //                                 let nodeName = document.createElement('b');
    //                                 nodeName.className = 'flow-name';

    //                                 nodeName.innerHTML = child.name;
    //                                 nodeChild.appendChild(nodeName);
    //                             }
    //                             if (child.description) {
    //                                 let nodeDescription = document.createElement('span');
    //                                 nodeDescription.className = 'flow-description';

    //                                 nodeDescription.innerHTML = child.description;
    //                                 nodeChild.appendChild(nodeDescription);
    //                             }



    //                             if (child.label) {
    //                                 let nodeLabel = document.createElement('span');
    //                                 nodeLabel.className = 'flow-label';
    //                                 nodeLabel.innerHTML = child.label;
    //                                 nodeChild.appendChild(nodeLabel);

    //                             }
    //                             node.appendChild(nodeChild);
    //                         });

    //                     }
    //                     rowElement.appendChild(node);
    //                 });
    //                 flow.appendChild(rowElement);
    //             });
    //             setTimeout(() => {
    //                 data.map(row => {
    //                     row.map(item => {
    //                         item.child.map(child => {
    //                             if (child.id && child.to) {
    //                                 child.to.map(relation => {
    //                                     let father = document.getElementById(random + child.id);
    //                                     let finish = document.getElementById(random + relation);
    //                                     if (child.type == 'icon-sa') {
    //                                         father = document.getElementById(random + relation);
    //                                         finish = document.getElementById(random + child.id);
    //                                     }
    //                                     new LeaderLine(

    //                                         father,
    //                                         finish, {
    //                                             endPlug: 'square',
    //                                             path: child.path || 'straight',
    //                                             startPlugColor: '#aaa',
    //                                             endPlugColor: '#6E7DFF',
    //                                             gradient: true,
    //                                             size: child.stroke || 0.8,
    //                                             endPlugSize: 3,
    //                                             dropShadow: {
    //                                                 dx: 0,
    //                                                 dy: 8,
    //                                                 blur: 3
    //                                             }
    //                                         });

    //                                     //page:
    //                                     let elmWrapper = $(document.getElementById(random + child.id)).closest('.page')[0];
    //                                     let arrowcontainer = $('.arrowcontainer', elmWrapper)[0];
    //                                     let rectWrapper = elmWrapper.getBoundingClientRect()
    //                                     // create div

    //                                     let arrows = document.querySelectorAll('body > .leader-line');
    //                                     let iix = 0;
    //                                     arrows.forEach(arrow => {
    //                                         arrowcontainer.appendChild(arrow);

    //                                     });


    //                                     arrowcontainer.style.transform = 'translate(-' +
    //                                         (rectWrapper.left + pageXOffset) + 'px, -' +
    //                                         (rectWrapper.top + pageYOffset) + 'px)';


    //                                 });
    //                             }
    //                         });
    //                     });


    //                 });
    //             }, 1000);
    //         }
    //         i++;
    //     }

    // });

    // function makeid(length) {
    //     var result = '';
    //     var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //     var charactersLength = characters.length;
    //     for (var i = 0; i < length; i++) {
    //         result += characters.charAt(Math.floor(Math.random() *
    //             charactersLength));
    //     }
    //     return result;
    // }
    useEffect(() => {

        setTimeout(() => {

            const id = 'documentiframe'
            // @ts-ignore
            const iframe = window.frames ? window.frames[id] : window.getElementById(id);
            const iframeWindow = iframe.contentWindow || iframe;
            const iframeDocument = iframeWindow.document;
            props.component?.content?.map((row: any) => {
                return row.map((item: any) => {
                    return item.child.map((child: any) => {

                        if (child.id && child.to) {
                            return child.to.map((relation: any) => {



                                let father = iframeDocument.getElementById(random + child.id);
                                if (!father) return false;
                                let finish = iframeDocument.getElementById(random + relation);
                                if (child.type === 'icon-sa') {
                                    father = iframeDocument.getElementById(random + relation);
                                    finish = iframeDocument.getElementById(random + child.id);
                                }
                                if (!father) {
                                    // console.log('Sin father flow')
                                    return false;
                                }
                                if (!finish) {
                                    // console.log('Sin finish flow')
                                    return false;
                                }
                                //random + '-arrows'
                                // @ts-ignore
                                new LeaderLine(

                                    father,
                                    finish, {
                                    endPlug: 'square',
                                    path: child.path || 'straight',
                                    startPlugColor: '#aaa',
                                    endPlugColor: '#6E7DFF',
                                    gradient: true,
                                    size: child.stroke || 0.8,
                                    endPlugSize: 3,
                                    dropShadow: {
                                        dx: 0,
                                        dy: 8,
                                        blur: 3
                                    }
                                });
                                // console.log(random + '-arrows')
                                // @ts-ignore
                                let x = iframeDocument.getElementById(random + '-arrows');
                                // console.log('ess::::', x)
                                // @ts-ignore
                                let elmWrapper = x.closest(".page");
                                let arrowcontainer = iframeDocument.getElementById(random + '-arrows');
                                // console.log('jeje:', elmWrapper)

                                // @ts-ignore
                                let rectWrapper = elmWrapper.getBoundingClientRect()

                                let arrows = iframeDocument.querySelectorAll('body > .leader-line');
                                // @ts-ignore

                                arrows.forEach(arrow => {
                                    // @ts-ignore
                                    arrowcontainer.appendChild(arrow);

                                });

                                setTimeout(() => {
                                    // @ts-ignore
                                    arrowcontainer.style.transform = 'translate(-' +
                                        (rectWrapper.left + window.pageXOffset) + 'px, -' +
                                        (rectWrapper.top + window.pageYOffset) + 'px)';

                                }, 100);



                                // arrowcontainer.style.transform = 'translate(-' +
                                //     (rectWrapper.left + pageXOffset) + 'px, -' +
                                //     (rectWrapper.top + pageYOffset) + 'px)';

                                return null
                            });
                        }
                        else {
                            return null
                        }
                    });
                });


            });
        }, 300);



    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <div className='Flow'>

        {
            props?.component?.content.map((row: any) => {
                return <div className="Flow-row" id={random + '-content'}>
                    <div className="arrowcontainer" id={random + '-arrows'}></div>
                    {
                        row.map((item: any, index: number) => {

                            return <div key={index} className="Flow-column">
                                {
                                    item.child.map((child: any, keyr: number) => {
                                        const className = ' flow-' + child.type;
                                        return <div key={keyr} className={className} id={(child.type !== 'icon') ? random + child.id : undefined}>
                                            {
                                                (child.icon) && <i className={child.icon} id={(child.type === 'icon') ? random + child.id : undefined}></i>
                                            }
                                            {
                                                (child.name) && <b className="flow-name">{child.name}</b>
                                            }
                                            {
                                                (child.description) && <span className="flow-description">{child.description}</span>
                                            }
                                            {
                                                (child.label) && <span className="flow-label">{child.label}</span>
                                            }

                                        </div>

                                    })}
                            </div>
                        })
                    }

                </div>
            })
        }
    </div >
}
