

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";
import Chart from "../../../../componets/Chart";

function Meets() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header title="Reuniones" description="Listado de oportunidades de desarrollo" buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />
            <Chart
                title="Grafica de Facturación"
                datasets={[
                    {
                        path: '/invoice/analytic',
                        label: 'Gasto',

                        data: {
                            x: (item: any) => {
                                let date: any = item.creation_date.split('-')
                                return date
                            },
                            y: (item: any) => {
                                return item.total
                            }
                        },
                        backgroundColor: '#ff6969',
                        stack: 'Stack 0',
                    },
                    {
                        path: '/invoice/analytic',
                        label: 'Ingreso',
                        data: {
                            x: (item: any) => {
                                let date: any = item.creation_date.split('-')
                                return date
                            },
                            y: (item: any) => {
                                return item.total
                            }
                        },
                        backgroundColor: '#5047ce',
                        stack: 'Stack 0',
                    },
                ]}
            />
            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        path={`/meet?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    path={'/invoice'}
                    method={'post'}
                    header={
                        {
                            title: 'Nueva factura'
                        }
                    }
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'reference',
                                title: "Numero de Factura",
                                type: 'text',
                            },
                            {
                                name: 'reference_external',
                                title: "Numero de Pedido Externo",
                                type: 'text',
                            },
                            {
                                name: 'total',
                                title: "Total",
                                type: 'text',
                            },
                            {
                                title: "Entidades",
                                type: 'tab',
                                inputs: [
                                    {
                                        name: 'authorship',
                                        title: "Autoria de Factura",
                                        type: 'select',
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'own',
                                                label: 'Nosotros'
                                            },
                                            {
                                                id: 'external',
                                                label: 'Externo'
                                            }
                                        ]
                                    },
                                    {
                                        name: 'organization_id',
                                        title: "Organización",
                                        type: 'select',
                                        path: '/organization',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                    {
                                        name: 'contact_id',
                                        title: "Contacto",
                                        type: 'select',
                                        path: '/contact',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'brand_id',
                                        title: "Marca",
                                        type: 'select',
                                        path: '/brand',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                    {
                                        name: 'project_id',
                                        title: "Proyecto",
                                        type: 'select',
                                        path: '/project',
                                        placeholder: 'Nombre de la Empresa S.L',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                ]
                            },

                            {
                                type: 'tab',
                                title: 'Configuración de la factura',
                                inputs: [

                                    {
                                        name: 'tax',
                                        title: "IVA",
                                        type: 'text',
                                    },
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Información',
                                inputs: [
                                    {
                                        name: 'concepts',
                                        title: "Conceptos Estáticos",
                                        type: 'excel',
                                        columns: [
                                            {
                                                width: 300,
                                                name: 'param',
                                                title: 'Concepto'
                                            },
                                            {
                                                name: 'data',
                                                title: 'Importe'
                                            }
                                        ]
                                    },
                                    {
                                        name: 'description',
                                        title: "Descripción",
                                        type: 'textarea',
                                    },
                                    {
                                        name: 'method_information',
                                        title: "Información del Pago",
                                        type: 'textarea',
                                    }
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Generación de Factura',
                                inputs: [
                                    {
                                        name: 'page_payment_info',
                                        label: "Información del Pago",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_payment_social',
                                        label: "Labor social",
                                        type: 'checkbox'
                                    },
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Fechas',
                                inputs: [
                                    {
                                        name: 'releasedAt',
                                        title: "Fecha de Emisión de la Factura",
                                        type: 'datetime',
                                    },
                                    {
                                        name: 'receivedAt',
                                        title: "Recepción o Envío de la Factura",
                                        type: 'datetime',
                                    },
                                    {
                                        name: 'expiredAt',
                                        title: "Vencimiento de la Factura",
                                        type: 'datetime',
                                    },
                                    {
                                        name: 'paidAt',
                                        title: "Pago de la Factura",
                                        type: 'datetime',
                                    }
                                ]
                            },

                        ]
                    } />}
                {update && <Form
                    path={'/invoice?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'reference',
                                title: "Numero de Factura",
                                type: 'text',
                            },
                            {
                                name: 'reference_external',
                                title: "Numero de Pedido Externo",
                                type: 'text',
                            },
                            {
                                name: 'total',
                                title: "Total",
                                type: 'text',
                            },
                            {
                                title: "Entidades",
                                type: 'tab',
                                inputs: [
                                    {
                                        name: 'authorship',
                                        title: "Autoria de Factura",
                                        type: 'select',
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'own',
                                                label: 'Nosotros'
                                            },
                                            {
                                                id: 'external',
                                                label: 'Externo'
                                            }
                                        ]
                                    },
                                    {
                                        name: 'organization_id',
                                        title: "Organización",
                                        type: 'select',
                                        path: '/organization',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                    {
                                        name: 'contact_id',
                                        title: "Contacto",
                                        type: 'select',
                                        path: '/contact',
                                        columns: [
                                            {

                                                render: (item: any) => {
                                                    return `${item.name} ${item.surname}`
                                                },
                                            }
                                        ]
                                    },
                                    {
                                        name: 'brand_id',
                                        title: "Marca",
                                        type: 'select',
                                        path: '/brand',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                    {
                                        name: 'project_id',
                                        title: "Proyecto",
                                        type: 'select',
                                        path: '/project',
                                        placeholder: 'Nombre de la Empresa S.L',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                ]
                            },

                            {
                                type: 'tab',
                                title: 'Configuración de la factura',
                                inputs: [

                                    {
                                        name: 'tax',
                                        title: "IVA",
                                        type: 'text',
                                    },
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Información',
                                inputs: [
                                    {
                                        name: 'concepts',
                                        title: "Conceptos Estáticos",
                                        type: 'excel',
                                        columns: [
                                            {
                                                width: 300,
                                                name: 'param',
                                                title: 'Concepto'
                                            },
                                            {
                                                name: 'data',
                                                title: 'Importe'
                                            }
                                        ]
                                    },
                                    {
                                        name: 'description',
                                        title: "Descripción",
                                        type: 'textarea',
                                    },
                                    {
                                        name: 'method_information',
                                        title: "Información del Pago",
                                        type: 'textarea',
                                    }
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Generación de Factura',
                                inputs: [
                                    {
                                        name: 'page_payment_info',
                                        label: "Información del Pago",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_payment_social',
                                        label: "Labor social",
                                        type: 'checkbox'
                                    },
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Fechas',
                                inputs: [
                                    {
                                        name: 'releasedAt',
                                        title: "Recepción o Envío de la Factura",
                                        type: 'datetime',
                                    },
                                    {
                                        name: 'receivedAt',
                                        title: "Recepción o Envío de la Factura",
                                        type: 'datetime',
                                    },
                                    {
                                        name: 'expiredAt',
                                        title: "Vencimiento de la Factura",
                                        type: 'datetime',
                                    },
                                    {
                                        name: 'paidAt',
                                        title: "Pago de la Factura",
                                        type: 'datetime',
                                    }
                                ]
                            },

                        ]
                    } />}
                <Widget title="Proyectos">
                    <Table reload={tableKey}
                        path={'/invoice'}
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    name: 'organization.name',
                                    title: "Compañía",
                                    width: 200

                                },
                                {
                                    name: 'reference',
                                    title: "Nº Factura",
                                    width: 150

                                },
                                {
                                    name: 'tax',
                                    title: "Tax",
                                    width: 50,
                                    render: (item: any) => {
                                        return `${item.tax}%`
                                    }
                                },
                                {
                                    name: 'total',
                                    title: "Base",
                                    type: 'price',
                                    width: 150
                                },
                                {
                                    name: 'tax',
                                    title: "Impuestos",
                                    width: 150,
                                    render: (item: any) => {
                                        let total = item.total * (1 + item.tax / 100) - item.total
                                        let price_ = (total * 1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67

                                        return `${price_} €`
                                    }
                                },
                                {
                                    title: "Total",
                                    width: 150,
                                    render: (item: any) => {
                                        let total = item.total * (1 + item.tax / 100)
                                        let price_ = (total * 1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67

                                        return `${price_} €`
                                    }
                                },
                                {
                                    name: 'releasedAt',
                                    type: 'date',
                                    title: "Emisión",
                                    width: 150
                                },
                                {
                                    name: 'creation',
                                    type: 'since',
                                    title: "Hace...",
                                    width: 150
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Meets
