
import { IFrame } from '../iframe/component';
import './index.css';
// templates
import TemplateTeam from './templates/team.json';
import TemplateMaterials from './templates/materials.json';
import TemplateClients from './templates/clients.json';
import TemplateDocumentation from './templates/documentation.json';
import TemplateMeets from './templates/meets.json';
import TemplateSpecifications from './templates/specifications.json';
import TemplateAuthentication from './templates/authentication.json';
import TemplateDatabase from './templates/database.json';
import TemplateArchitecture from './templates/architecture.json';
import TemplateDesign from './templates/design.json';
import TemplateDeploy from './templates/deploy.json';
import TemplateSecurity from './templates/security.json';
import TemplateBudget from './templates/budget.json';
import TemplateIntroduction from './templates/introduction.json';
import TemplateSplash from './templates/splash.json';
import TemplateIndex from './templates/index.json';
import TemplateRequerimentsPerformance from './templates/requeriments_performance.json';
import TemplateRequerimentsUsability from './templates/requeriments_usability.json';
import TemplateRequerimentsImplementation from './templates/requeriments_implementation.json';
import TemplateRequerimentsFunctional from './templates/requeriments_functional.json';
import TemplateRequerimentsLegal from './templates/requeriments_legal.json';
import TemplateAuditList from './templates/audit_list.json';
import TemplateAuditIntroduction from './templates/audit_introduction.json';
import TemplateRequerimentsTechnology from './templates/requeriments_technology.json';
import TemplateRequerimentsSecurity from './templates/requeriments_security.json';

// components
import Team from './components/team';
import P from './components/p';
import H1 from './components/h1';
import H2 from './components/h2';
import H3 from './components/h3';
import H4 from './components/h4';
import H5 from './components/h5';
import H6 from './components/h6';
import Index from './components/index';
import Requeriments from './components/requeriments';
import Audits from './components/audits';
import AuditsChart from './components/auditsChart';
import Splash from './components/splash';
import Card from './components/card';
import Responsible from './components/responsible';
import Chart from './components/chart';
import Flow from './components/flow';
import Files from './components/files';
import Budget from './components/budget';
import Diagram from './components/diagram'
import Meets from './components/meets'
import Specifications from './components/specifications'
import Brands from './components/brands'
import Architecture from './components/architecture'


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import List from './components/list';


const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
// function login props redux connect

interface DispatchProps {
    user: any,
    project: any,
    endpoint: string,
    budget?: any,
    documentId?: string,
    type?: 'requeriments' | 'budget'
    environment: any,
    close: any
}
type Props = DispatchProps

const Document: React.FC<Props> = (props) => {
    const [documentor, setDocumentor] = useState<any>(false);

    let pages: any = []
    useEffect(() => {
        if (props.documentId) {
            let path = '/budget?page=1&id=' + props.documentId + '&mode=document'
            let url = props.endpoint + path
            if (props.environment === "dev") {
                url = 'http://127.0.0.1:5000' + path
            }
            axios({
                method: 'get',
                url: url,
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${props.user.access_token}`
                }
            }).then(response => {
                if (response.data) {
                    if (response.data.items && Array.isArray(response.data.items)) {
                        let doc = response.data.items[0];
                        console.log('Doc cargado:', doc)
                        if (doc.generation) doc.generation = doc.generation.split('T')[0]
                        setDocumentor(doc);
                    }


                }


            }
            ).catch(error => {

            })
        }
        else {
            let doc = props.budget;
            if (doc.generation) doc.generation = doc.generation.split('T')[0]
            setDocumentor(doc)
        }
    }, [props.documentId]) // eslint-disable-line
    if (documentor) {
        console.log('Cargar páginas', documentor)
        if (documentor.page_splash) pages.push(TemplateSplash)
        if (documentor.page_index) pages.push(TemplateIndex)
        if (documentor.page_introduction) pages.push(TemplateIntroduction)

        if (documentor.page_meets) pages.push(TemplateMeets)
        if (documentor.page_materials) {
            if (documentor?.project?.files?.length > 0) pages.push(TemplateMaterials)
        }

        if (documentor.page_specifications) pages.push(TemplateSpecifications)

        if (documentor.page_architecture) pages.push(TemplateArchitecture)

        if (documentor.page_authentication) pages.push(TemplateAuthentication)
        if (documentor.page_database) pages.push(TemplateDatabase)
        if (documentor.page_documentation) pages.push(TemplateDocumentation)
        if (documentor.page_design) pages.push(TemplateDesign)
        if (documentor.page_security) pages.push(TemplateSecurity)
        if (documentor.page_deploy) pages.push(TemplateDeploy)

        if (documentor.page_budget) pages.push(TemplateBudget)
        if (documentor.page_team) pages.push(TemplateTeam)
        if (documentor.page_clients) pages.push(TemplateClients)
        if (documentor.page_audit) {
            //pages.push(TemplateRequeriments)
            pages.push(TemplateAuditIntroduction)

            // por página:
            let sectionHeight = 36;
            let requerimentHeight = 200;
            let maxHeight = 780;

            let requerimientStart = 0;
            while (requerimientStart < documentor.project.audits.length) {
                if (requerimientStart > 0) {
                    maxHeight = 700
                }

                let heightCurrentPage = 0;
                // creamos un listado ficticio de requerimientos
                let requerimentsForPage: any = []
                let sectionsUse: any = [];
                let requerimientFinish = 0;
                let requerimentI = requerimientStart;
                while (requerimentI < documentor.project.audits.length) {
                    let requerimentIndex = requerimentI;
                    let requeriment = documentor.project.audits[requerimentI];

                    let currentSection = 'Generales'
                    if (requeriment.section) {
                        currentSection = requeriment.section
                    }

                    if (!sectionsUse.includes(currentSection)) {
                        sectionsUse.push(requeriment.section)
                        heightCurrentPage += sectionHeight;
                    }

                    // ahora añadimos la altura del requerimiento:

                    // si la altura de la página es mayor que el máximo, no añadimos el requerimiento
                    if (heightCurrentPage < maxHeight) {
                        heightCurrentPage += requerimentHeight;

                        requerimientFinish = requerimentIndex
                        requerimentsForPage.push(requeriment)
                    }
                    else {
                        requerimentI = documentor.project.audits.length
                    }

                    requerimentI++;
                }



                // creamos página:
                let requerimentDefault: any = TemplateRequerimentsFunctional;

                requerimentDefault = TemplateAuditList
                requerimentDefault = Object.assign({}, requerimentDefault)
                if (requerimientStart > 0) {
                    requerimentDefault.content = [requerimentDefault.content[3]]
                }
                requerimientFinish = requerimientFinish + 1;
                requerimentDefault.requerimentStart = requerimientStart;
                requerimentDefault.requerimentEnd = requerimientFinish;
                pages.push(requerimentDefault)
                console.log('Pagina empieza en:', requerimientStart, ' y termina en:', requerimientFinish)
                requerimientStart = requerimientFinish + 1;

            }



        }
        if (documentor.page_requeriments) {
            //pages.push(TemplateRequeriments)
            // pages.push(TemplateRequerimentsGeneral)
            let typeRequeriments = ['functional', 'performance', 'usability', 'implementation', 'technology', 'security', 'legal'];
            //map
            typeRequeriments.map((type: any) => {

                // contamos requerimientos
                let requerimentsSelected: any = []
                for (let i = 0; i < documentor.project.requeriments.length; i++) {
                    if (type === documentor.project.requeriments[i].type) {
                        requerimentsSelected.push(documentor.project.requeriments[i])
                    }
                }

                // order by requerimentsSelected by position param
                requerimentsSelected = requerimentsSelected.sort((a: any, b: any) => {
                    if (a.position < b.position) return -1;
                    if (a.position > b.position) return 1;
                    return 0;
                })
                console.log(requerimentsSelected)

                console.log('Seleccionados de ' + type + ':', requerimentsSelected)


                // por página:
                let sectionHeight = 36;
                let requerimentHeight = 30;
                let maxHeight = 570;

                let requerimientStart = 0;
                while (requerimientStart < requerimentsSelected.length) {
                    if (requerimientStart > 0) {
                        maxHeight = 700
                    }

                    let heightCurrentPage = 0;
                    // creamos un listado ficticio de requerimientos
                    let requerimentsForPage: any = []
                    let sectionsUse: any = [];
                    let requerimientFinish = 0;
                    let requerimentI = requerimientStart;
                    while (requerimentI < requerimentsSelected.length) {
                        let requerimentIndex = requerimentI;
                        let requeriment = requerimentsSelected[requerimentI];

                        let currentSection = 'Generales'
                        if (requeriment.section) {
                            currentSection = requeriment.section
                        }

                        if (!sectionsUse.includes(currentSection)) {
                            sectionsUse.push(requeriment.section)
                            heightCurrentPage += sectionHeight;
                        }

                        // ahora añadimos la altura del requerimiento:

                        // si la altura de la página es mayor que el máximo, no añadimos el requerimiento
                        if (heightCurrentPage < maxHeight) {
                            heightCurrentPage += requerimentHeight;

                            requerimientFinish = requerimentIndex
                            requerimentsForPage.push(requeriment)
                        }
                        else {
                            requerimentI = requerimentsSelected.length
                        }

                        requerimentI++;
                    }



                    // creamos página:
                    let requerimentDefault: any = TemplateRequerimentsFunctional;
                    if (type === 'performance') requerimentDefault = TemplateRequerimentsPerformance
                    if (type === 'usability') requerimentDefault = TemplateRequerimentsUsability
                    if (type === 'implementation') requerimentDefault = TemplateRequerimentsImplementation
                    if (type === 'technology') requerimentDefault = TemplateRequerimentsTechnology
                    if (type === 'security') requerimentDefault = TemplateRequerimentsSecurity
                    if (type === 'legal') requerimentDefault = TemplateRequerimentsLegal
                    requerimentDefault = Object.assign({}, requerimentDefault)
                    if (requerimientStart > 0) {
                        requerimentDefault.content = [requerimentDefault.content[3]]
                    }
                    requerimientFinish = requerimientFinish + 1;
                    requerimentDefault.requerimentStart = requerimientStart;
                    requerimentDefault.requerimentEnd = requerimientFinish;
                    pages.push(requerimentDefault)
                    console.log('Pagina empieza en:', requerimientStart, ' y termina en:', requerimientFinish)
                    requerimientStart = requerimientFinish + 1;

                }

                return null;
            })
            
        }


    }
    if (!documentor) return <div>asd</div>
    documentor.pages = pages.length
    const components = (component: any, pages: any, page: any) => {
        switch (component.type) {
            case 'h1':
                return <H1 document={documentor} component={component} />;
            case 'h2':
                return <H2 document={documentor} component={component} />;
            case 'h3':
                return <H3 document={documentor} component={component} />;
            case 'h4':
                return <H4 document={documentor} component={component} />;
            case 'h5':
                return <H5 document={documentor} component={component} />;
            case 'h6':
                return <H6 document={documentor} component={component} />;
            case 'p':
                return <P document={documentor} component={component} />;
            case 'list':
                return <List document={documentor} component={component} />;
            case 'team':
                return <Team document={documentor} component={component} />;
            case 'splash':
                return <Splash document={documentor} />;
            case 'index':
                return <Index document={documentor} pages={pages} />;
            case 'files':
                return <Files document={documentor} />;
            case 'requeriments':
                return <Requeriments document={documentor} component={component} requerimentStart={page.requerimentStart} requerimentEnd={page.requerimentEnd} />;
            case 'auditsChart':
                return <AuditsChart document={documentor} component={component} />;
            case 'audit':
                return <Audits document={documentor} component={component} requerimentStart={page.requerimentStart} requerimentEnd={page.requerimentEnd} />;

            case 'card':
                return <Card document={documentor} pages={pages} />;
            case 'chart':
                return <Chart component={component} />;
            case 'flow':
                return <Flow component={component} />;
            case 'responsible':
                return <Responsible document={documentor} component={component} />;
            case 'budget':
                return <Budget document={documentor} />;
            case 'diagram':
                return <Diagram document={documentor} component={component} />;
            case 'meets':
                return <Meets document={documentor} />;
            case 'specifications':
                return <Specifications document={documentor} />;
            case 'brands':
                return <Brands component={component} />;
            case 'architecture':
                return <Architecture document={documentor} component={component} />;
            default:
                return 'Sin componente asociado(' + component.type + ')';
        }
    }
    console.log('pages:', pages)
    return <div className='Document'>
        <div className='Document-header'>
            <div onClick={props.close} className='button-close'><i className='las la-times'></i></div>
            <div className='button-print' onClick={() => {
                const id = 'documentiframe'
                // @ts-ignore
                const iframe = window.frames ? window.frames[id] : window.getElementById(id);
                const iframeWindow = iframe.contentWindow || iframe;

                iframe.focus();
                document.title = documentor.project.name
                iframeWindow.print();
            }}>
                <i className='las la-print'></i>
            </div>
        </div>
        <IFrame id="documentiframe" name="documentiframe" >
            <link rel="stylesheet" href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/team.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/rotater.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/legal.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/p.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/header.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/hs.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/phone.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/splash.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/desktop.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/specifications.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/meets.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/list.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/index.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/flow.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/files.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/diagram.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/card.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/budget.css" />
            <link type="text/css" rel="stylesheet" href="/styles/document/brands.css" />

            <link type="text/css" rel="stylesheet" href="/styles/document/responsible.css" />

            <link type="text/css" rel="stylesheet" href="/styles/document/requeriments.css" />



            <body >

                {
                    pages.map((page: any, index: number) => {
                        return <div className='page' key={index}>
                            <div className='Header'>
                                <b>{documentor?.project?.brand?.title}</b> {documentor?.project?.brand?.subtitle}
                                <strong className='confidential'>
                                    Confidencial
                                </strong>
                                <strong>
                                    {documentor.project?.organization?.name}
                                </strong>
                                <i>
                                    {documentor?.name}
                                </i>

                            </div>

                            <div className='content'>

                                {
                                    page.content.map((content: any, index: number) => {
                                        return <React.Fragment key={index}>
                                            {
                                                components(content, pages, page)
                                            }
                                        </React.Fragment>
                                    })
                                }
                                {
                                    page?.foot === 'no' ? '' : <>

                                        <div className='Rotater'>
                                            <b>{documentor?.project?.brand?.title}</b>
                                            <i>{documentor?.project?.brand?.subtitle}</i>
                                        </div>
                                        <div className='Legal'>
                                            <i>{documentor?.project?.brand?.confidential}</i>
                                            <b>{documentor?.project?.brand?.foot}</b>
                                        </div>
                                    </>
                                }
                                <div className='pagination'>{index}</div>
                            </div>
                        </div>
                    })
                }
            </body>
        </IFrame>
    </div>
}


export default connect(mapStateToProps, mapDispatchToProps)(Document)
