

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import Document from "../../../../componets/Document";
import { useState } from "react";

function Budgets() {
    const [form, setForm] = useState(false)
    const [printer, setPrinter] = useState<any>(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            {printer && <Document type={'budget'} documentId={printer.id} close={() => { setPrinter(false) }} />}
            <Header icon="las la-video" title="Documentos" description="Listado de eventos agendados con clientes" buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        path={`/budget?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    path={'/budget'}
                    method={'post'}
                    header={
                        {
                            icon: "las la-file",
                            title: "Nueva Documento",
                        }
                    }
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                name: 'project_id',
                                title: "Projecte",
                                type: 'select',
                                path: '/project',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },


                            {
                                name: 'generation',
                                title: "Fecha de Emisión",
                                type: 'datetime'
                            },

                            {
                                title: 'Presupuesto',
                                type: 'tab',
                                inputs: [
                                    {
                                        name: 'total',
                                        title: "Total",
                                        type: 'text',
                                        placeholder: '1000.00',
                                    },
                                    {
                                        name: 'duration',
                                        title: "Días de Desarrollo con Fines de Semana",
                                        type: 'text',
                                        placeholder: '30',
                                    },
                                    {
                                        name: 'resources',
                                        title: "Recursos por Horas",
                                        type: 'select',
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                id: 'no',
                                                label: 'Sin recursos'
                                            },
                                            {
                                                id: 'yes',
                                                label: 'Con recursos'
                                            }
                                        ]
                                    },
                                ]
                            },
                            {
                                title: "Configuración",
                                type: 'tab',
                                inputs: [
                                    {
                                        name: 'color_primary',
                                        title: "Color primario",
                                        type: 'color'
                                    },
                                    {
                                        name: 'pronoun',
                                        title: 'Pronombre',
                                        type: 'text',
                                    },
                                    {
                                        name: 'name',
                                        title: "Nombre",
                                        type: 'text',
                                        placeholder: 'Título de la Reunión',
                                    },
                                    {
                                        name: 'description',
                                        title: "Descripción",
                                        type: 'textarea'
                                    },
                                    {
                                        name: 'conclusion',
                                        title: "Conclusión",
                                        type: 'textarea'
                                    },
                                ]
                            },

                            {
                                title: "Páginas",
                                type: 'tab',
                                inputs: [
                                    {
                                        name: 'page_splash',
                                        label: "Portada",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_introduction',
                                        label: "Introducción",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_index',
                                        label: "Indice",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_architecture',
                                        label: "Arquitectura",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_budget',
                                        label: "Presupuesto",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_architecture',
                                        label: "Arquitectura",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_documentation',
                                        label: "Documentación",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_authentication',
                                        label: "Sistema de Autenticación",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_deploy',
                                        label: "Sistema de Despliegue",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_materials',
                                        label: "Materiales Aportados",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_meets',
                                        label: "Reuniones Mantenidas",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_requeriments',
                                        label: "Requerimientos",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_specifications',
                                        label: "Especificaciones",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_audit',
                                        label: "Auditoría Técnica",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_clients',
                                        label: "Clientes Referentes",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_security',
                                        label: "Seguridad del Software",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_team',
                                        label: "Equipo Corporativo",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_design',
                                        label: "Design System",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_database',
                                        label: "Base de Datos",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_technologies',
                                        label: "Tecnologías",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_integrations',
                                        label: "Integraciones",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_calendar',
                                        label: "Calendario",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_certifications',
                                        label: "Certificaciones ISO",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_testing',
                                        label: "Testing",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_stories',
                                        label: "Casos de Uso",
                                        type: 'checkbox'
                                    }
                                ]
                            }
                        ]
                    } />}
                {update && <Form
                    path={'/budget?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {

                                name: 'status',
                                title: "Estado del Expediente",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'PENDING',
                                        label: 'Pendiente'
                                    },
                                    {
                                        id: 'FINALIZED',
                                        label: 'Finalizado'
                                    },
                                    {
                                        id: 'CANCELLED',
                                        label: 'Cancelado'
                                    }
                                ]
                            },
                            {
                                name: 'project_id',
                                title: "Projecte",
                                type: 'select',
                                path: '/project',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'resources',
                                title: "Recursos por Horas",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'no',
                                        label: 'Sin recursos'
                                    },
                                    {
                                        id: 'yes',
                                        label: 'Con recursos'
                                    }
                                ]
                            },
                            {
                                name: 'color_primary',
                                title: "Color primario",
                                type: 'color'
                            },
                            {
                                name: 'generation',
                                title: "Fecha de Emisión",
                                type: 'datetime'
                            },
                            {
                                name: 'total',
                                title: "Total",
                                type: 'text',
                                placeholder: '1000.00',
                            },
                            {
                                name: 'duration',
                                title: "Días de Desarrollo con Fines de Semana",
                                type: 'text',
                                placeholder: '30',
                            },
                            {
                                name: 'pronoun',
                                title: 'Pronombre',
                                type: 'text',
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                                placeholder: 'Título de la Reunión',
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                type: 'textarea'
                            },
                            {
                                name: 'conclusion',
                                title: "Conclusión",
                                type: 'textarea'
                            },
                            {
                                title: "Páginas",
                                type: 'tab',
                                inputs: [

                                    {
                                        name: 'page_splash',
                                        label: "Portada",
                                        type: 'checkbox'
                                    }, {
                                        name: 'page_introduction',
                                        label: "Introducción",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_index',
                                        label: "Indice",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_audit',
                                        label: "Auditoría Técnica",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_budget',
                                        label: "Presupuesto",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_architecture',
                                        label: "Arquitectura",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_documentation',
                                        label: "Documentación",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_authentication',
                                        label: "Sistema de Autenticación",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_deploy',
                                        label: "Sistema de Despliegue",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_materials',
                                        label: "Materiales Aportados",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_meets',
                                        label: "Reuniones Mantenidas",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_requeriments',
                                        label: "Requerimientos",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_specifications',
                                        label: "Especificaciones",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_clients',
                                        label: "Clientes Referentes",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_security',
                                        label: "Seguridad del Software",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_team',
                                        label: "Equipo Corporativo",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_design',
                                        label: "Design System",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_database',
                                        label: "Base de Datos",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_technologies',
                                        label: "Tecnologías",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_integrations',
                                        label: "Integraciones",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_calendar',
                                        label: "Calendario",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_certifications',
                                        label: "Certificaciones ISO",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_testing',
                                        label: "Testing",
                                        type: 'checkbox'
                                    },
                                    {
                                        name: 'page_stories',
                                        label: "Casos de Uso",
                                        type: 'checkbox'
                                    }
                                ]
                            }
                        ]
                    } />}
                <Widget title="Listado de Reuniones">
                    <Table reload={tableKey}
                        path={'/budget'}
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setPrinter(data)
                                    },
                                    icon: 'las la-print',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    name: 'project.name',
                                    title: "Proyecto",
                                    width: 400

                                },
                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 300

                                },
                                {
                                    name: 'total',
                                    title: "Total",
                                    width: 100

                                },

                                {
                                    name: 'generation',
                                    title: "Empieza",
                                    width: 200,
                                    type: 'since'
                                },
                                {
                                    name: 'status',
                                    title: "Status",
                                    order: 'status',

                                    width: 130,
                                    render: (item: any) => {
                                        return <div className={`Status Status-${item.status}`}>{item.status}</div>
                                    }

                                },


                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Budgets
