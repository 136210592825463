

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";
import { setProject } from "../../../../redux/actions";
import { connect } from "react-redux";
import DragerComponent from "../../../../componets/Drager";
// connect redux
const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setProject
}
interface DispatchProps {
    project: any,
    setProject: (s: any) => void


}
type Props = DispatchProps
function Projects(props: Props) {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header icon="las la-pencil-ruler" title="Tareas"  buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/task?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    path={'/task'}
                    method={'post'}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                     //setForm(false)
                       setTableKey(Math.random().toString(36).substring(7))
                    }}
                    default={{
                        status: 'PLANING',
                        type: 'SOFTWARE',
                        brand: {
                            name:'Alicunde Systems',
                            id: '93864e26-ce3e-417a-ab04-b8c450fe180e'
                        },
                        brand_id: '93864e26-ce3e-417a-ab04-b8c450fe180e'
                    

                    }}
                    header={{
                        icon: "las la-tasks",
                        title: "Nueva Tarea",
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Título de la Tarea",
                                type: 'text',
                                placeholder: '',
                            },

                            {
                                name: 'description',
                                title: "Description",
                                type: 'textarea',
                                placeholder: '',
                            },
                            {
                                help: 'Fecha de la entrega final de la tarea, permitiendo al bot de notificar al responsable mediante Discord.',

                                name: 'deliveredAt',
                                title: "Fecha de Entrega",
                                type: 'datetime'
                            },
                            {
                                name: 'project_id',
                                title: "Proyecto de la Tarea2",
                                help:'Selecciona un proyecto al que asociar la tarea',
                                type: 'select',
                                path: '/project',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'organization.name',
                                        title: 'Organización',
                                    },
                                    {
                                        name: 'name',
                                        title: 'Proyecto',
                                    }
                                ]
                            },
                           
                            {
                                name: 'responsible_id',
                                title: "Responsable",
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                type:'tab',
                                title:'Configuración',
                                inputs:[
                                    {
                                        name: 'status',
                                        title: "Estado",
                                        type: 'select',
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],

                                        options: [
                                            
                                            {
                                                label: 'PLANING',
                                                id: 'PLANING'
                                            },
                                            {
                                                label: 'ACTIVE',

                                                id: 'ACTIVE'
                                            },
                                            {
                                                label: 'PENDING',
                                                id: 'PENDING'
                                            },
                                            {
                                                label: 'CANCELLED',
                                                id: 'CANCELLED'
                                            },
                                            {
                                                label: 'INACTIVE',
                                                id: 'INACTIVE'
                                            }, {
                                                label: 'FINALIZED',
                                                id: 'FINALIZED'
                                            },

                                        ]
                                    },
                                    {
                                        name: 'type',
                                        title: "Tipo de Tarea",
                                        type: 'select',
                                        columns: [
                                            {
                                                name: 'label',
                                                title: 'Nombre',
                                            }
                                        ],
                                        options: [
                                            {
                                                label: 'Software',
                                                id: 'SOFTWARE'
                                            },
                                            {
                                                label: 'Gestión del Proyecto',
                                                id: 'PROYECT_MANAGEMENT'
                                            },
                                            {
                                                label: 'Contabilidad',
                                                id: 'ACCOUNTING'
                                            },
                                            {
                                                label: 'Labor Comercial',
                                                id: 'COMMERCIAL'
                                            },
                                            {
                                                label: 'Diseño',
                                                id: 'DESIGN'
                                            }

                                        ]
                                    },

                                    {
                                        name: 'brand_id',
                                        title: "Marca Corporativa",
                                        type: 'select',
                                        path: '/brand',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                    {
                                        name: 'manager_id',
                                        title: "Proyect Manager",
                                        type: 'select',
                                        path: '/directory/user',
                                        columns: [
                                            {
                                                name: 'name',
                                                title: 'Nombre',
                                            }
                                        ]
                                    },
                                ]
                            },
                            
                          
                            
                            
                           

                        ]
                    } />}
                {update && <Form
                    path={'/task?id=' + update.id}
                    method={'put'}
                    recovery={true}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'project_id',
                                title: "Projecte",
                                type: 'select',
                                path: '/project',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'type',
                                title: "Tipo de Proyecto",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        label: 'Desarrollo',
                                        id: 'development'
                                    },
                                    {
                                        label: 'Mantenimiento',
                                        id: 'maintenance'
                                    }
                                ]
                            },
                            
                            {
                                name: 'brand_id',
                                title: "Marca Corporativa",
                                type: 'select',
                                path: '/brand',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre del Proyecto",
                                type: 'text',
                                placeholder: '',
                            },
                            {
                                name: 'responsible_id',
                                title: "Responsable",
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'manager_id',
                                title: "Proyect Manager",
                                type: 'select',
                                path: '/directory/user',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'cover',
                                title: "Imagen de Portada",
                                type: 'file',
                                compress: 'image-splash'
                            },
                           {
                                name: 'description',
                                title: "Description",
                                type: 'textarea',
                                placeholder: '',
                            },
                            {
                                name: 'icon',
                                title: "Icono",
                                type: 'icon',
                                placeholder: '',
                            },
                        ]
                    } />}
                <DragerComponent 
                    path={'/task'}
                    key={tableKey}
                    columns={{
                        PLANING: {
                            color:'orange',
                            title: 'PLANING',
                            description: 'Pendientes de adjudicar',
                            id: 'PLANING',
                        },
                        ACTIVE:{
                            color:'green',
                            title: 'ACTIVE',
                            description: 'En proceso de desarrollo',

                            id: 'ACTIVE',
                        },
                        INACTIVE:{
                            color:'red',
                            title: 'INACTIVE',
                            description: 'Bloqueadas por el cliente',
                            id: 'INACTIVE',
                        },
                        
                       
                        FINALIZED:{
                            color:'blue',
                            title: 'FINALIZED',
                            description: 'Completadas y entregadas',
                            id: 'FINALIZED',
                        },
                       
                        

                    }
                    
                } />
                <Widget >
                    <Table reload={tableKey}
                        path={'/task'}
                        method={'get'}
                        columns={
                            [
                                
                                {
                                    title: "",
                                    width: 40,
                                    paddingLeft: 0,
                                    render: (item: any) => {
                                        // eslint-disable-next-line jsx-a11y/alt-text
                                        return <span className="avatar" style={{ backgroundImage: `url(${item.responsible.selfie})` }} />
                                    }
                                },
                                
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    name: 'status',
                                    title: "Status",
                                    order: 'status',

                                    width: 130,
                                    render: (item: any) => {
                                        return <div className={`Status Status-${item.status}`}>{item.status}</div>
                                    }

                                },
                                {
                                    name: 'project.organization.name',
                                    title: "Organización",
                                    width: 120

                                },
                                {
                                    name: 'project.name',
                                    title: "Proyecto",
                                    
                                    width: 200

                                },
                               
                                {
                                    name: 'name',
                                    title: "Tarea",
                                    width: 400

                                },
                               
                                {
                                    type: 'since',
                                    name: 'creation',
                                    title: "Hace...",
                                    width: 120
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
