import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import './index.css'
const Color = require('color');

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);



interface DispatchProps {
    user: any
    environment: string,
    project: any,
    endpoint: string,
    datasets: any,
    title: string,
    range?: boolean,
    type?: 'lines' | 'bars'
}
type Props = DispatchProps
const mapStateToProps = (state: any, ownProps: any) => (state)

function Chart(props: Props) {
    const [data, setData] = useState<any>([]);
    const [period, setPeriod] = useState<any>('day');
    const [loading, setLoading] = useState<any>(true);
    const [error, setError] = useState<any>(false);

    var dataTemporal: any = [];
    const loadData = (item: any) => {
        let pager = 0
        let url = props.endpoint + item.path
        if (props.environment === 'dev') {
            url = 'http://127.0.0.1:5000' + item.path
        }
        url = url + '?mode=table&page=' + pager
        if (props.project) {
            url = url + '&project=' + props.project.id
        }

        axios({
            method: 'get',
            url: url,
            data: {},
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${props.user.access_token}`
            }
        }).then(response => {
            if (response.data) {
                let i = 0;
                let items = response.data.items
                const colored: any = Color(item.backgroundColor)
                let dataSets = [];
                while (i < items.length) {
                    var dateWithTimeZone: any = new Date(items[i].x); /* midnight in China on April 13th */
                    let dataWithTimeZoneFormat = dateWithTimeZone.toISOString({ timeZone: 'Europe/Madrid' }).slice(0, 10)
                    items[i].x = dataWithTimeZoneFormat

                    const change = colored.hex()
                    let dataSet: any = {
                        label: dataWithTimeZoneFormat,
                        data: [items[i]],
                        backgroundColor: change,
                        tension: -1,
                        borderWidth: 0,
                        barPercentage: 1.5,
                        categoryPercentage: 1.0,
                        maxBarThickness: 20
                    }
                    dataSets.push(dataSet)
                    i++;
                }
                dataTemporal = [...dataTemporal, ...dataSets]
                if (dataTemporal.length < 30) {

                }
                setData(dataTemporal)
                // delete labels empty


                i++;


            }


            setLoading(false);
        }
        ).catch(error => {
            setError(error);
            setLoading(false);
        })
    }
    useEffect(() => {
        // pasamos por cada data
        if (props.datasets) {
            props.datasets.map((item: any) => {
                loadData(item)

                return true;
            })

        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    // filter by period x

    let dataFilterByPeriod: any = [];
    console.log('Analizamos:', data)
    data.map((item: any) => {
        let itemClone: any = JSON.parse(JSON.stringify(item))


        if (itemClone.data.length > 0) {
            if (period === 'day') {
                console.log('day!', itemClone.data[0].x)
                itemClone.label = itemClone.data[0].x = itemClone.data[0].x.substring(0, 10)
                console.log('Day final:', itemClone.label)
            }
            else if (period === 'month') {
                itemClone.label = itemClone.data[0].x = itemClone.data[0].x.substring(0, 7)
            }
            else if (period === 'year') {
                itemClone.label = itemClone.data[0].x = itemClone.data[0].x.substring(0, 4)
            }
        }
        dataFilterByPeriod.push(itemClone)
        return true;
    });
    console.log('Grafica final:', dataFilterByPeriod)
    let options: any = {
        responsive: true,

        animation: false,
        maintainAspectRatio: false,
        layout: {
            autoPadding: false,
            padding: 0,
            height: 150,
        },
        scales: {
            y: {
                ticks: {
                    color: '#000',
                    font: {
                        size: 10
                    }
                },


            },
            x: {
                ticks: {
                    color: '#000',
                    font: {

                        size: 8
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
            title: {
                display: false,
            },
        },
    };
    if (props.type === 'lines') {
        options = {}
    }
    let labelsComplete: any = []
    dataFilterByPeriod.map((item: any) => {
        if (labelsComplete.indexOf(item.label) === -1) {
            labelsComplete.push(item.label)
        }
        return true;
    })
    console.log(labelsComplete)
    // add 10 more labels if not
    let i = labelsComplete.length;
    console.log(labelsComplete.length)
    while (i < 20) {
        console.log('nuevo')
        labelsComplete.push(i)
        i++;
    }
    console.log(labelsComplete)



    let dataRender: any = {
        labels: labelsComplete,
        datasets: dataFilterByPeriod
    };
    // if (props.type === 'lines') {
    //     data = {
    //         labels,
    //         datasets: [
    //             {
    //                 label: 'Dataset 1',
    //                 data: labels.map(() => Math.floor(Math.random() * 100 + 10)),
    //                 borderColor: 'rgb(255, 99, 132)',
    //                 backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //             }
    //         ],
    //     };
    // }
    console.log('grafica:', data)
    let max = 0;
    let min = 100000000000000;
    let total = 0;
    data.map((item: any) => {
        if (item.data.length > 0) {
            if (item.data[0].y > max) {
                max = item.data[0].y
            }
            if (item.data[0].y < min) {
                min = item.data[0].y
            }
            total = total + item.data[0].y
        }
        return true;
    })
    let avg = total / data.length
    return <div className='Chart' style={{}}>
        {
            error && <div className='error'>{error}</div>
        }
        {
            loading && <div className='Loading'>Cargando...</div>
        }
        <div className='filter'>
            <div className='result'>
                <span className='total'>Total <b>{total}</b></span>
                <span className='avg'>Media <b>{avg}</b></span>
                <span className='min'>Mínimo <b>{min}</b></span>
                <span className='max'>Máximo <b>{max}</b></span>
                <span className='max'>Elementos <b>{dataFilterByPeriod.length}</b></span>
            </div>

        </div>
        {
            props.range && <div className='range'>
                <span>Inicio</span>
                <span>Final</span>
            </div>
        }
        <div className='period'>
            <span className={period === 'day' ? 'active' : ''} onClick={() => {
                setPeriod('day')
            }}>Días</span>
            <span className={period === 'month' ? 'active' : ''} onClick={() => {
                setPeriod('month')
            }}>Meses</span>
            <span className={period === 'year' ? 'active' : ''} onClick={() => {
                setPeriod('year')
            }}>Años</span>
        </div>
        <div className='canvas'>
            {
                props.type === 'lines' &&
                <Line options={options} data={data} />
            }
            {
                (props.type === 'bars' || !props.type) &&
                <Bar options={options} data={dataRender} />
            }
        </div>
    </div>
}


export default connect(mapStateToProps)(Chart)
