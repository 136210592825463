import React from 'react';
// import styles
import { setUser, clear, setProject, setRepository } from '../../redux/actions'
import { connect } from "react-redux";
import './index.css'




const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setUser, clear, setRepository, setProject
}
// function login props redux connect

interface HeaderProps {
    title?: string,
    icon?: string
    setProject: (project: any) => void
    setUser: (user: any) => void
    description: string
    buttons?: {
        text?: string,
        icon: string,
        onClick: () => void
    }[],
    user: any

}
type Props = HeaderProps

const Header: React.FC<Props> = (props) => {

    const icons = false;
    return (
        <>
            <div className="Header">
                {
                    icons && <div className="Header-icon">
                        <a href='https://www.iso.org/standard/59752.html' target={'_blank'} rel="noreferrer" className='iso'>
                            <img src='/graphics/images/iso-logo.svg' alt="" />
                        </a>
                        <a href='https://www.iso.org/standard/59752.html' target={'_blank'} rel="noreferrer" className='ce'>
                            <img src='/graphics/images/ce.svg' alt="" />
                        </a>
                    </div>
                }
                <i className={`Header-icon ${props.icon}`}></i>
                <div className="Header-title">
                    {props.title}
                </div>
                <div className="Header-description">
                    {props.description}
                </div>

                {

                    <div className="Header-buttons">

                        {
                            props.buttons && props.buttons.map((button, index) => {
                                return <span className={button.text ? 'withText' : 'withoutText'} key={index} onClick={() => {

                                    button.onClick()
                                }}><i className={button.icon}></i> {button.text} </span>
                            })
                        }
                        {
                            props.user && <div className={`Header-user ${props.user.selfie && 'Header-user-with-avatar'}`}>
                                {
                                    props.user.selfie &&

                                    <div className="Header-user-avatar" style={{
                                        backgroundImage: `url(${props.user.selfie})`
                                    }}>
                                    </div>}
                                <div className="Header-user-name">
                                    {props.user.name} {props.user.surname}
                                    <em> {props.user.role}</em>
                                </div>
                                <i className='las la-times' onClick={() => {
                                    props.setProject('')
                                    props.setUser('')
                                }}></i>
                            </div>

                        }
                    </div>

                }

            </div >
        </>
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Header)
