

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Treasures() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header title="Reuniones" description="Listado de oportunidades de desarrollo" buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />
            {update && <div></div>}
            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        path={`/treasure?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [

                            ]
                        } />}
                {form && <Form
                    path={'/treasure'}
                    method={'post'}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'account_id',
                                title: "Cuenta Bancaria",
                                type: 'select',
                                path: '/account',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    },
                                    {
                                        name: 'bank',
                                        title: 'Banco',
                                    }
                                ]
                            },
                            {
                                name: 'total',
                                title: "Total",
                                type: 'text',
                                placeholder: 'Total',
                            }
                        ]
                    } />}

                <Widget title="Proyectos">
                    <Table reload={tableKey}
                        path={'/treasure'}
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },

                                {
                                    name: 'account.name',
                                    title: "Cuenta",
                                    width: 200

                                },
                                {
                                    name: 'total',
                                    title: "Total",
                                    width: 300,
                                    type: 'price'

                                },

                                {
                                    name: 'creation',
                                    title: "Creación",
                                    type: 'date',
                                    width: 90
                                },
                                {
                                    type: 'since',
                                    name: 'since',
                                    title: "Hace...",
                                    width: 90
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Treasures
