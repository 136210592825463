import React from "react";
import Item from "./item";
import { Droppable } from "react-beautiful-dnd";

interface ColumnProps {
    col: {
        color:string,
        id: string;
        title:string;
        description:string,
        list?: any[];
    };
}



const Column: React.FC<ColumnProps> = ({ col: { color, description,title,list, id } }) => {
    return (
        <>
           
        <Droppable droppableId={id}>
            
            {(provided) => (
                <div className="Drager-column" >
                        <div className="Drager-column-title" style={{ borderColor: color}}>{title}</div>
                        <div className="Drager-column-description">{description}</div>
                    <div className="Drager-column-content" {...provided.droppableProps} ref={provided.innerRef}>
                            {list && list.map((item, index) => (
                                <Item key={item.id} item={item} index={index} />
                        ))}
                        {provided.placeholder}
                    </div>
                </div>
            )}
        </Droppable></>
    );
};

export default Column;
