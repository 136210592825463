import React, { useState } from 'react';
// import styles
import { setUser, clear, setRepository } from '../../redux/actions'
import { connect } from "react-redux";
import './index.css'
import useSound from 'use-sound';

import "react-datepicker/dist/react-datepicker.css";
import axios, { Method } from 'axios';
import Icons from './icons'
import Excel from './excel'
import Select from './select'
import Hierarchy from './hierarchy'
import Header from '../Header';
import Dater from './date'
import Tab from './tab';
import ReactTooltip from 'react-tooltip';

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setUser, clear, setRepository
}
// function login props redux connect
interface InputProps {
    help?: string,
    columns: {
        onPress?: (data: any) => void
        width: number
        name: string,
        title?: string
        icon?: string
    }[],
    onSuccessCallback: any,
    conditions?: {
        name: string,
        value: any
        operator: '!==' | '==='
    }[],
    label?: string,
    options: any,
    file?: string,
    reload: string,
    disabled?: boolean,
    onSuccessUlr?: string,
    compress: string,
    path?: string,
    thumb?: boolean,
    method?: Method,
    accept?: any,
    unselected?: string,
    selected?: string,
    items?: any[]
    title: string,
    multiple?: boolean,
    description?: string,
    name: string,
    placeholder: string,
    paths: any,
    type: 'google-storage' | 'date' | 'hierarchy' | 'number' | 'excel' | 'checkbox' | 'color' | 'tab' | 'icon' | 'text' | 'textarea' | 'select' | 'divisor' | 'password' | 'stl' | 'datetime' | 'file' | 'image'
    inputs?: InputProps[]
}
interface DispatchProps {
    autocomplete:string,
    endpoint: string,
    header?: {
        title: string,
        icon?: string
        description: string
        buttons?: {
            text: string,
            icon: string,
            onClick: () => void
        }[]
    }
    backgroundClick: () => void,
    audio: any,
    type?: string,
    environment: string,
    path: string,
    buttons?: any,
    method: Method,
    default: any,
    recovery?: boolean,
    user: any,
    onSuccess: (response: any) => void,
    secondary?: {
        icon: string,
        title: string,
        onClick: () => void
    }
    submit?: {
        title: string,
        icon: string,

    },
    inputs: InputProps[]

}
type Props = DispatchProps

const Form: React.FC<Props> = (props) => {
    const [error, setError] = useState<any>(false);
    const [success, setSuccess] = useState<any>(false);
    const [errors, setErrors] = useState<any>(false);
    const [recovery, setRecovery] = useState<any>(false);
    const [errorsFull, setErrorsFull] = useState<boolean>(false);
    const [loading, setLoading] = useState<any>(false);
    const formScroll: any = React.useRef()

    const [soundEnter] = useSound(
        '/sounds/hero_simple-celebration-03.wav',
        { volume: 0.3 }
    );
    const [play] = useSound(
        '/sounds/Cancel-1.m4a',
        { volume: 0.4 }
    );
    const [soundError] = useSound(
        '/sounds/alert_error-01.wav',
        { volume: 0.4 }
    );

    const [soundDelete] = useSound(
        '/sounds/trash.mp3',
        { volume: 0.4 }
    );
    const [backgroundCloserSound] = useSound(
        '/sounds/ui_tap-variant-03.wav',
        { volume: 0.3 }
    );
    const backgroundCloser = () => {
        props.audio && backgroundCloserSound()
        props?.backgroundClick()
    }
    React.useEffect(() => {
        setTimeout(() => {
            props.audio && play();
        }, 5);
    }, [play, props.audio]);


    React.useEffect(() => {
        if (props.recovery) {
            // obtenemos los valores del server
            const headers = {

                "Content-Type": "multipart/form-data",
                // beraarer token props.user
                'Authorization': 'Bearer ' + props.user.access_token
            }
            let url = props.endpoint + props.path
            if (props.environment === 'dev') {
                url = 'http://127.0.0.1:5000' + props.path
            }
            axios({
                method: 'get',
                url: url + '&mode=recovery',
                headers: headers
            })
                .then(function (response) {
                    if (response.data.items && response.data.items.length > 0) {
                        setRecovery(response.data.items[0])
                    }

                }).catch(() => {
                    alert('Error')
                })

        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    if (props.recovery && recovery === false) {
        return <>
            {!props.type && <div className="Form-background" onClick={backgroundCloser}></div>}
            <div className={`Form-position${props.type ? '-' + props.type : '-center'}`} >
                <div className={`Form ${props.type ? props.type : 'Form-Popup'} ${loading ? 'Form-loading' : ''}`} >
                    <div className='Form-recovery'>
                        <div className='Loading' />
                        Cargando datos
                    </div>
                </div>
            </div>
        </>

    }
    var defaultValues: any = props.default
    if (props.recovery && recovery) {
        defaultValues = recovery
    }


    const renderInputs = (defaultvalues: any, inputs: InputProps[], callBackSetValue?: any) => {
        return inputs && inputs.map((input, index) => {
            if (input.conditions) {
                let validationCondition: boolean = true
                // for input.conditions
                for (let i in input.conditions) {
                    const condition = input.conditions[i]
                    if (condition.operator === '!==') {
                        if (defaultvalues[condition.name] !== condition.value) {
                        }
                        else {

                            validationCondition = false
                        }
                    }
                    if (condition.operator === '===') {
                        if (defaultvalues[condition.name] === condition.value) {

                        }
                        else {
                            validationCondition = false
                        }
                    }
                }
                console.log('Condition:', validationCondition, input.conditions)
                if (validationCondition === false) return null;
            }
            return <div key={index} className={`Form-box Form-box-${input.type}`}>
                {input.type !== 'tab' && input.type !== 'divisor' && input.title && <p style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} >{input.title} {input.help && <i data-tip={input.help} style={{ marginLeft: 10, fontSize: 20 }} className='las la-info-circle' />}</p>}
                {input.type !== 'tab' && input.type !== 'divisor' && input.description && <p className='description'>{input.description}</p>}
                {
                    input.type === 'text' &&

                    <input disabled={input.disabled} defaultValue={defaultvalues && defaultvalues[input.name]} name={input.name} type={'text'} placeholder={input.placeholder} />
                }
                {
                    input.type === 'number' &&

                    <input defaultValue={defaultvalues && defaultvalues[input.name]} name={input.name} type={'number'} placeholder={input.placeholder} />
                }
                {
                    input.type === 'color' &&

                    <input defaultValue={defaultvalues && defaultvalues[input.name]} name={input.name} type={'color'} placeholder={input.placeholder} />
                }
                {
                    input.type === 'password' &&

                    <input autoComplete={props.autocomplete}  defaultValue={defaultvalues && defaultvalues[input.name]} name={input.name} type={'password'} placeholder={input.placeholder} />
                }
                {
                    input.type === 'google-storage' &&

                    <div className='finder'>
                        {
                            input.file && defaultvalues && defaultvalues[input.file] && <div onClick={() => {
                                input.file && window.open(defaultvalues[input.file])
                            }} className='finder-file'>
                                Descargar archivo
                                <i className="las la-cloud-download-alt"></i>
                            </div>
                        }
                        <div className='finder-progress'><div className='finder-progress-bar'></div></div>
                        <div className='finder-success'><i className='las la-check'></i> Archivo guardado correctamente</div>
                        <div className='finder-error'><i className='las la-times'></i> No se ha podido guardar el archivo</div>
                        {defaultvalues && defaultvalues[input.name] ?
                            <input data-compress={input.compress} name={input.name} type="file" accept={input.accept} placeholder={input.placeholder} onChange={(e) => {
                                let that: any = e.target;
                                that.style.display = 'none';
                                that.parentNode.classList.add('finder-loading');

                                if (defaultvalues && defaultvalues[input.name]) {

                                    let url = defaultvalues[input.name]
                                    let id = defaultvalues['id']
                                    let formData = new FormData();
                                    formData.append('key', input.name + '-' + id);
                                    console.log(url)
                                    console.log(that.files[0])
                                    formData.append('file', that.files[0]);

                                    let xhr = new XMLHttpRequest();

                                    xhr.open('PUT', url, true);
                                    xhr.setRequestHeader('Content-Type', that.files[0].type);

                                    xhr.upload.addEventListener("progress", function (event) {
                                        if (event.lengthComputable) {
                                            var complete = (event.loaded / event.total * 100 | 0);
                                            // get progress bar
                                            let progressBar = that.parentNode.querySelector('.finder-progress-bar');
                                            progressBar.style.width = complete + '%';
                                        }
                                    });
                                    xhr.onload = function () {
                                        that.parentNode.classList.remove('finder-loading');
                                        console.log('Terminado:', xhr.status)
                                        if (xhr.status === 200) {
                                            that.style.display = 'block';

                                            // editamos el identificador:
                                            that.parentNode.classList.add('finder-complete');
                                            console.log('Exito:', input.onSuccessUlr)
                                            if (input.onSuccessUlr) {

                                                let url = props.endpoint + input.onSuccessUlr
                                                if (props.environment === 'dev') {
                                                    url = 'http://127.0.0.1:5000' + input.onSuccessUlr
                                                }
                                                axios({
                                                    method: 'PUT',
                                                    url: url,
                                                    data: {},
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'authorization': `Bearer ${props.user.access_token}`
                                                    }
                                                }).then((response) => {
                                                    that.parentNode.classList.add('finder-complete');
                                                    if (input.onSuccessCallback) {
                                                        input.onSuccessCallback(response.data)
                                                    }

                                                }
                                                ).catch((error) => {
                                                    that.parentNode.classList.add('finder-failed');

                                                })
                                            }
                                        } else {
                                            that.parentNode.classList.add('finder-failed');
                                        }
                                    }
                                    xhr.send(that.files[0]);

                                }
                                else {
                                    that.parentNode.classList.add('finder-error');
                                }
                            }} /> : <div className='finder-error' style={{ display: 'block' }}><i className='las la-times'></i> No tiene permisos para subir archivos</div>}

                    </div>
                }
                {
                    input.type === 'checkbox' &&
                    <div className='checkbox'>
                        <label htmlFor={input.name}>{input.label}</label>

                        <input data-selected={input.selected} data-unselected={input.unselected} defaultChecked={defaultvalues && defaultvalues[input.name] && ((input.selected && input.selected === defaultvalues[input.name] && input.selected) || (!input.selected && defaultvalues[input.name]))} name={input.name} type={'checkbox'} />
                    </div>
                }
                {
                    input.type === 'divisor' && <div className="Form-divisor">{input.title}</div>
                }
                {
                    input.disabled && <div className="Form-disabled"><i className='la la-lock' /></div>
                }
                {
                    input.disabled && <div className="Form-disabled-cover"></div>
                }
                {
                    input.type === 'select' &&

                    <div className='select'>
                        <div style={{ display: 'none' }}>
                            <input multiple={input.multiple} defaultValue={defaultvalues && defaultvalues[input.name]} name={input.name} placeholder={input.placeholder} />
                        </div>

                            <Select placeholder={input.placeholder} itemRetrieve={defaultvalues && defaultvalues[input.name.replace('_id', '')]} value={defaultvalues && defaultvalues[input.name]} multiple={input.multiple} onSelect={(item: any) => {
                            // set value in 
                            if (input.multiple) {
                                console.log('Save multiple')
                                let inputElement: any = document.getElementsByName(input.name)[0];
                                inputElement.items = item
                            }
                            else {
                                let inputElement: any = document.getElementsByName(input.name)[0];
                                inputElement.value = item.id
                                callBackSetValue && callBackSetValue(input.name, item.id)
                            }
                        }} method={'get'} options={input.options} path={input.path} columns={input.columns} />
                    </div>
                }
                {
                    input.type === 'icon' &&

                    <div className='icon'>
                        <div style={{ display: 'none' }}>
                            <input defaultValue={defaultvalues && defaultvalues[input.name]} name={input.name} placeholder={input.placeholder} />
                        </div>

                        <Icons onSelect={(item: any) => {
                            // set value in 
                            let inputElement: any = document.getElementsByName(input.name)[0];
                            inputElement.value = item
                        }} method={'get'} options={input.options} path={input.path} columns={input.columns} />
                    </div>
                }
                {
                    input.type === 'excel' &&

                    <div className='excel'>
                        <div style={{ display: 'none' }}>
                            <input className='exceler' defaultValue={defaultvalues && defaultvalues[input.name]} name={input.name} placeholder={input.placeholder} />
                        </div>

                        <Excel onUpdate={(item: any) => {
                            // set value in 
                            let inputElement: any = document.getElementsByName(input.name)[0];
                            inputElement.value = JSON.stringify(item)
                        }} columns={input.columns} defaultValue={defaultvalues && defaultvalues[input.name]} />
                    </div>
                }
                {
                    input.type === 'textarea' &&

                    <textarea defaultValue={defaultvalues && defaultvalues[input.name]} name={input.name} placeholder={input.placeholder} />
                }
                {
                    input.type === 'tab' &&

                    <Tab title={input.title} inputs={input.inputs} errors={errors}>

                        {
                            input.inputs && renderInputs(defaultValues, input.inputs)
                        }
                    </Tab>
                }
                {
                    input.type === 'hierarchy' &&

                    <>

                        <Hierarchy
                            default={defaultvalues}
                            value={defaultvalues && defaultvalues[input.name]}
                            multiple={input.multiple}
                            paths={input.paths}
                            onSelect={(item: any) => {
                                // set value in 
                                if (input.multiple) {
                                    console.log('Save multiple')
                                    let inputElement: any = document.getElementsByName(input.name)[0];
                                    inputElement.items = item
                                }
                                else {
                                    let inputElement: any = document.getElementsByName(input.name)[0];
                                    inputElement.value = item.id
                                    callBackSetValue && callBackSetValue(input.name, item.id)
                                }
                            }} method={'get'} options={input.options} path={input.path} columns={input.columns} />
                    </>
                }
                {
                    input.thumb !== false && input.type === 'file' && defaultvalues && defaultvalues[input.name] &&
                    <div className='file'>
                        <div className='imagination' style={{ backgroundImage: `url(${defaultvalues[input.name]})` }}>

                        </div>
                    </div>
                }
                {
                    input.thumb !== true && input.type === 'file' && defaultvalues && defaultvalues[input.name] &&
                    <a rel={'noreferrer'} href={defaultvalues[input.name]} target={'_blank'} className='downloadFile'>
                        <i className="las la-download"></i> Descargar archivo
                    </a>
                }
                {
                    input.type === 'file' &&

                    <input data-compress={input.compress} name={input.name} type="file" accept={input.accept} placeholder={input.placeholder} />
                }
                {
                    input.type === 'datetime' &&

                    <>
                        <div style={{ display: 'none' }}>
                            <input data-type={'datetime'} defaultValue={defaultvalues && defaultvalues[input.name] && new Date(defaultvalues[input.name]).toISOString()} name={input.name} placeholder={input.placeholder} />
                        </div>
                        <Dater
                            datetime={defaultvalues && defaultvalues[input.name] && new Date(defaultvalues[input.name])}
                            onSelect={(item: any) => {
                                let inputElement: any = document.getElementsByName(input.name)[0];
                                inputElement.value = item.toISOString()
                            }} />
                    </>
                }
                {
                    input.type === 'date' &&

                    <>
                        <div style={{ display: 'none' }}>
                            <input data-type={'date'} defaultValue={defaultvalues && defaultvalues[input.name] && new Date(defaultvalues[input.name]).toISOString()} name={input.name} placeholder={input.placeholder} />
                        </div>
                        <Dater
                            type="date"
                            datetime={defaultvalues && defaultvalues[input.name] && new Date(defaultvalues[input.name])}
                            onSelect={(item: any) => {
                                let inputElement: any = document.getElementsByName(input.name)[0];
                                inputElement.value = item.toISOString()
                            }} />
                    </>
                }
                {
                    errors && errors[input.name] && <div className="Form-error-message">
                        <i className='las la-exclamation-triangle'></i>
                        {
                            errors[input.name].map((error: any, index: any) => {
                                return <div key={index}>{error}</div>
                            })

                        }</div>
                }
            </div >
        })
    }

    return (
        <>

            {!props.type && <div className="Form-background" onClick={backgroundCloser}></div>}
            <div className={`Form-position${props.type ? '-' + props.type : '-center'}`} >
                <div className={`Form ${props.type ? props.type : 'Form-Popup'} ${loading ? 'Form-loading' : ''}`} >
                    {
                        props.header && <Header title={props.header.title} icon={props.header.icon} description={props.header.description} buttons={[
                            {
                                icon: 'las la-times',
                                onClick: backgroundCloser

                            }
                        ]} />
                    }

                    <form autoComplete={'off'} onSubmit={async (e) => {
                        e.stopPropagation();
                        setLoading(true)
                        e.preventDefault();
                        setError(false)
                        setErrors(false)
                        setErrorsFull(false)
                        let url = props.endpoint + props.path
                        if (props.environment === 'dev') {
                            url = 'http://127.0.0.1:5000' + props.path
                        }
                        console.log('Envio a: ', url)
                        // get all inputs json string
                        // get form dom tag this
                        let that = e.target

                        let formElement: any = that;
                        let inputElements: any = formElement.querySelectorAll("input, textarea, select");
                        let jsonObject: any = {};
                        const formData = new FormData();

                        for (var i = 0; i < inputElements.length; i++) {
                            var inputElement = inputElements[i];

                            if (inputElement.name) {
                                // type file
                                if (inputElement.multiple) {
                                    console.log(inputElement.items)
                                    let r = 0
                                    for (let itemx of inputElement.items) {
                                        formData.append(inputElement.name + '[' + r + ']', itemx.id)
                                        r++;
                                    }
                                }

                                else if (inputElement.type === 'checkbox') {
                                    if (inputElement.checked) {
                                        let dataInputSelected = inputElement.getAttribute('data-selected')
                                        if (dataInputSelected) {
                                            formData.append(inputElement.name, dataInputSelected);
                                        }
                                        else formData.append(inputElement.name, 'true');
                                    }
                                    else {
                                        let dataInputUnselected = inputElement.getAttribute('data-unselected')

                                        if (dataInputUnselected) {
                                            formData.append(inputElement.name, dataInputUnselected);
                                        }
                                        else formData.append(inputElement.name, 'false');
                                    }

                                }
                                else if (inputElement.dataset.type === 'datetime') {
                                    if (inputElement.value) {
                                        formData.append(inputElement.name, inputElement.value);
                                    }

                                }
                                else if (inputElement.type === 'file') {
                                    if (inputElement.files[0]) {
                                        if (inputElement.dataset.compress === 'image-splash') {
                                            console.log('Compress image ->')
                                            let inputElementx = inputElement;
                                            await resizeImage({
                                                file: inputElement.files[0],
                                                maxSize: 2000
                                            }).then((resizedImage: any) => {
                                                //@ts-ignore
                                                console.log('foto comprimida:', resizedImage)
                                                formData.append(inputElementx.name, resizedImage);
                                            }).catch((err) => {
                                                console.error(err);
                                            });
                                        }
                                        formData.append(inputElement.name, inputElement.files[0]);
                                    }
                                } else {
                                    if (inputElement.value){
                                        jsonObject[inputElement.name] = inputElement.value;
                                        formData.append(inputElement.name, inputElement.value);
                                    }
                                  
                                }

                            }

                        }



                        // let data: any = JSON.stringify(jsonObject);
                        console.log(formData)

                        // send data with Axios to url

                        setTimeout(() => {
                            const headers = {

                                "Content-Type": "multipart/form-data",
                                // beraarer token props.user
                                'Authorization': 'Bearer ' + props.user.access_token
                            }
                            axios({
                                method: props.method,
                                url: url,
                                data: formData,

                                headers: headers
                            })
                                .then(function (response) {
                                    // if response.data is string
                                    if (typeof response.data === 'string') {
                                        console.log('error')
                                        return false;
                                    }

                                    console.log(response)
                                    setLoading(false)
                                    console.log(response);
                                    if (props.method === 'delete') {
                                        props.audio && soundDelete()
                                    }
                                    else props.audio && soundEnter()
                                    setSuccess(true)
                                    props.onSuccess && props.onSuccess(response)

                                }
                                ).catch(({ response }) => {
                                    console.log('former:', formScroll)
                                    formScroll && formScroll?.current?.scrollTo(0, 0);
                                    console.log(response.data);
                                    console.log(response.status);
                                    console.log(response.headers);
                                    console.log('error')
                                    setErrors(response.data.errors)
                                    setLoading(false)
                                    console.log(error);
                                    props.audio && soundError()
                                    setError({
                                        title: 'Error',
                                        message: 'Se ha producido un error'
                                    })
                                })
                        }, 200);

                    }}
                    >

                        {
                            loading && <div className="Loading" >

                            </div>
                        }

                        <div className='Form-content' ref={formScroll} >


                            {
                                success && <div className="Form-success">
                                    <i className='las la-check'></i>
                                    {
                                        (props.method === 'delete' || props.method === 'DELETE') ?
                                            <div className="Form-success-title">{'Eliminado correctamente'}</div> :
                                            <div className="Form-success-title">{'Guardado correctamente'}</div>
                                    }

                                </div>

                            }
                            {
                                error && <div className="Form-error-header" onClick={() => { setErrorsFull(!errorsFull) }}>
                                    <i className="las la-exclamation-circle"></i>
                                    <div>{errors && errors.general ? errors.general : 'Se ha producido un error, revise los campos del formulario'}</div>
                                    <b>VER ERRORES</b>
                                </div>

                            }

                            {
                                errorsFull && errors && Object.keys(errors).map((item, index) => {
                                    return <div className="Form-warning" key={index}>
                                        <div className="Form-warning-title">{item}
                                        </div>
                                        {
                                            <div className="Form-warning-message">{
                                                errors[item].map((error: any, index: any) => {
                                                    return <div key={index}>{error}</div>
                                                })

                                            }</div>
                                        }
                                    </div>

                                })
                            }
                            {
                                (props.method === 'delete' || props.method === 'DELETE') && <div className='Form-delete-info'>
                                    <div className="Form-delete-info-title">

                                        ¿Seguro que deseas eliminarlo?
                                    </div>
                                    <div className="Form-delete-info-description">

                                        Esta acción es irreversible y afectará a todos los elementos asociados a esta entidad. La eliminación de objetos de la base de datos del sistema puede requerir permisos especiales.
                                    </div>

                                </div>
                            }
                            {
                                renderInputs(defaultValues, props.inputs)
                            }
                        </div>

                        {
                            props.buttons === false ? null :

                                <div className='Form-buttons'>
                                    {
                                        loading && <div className="Loading" >

                                        </div>
                                    }
                                    {
                                        !props.submit && props.method === 'delete' && <button type='submit'>
                                            ELIMINAR
                                        </button>
                                    }
                                    {
                                        !props.submit && props.method === 'post' && <button type='submit'>
                                            INSERTAR
                                        </button>
                                    }
                                    {
                                        !props.submit && props.method === 'put' && <button type='submit'>
                                            GUARDAR
                                        </button>
                                    }
                                    {
                                        props.submit && <button type='submit'>
                                            {props.submit.title}
                                            <i className={props.submit.icon}></i>

                                        </button>
                                    }
                                    {
                                        props.secondary && <button className='Form-secondary' onClick={props.secondary.onClick}>
                                            <i className={props.secondary.icon}></i>
                                            {props.secondary.title}</button>
                                    }
                                </div>}
                    </form>
                </div>
            </div>
            <ReactTooltip className="toolper" place="bottom" effect="float" />

        </>
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Form)


function resizeImage(settings: any) {
    var file = settings.file;
    var maxSize = settings.maxSize;
    var reader = new FileReader();
    var image: any = new Image();
    var canvas: any = document.createElement('canvas');
    var dataURItoBlob = function (dataURI: any) {
        var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
            atob(dataURI.split(',')[1]) :
            unescape(dataURI.split(',')[1]);
        var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var max = bytes.length;
        var ia = new Uint8Array(max);
        for (var i = 0; i < max; i++)
            ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
    };
    var resize = function () {
        var width = image.width;
        var height = image.height;
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        return dataURItoBlob(dataUrl);
    };
    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.onload = function (readerEvent: any) {
            image.onload = function () { return ok(resize()); };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};