import './App.css';

import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import Scenes from './scenes/alicunde';
// import ScenesComitas from './scenes/comitas';
//import ScenesBonic from './scenes/b-onic';
import { BrowserRouter } from "react-router-dom";

function App() {


  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Scenes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
