

import Calendar from "../../componets/Calendar";

function Clients() {

    const events = [{
        id: 5,
        color: '#8281fd',
        from: '2022-01-15T12:00:00+00:00',
        to: '2022-01-15T17:00:00+00:00',
        title: '2 Obras',
    }, {
        id: 5,
        color: '#8281fd',
        from: '2022-01-15T12:00:00+00:00',
        to: '2022-01-15T17:00:00+00:00',
        title: '10 Personal',
    }, {
        id: 6,
        color: '#8281fd',
        from: '2022-01-15T13:00:00+00:00',
        to: '2022-01-18T21:00:00+00:00',
        title: '5 Maquinaria',
    }, {
        id: 6,
        color: '#8281fd',
        from: '2022-01-15T13:00:00+00:00',
        to: '2022-01-18T21:00:00+00:00',
        title: 'Maquinaria',
    }, {
        id: 7,
        color: '#8281fd',
        from: '2022-01-15T14:00:00+00:00',
        to: '2022-01-18T21:00:00+00:00',
        title: 'Obras',
    }];
    return (

        <div style={{ flex: 1, minWidth: '100%', flexDirection: 'column' }}>


            <Calendar
                onClickEvent={(event: any) => console.log('this is an event', event)}
                onChange={(dates: any) => console.log(dates)}
                onClickTimeLine={(date: any) => console.log(date)}
                events={events}
            />
        </div>

    );
}

export default Clients
