

import { Details } from "../../componets/Details";
import { Widget } from "../../componets/Widget";

function Clients() {
    return (
        <>
            <div className="Widgets">
                <div className="Widgets">

                    <Widget title="Obras">
                        <Details
                            items={
                                [
                                    {
                                        title: "Maquinarias",
                                        detail: "2",
                                        icon: "las la-square",
                                    },
                                    {
                                        title: "Terminadas",
                                        detail: "11.000",
                                        icon: "las la-square",
                                    },
                                    {
                                        title: "Turnos",
                                        detail: "23.000",
                                        icon: "las la-square",
                                    },
                                    {
                                        title: "Horas",
                                        detail: "23.000",
                                        icon: "las la-square",
                                    },
                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Partes">
                        <Details
                            items={
                                [
                                    {
                                        title: "Fallos de Partes",
                                        detail: "3",
                                        icon: "las la-square",
                                    },
                                    {
                                        title: "Partes con Retraso",
                                        detail: "3",
                                        icon: "las la-square",
                                    },
                                    {
                                        title: "Partes correctos",
                                        detail: "22.000",
                                        icon: "las la-square",
                                    }
                                ]
                            }
                        />

                    </Widget>
                    <Widget title="KPIs">
                        <Details
                            items={
                                [
                                    {
                                        title: "Operaciones",
                                        detail: "0",
                                        icon: "las la-square",
                                    },

                                    {
                                        title: "Mano de Obra",
                                        detail: "0",
                                        icon: "las la-square",
                                    },
                                    {
                                        title: "Jefes de Turno",
                                        detail: "0",
                                        icon: "las la-square",
                                    },
                                    {
                                        title: "Notas",
                                        detail: "0",
                                        icon: "las la-square",
                                    },
                                    {
                                        title: "Mensajes",
                                        detail: "0",
                                        icon: "las la-square",
                                    }
                                ]
                            }
                        />
                    </Widget>
                </div>


            </div>
        </>
    );
}

export default Clients
