import ReactMarkdown from "react-markdown"


type Props = {
    document: any
}


export default function Budget(props: Props) {

    const productPrice = (product: any) => {
        const total = props.document?.total
        if (total) {
            // sum all products importance
            let importance = 0
            props?.document?.project?.productions?.map((item: any, index: number) => {

                importance += item.importance
                return true
            })
            let unit = total / importance
            let priceProduct = product.importance * unit
            return priceProduct.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

        }
        return 0
    }

    const renderTotal = (items: any, discount: any = 0) => {
        let total = props.document.total
        // discount percentaje:
        let discountPercent = (discount / 100) * total
        // discount value:
        let discountValue = total - discountPercent
        // let total = 0;
        // items.map((item: any) => {
        //     total += item.importance
        //     return true
        // }
        // )
        return <div className='Total'>

            <div className='Title'>
                <b>Total</b>
            </div>
            <div className='Value'>
                <b>{discountValue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</b>
                {' '}EUR
            </div>
        </div>


    }

    const renderDiscount = (items: any, discount: any) => {
        let total = props.document.total
        // discount percentaje:
        let discountPercent = (discount / 100) * total
        // discount value:
        return discountPercent.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }

    // order productions by importance
    const productions = props.document?.project?.productions?.sort((a: any, b: any) => {
        return b.importance - a.importance
    })

    return <div className='Budget'>
        <div className="P">
            <ReactMarkdown>{`Este presupuesto ha sido creado exclusivamente para **${props?.document?.project?.organization?.name}** el día **${props?.document?.generation?.split('T')[0]}**. Presupuesto revisado y autorizado por el departamento financiero de **${props.document?.project?.brand?.title} ${props?.document?.project?.brand?.subtitle}**.`}</ReactMarkdown>


        </div>

        {props?.document?.resources === 'yes' && <>
            <div className="H6">Equipo de Desarrollo</div>
            <div className="P">Costes por recurso y horas requeridas para el total del desarrollo.</div>

            <table cellSpacing="0">
                <thead>
                    <td>Pieza del Software</td>
                    <td>Coste</td>
                </thead>
                <tbody>
                    {
                        props?.document?.project?.productions?.map((item: any, index: number) => {
                            return <tr key={index}>
                                <td className="name"><b>{item.name}</b><span>{item.description}</span></td>
                                <td className="price"><b>{productPrice(item)}</b> €</td>

                            </tr>
                        })
                    }
                </tbody>

            </table>
        </>}
        <table>
            <thead>
                <td>Pieza del Software</td>
                <td>Coste</td>
            </thead>
            <tbody>
                {
                    productions?.map((item: any, index: number) => {
                        return <tr key={index}>
                            <td className="name"><b>{item.name}</b><span>{item.description}</span></td>
                            <td className="price"><b>{productPrice(item)}</b> EUR</td>
                        </tr>
                    })
                }
            </tbody>


        </table>
        <div className="Totaler">
            {
                props?.document?.project?.productions && renderTotal(props?.document?.project?.productions)
            }</div>
        <div className="P">Ofrecemos <b>3 modalidades</b> de desarrollo que modifican el precio total del presupuesto. Cuanto mayor sea la duración del desarrollo, mayor es nuestro margen en la gestión de recursos, permitiéndonos cierta flexibilidad presupuestaria.</div>
        <style>
            {
                `
                .Totaler{margin-top:20px;margin-bottom:20px; text-align:right;font-size:20px}
                .Totaler .Title{padding-right:20px; width:200px; display:inline-block}
                .Totaler .Value{font-weight:100; color:#aaa; width:167px; display:inline-block;border-left:1px solid #aaa}
                .Totaler .Value b{color:#000}
                .price {color:#aaa}
                .price b{color:#000}
                .plans{vertical-align:top; margin-top:-10px; width:105%; }
                    .plan {
                        overflow:hidden;
                        vertical-align:top;
                        box-sizing: border-box;
                        display:inline-block;width:33%;
                        border-radius:5px;
                        background:#eee;
                        width:205px;
                        padding:20px 0 0px 0px;
                        margin:0px 10px 0 0
                    }
                    .plan .price{text-align:right;background:#ccc;padding:10px 15px; color:#000}
                    .plan .price .Title{display:none}
                    .plan .price .Value b{font-weight:bold;font-size:20px}
                    .plan .title b{ font-size:20px; font-weight:100}
                    .plan .price b{color:#000; font-size:13px; font-weight:100}
                    .plan .titler{padding-left:15px;}
                    .plan .titler b{font-size:16px;font-weight:bold;}
                    .plan .description{padding-left:15px;padding-right:10px;line-height:1.5;margin-top:10px;margin-bottom:10px;font-size:12px}
                    .plan .minus{font-weight:100;font-size:14px; text-align:right;background:#000;padding:10px 15px; color:#fff}
                    .plan .minus b{font-size:18px;margin-right:5px;}
                    .plan .minus span{float:left;margin-top:4px; font-size:11px;opacity:0.6;text-transform:uppercase}
                    `
            }
        </style>
        <div className="plans">
            <div className="plan">
                <div className="titler">
                    <b >Express</b>
                </div>
                <div className="description">
                    Completado en <b>{props.document.duration}</b> días para todas las piezas del software, con <b>0</b> días de margen.
                </div>
                <div className="price">
                    <b>
                        {
                            props?.document?.project?.productions && renderTotal(props?.document?.project?.productions, 0)
                        }
                    </b>

                </div>
                <div className="minus">
                    <span>Ahorro</span>
                    <b>{
                        props?.document?.project?.productions && renderDiscount(props?.document?.project?.productions, 0)
                    }</b>
                    EUR
                </div>

            </div>
            <div className="plan">
                <div className="titler">
                    <b>Estándar</b>
                </div>
                <div className="description">
                    Desarrollo completado en <b>{props.document.duration}</b> días, más un tiempo de maniobra de <b>49</b> días.
                </div>
                <div className="price">
                    <b>
                        {
                            props?.document?.project?.productions && renderTotal(props?.document?.project?.productions, 9)
                        }
                    </b>

                </div>
                <div className="minus">
                    <span>Ahorro</span>
                    <b>{
                        props?.document?.project?.productions && renderDiscount(props?.document?.project?.productions, 9)
                    }</b>
                    EUR
                </div>

            </div>

            <div className="plan">
                <div className="titler">
                    <b>Extendido</b>
                </div>
                <div className="description">
                    Desarrollo completado en <b>{props.document.duration}</b> días, más un tiempo de maniobra de <b>72</b> días.
                </div>
                <div className="price">
                    <b>
                        {
                            props?.document?.project?.productions && renderTotal(props?.document?.project?.productions, 17)
                        }
                    </b>
                </div>
                <div className="minus">
                    <span>Ahorro</span>
                    <b>{
                        props?.document?.project?.productions && renderDiscount(props?.document?.project?.productions, 17)
                    }</b>
                    EUR
                </div>

            </div>


        </div>

    </div>
}
