// @ts-ignore
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Replacer from '../../../functions/replacer';

type Props = {
    component: any,
    document: any
}

export default function DiagramComponent(props: Props) {
    // random id
    const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const [diagramId] = useState(id);



    useEffect(() => {
        // get by class:
        // @ts-ignore
        setTimeout(() => {
            const id = 'documentiframe'
            // @ts-ignore
            const iframe = window.frames ? window.frames[id] : window.getElementById(id);
            const iframeWindow = iframe.contentWindow || iframe;
            const iframeDocument = iframeWindow.document;
            const diagramer = iframeDocument.getElementById(diagramId);
            console.log('Creamos diagrama')
            console.log(diagramer)
            if (diagramer) {

                // @ts-ignore
                diagramer.innerHTML = ''
                let diagramText = ''
                // @ts-ignore
                props.component?.content.map((item: any) => {
                    diagramText += item + '\n'
                    return true
                })
                console.log('doagra, text:', diagramText)
                // @ts-ignore
                console.log('funcion diagrama', Diagram)

                // @ts-ignore
                var diagram = Diagram.parse(diagramText);
                console.log(diagram)
                diagram.drawSVG(diagramer);

            }
        }, 1000);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return <div className='Diagram'>
        <div id={diagramId}>
            MIRA:
        </div>
        <div className='Diagram-legal'>
            <i className='las la-info-circle' /> <ReactMarkdown>{Replacer(props.component.detail, props.document, '')}</ReactMarkdown>

        </div>

    </div>
}
