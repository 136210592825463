import './icons.css'
import { connect } from "react-redux";
import { useState } from 'react';
import { Method } from 'axios';

import Iconers from './icons.json'

const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
// function login props redux connect

interface DispatchProps {
    user: any,
    project: any,
    environment: string,
    onSelect: (item: any) => void
    columns: {
        onPress?: (data: any) => void
        width: number
        name: string,
        title?: string
        icon?: string
    }[],
    options?: any,
    reload: string,
    path?: string,
    method?: Method,
    items?: any[]
}
type Props = DispatchProps

const Select: React.FC<Props> = (props) => {
    const [error] = useState<any>(false);
    const [loading] = useState<any>(false);
    const [selected, setSelected] = useState<any>(null);
    const [toggle, setToggle] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    // data
    // get data with axios
    // reload if change

    let icons: any = Iconers.list
    return (< div className='Icons' >
        {
            selected && <div className='selected'>
                {
                    <div className='unselected' onClick={() => {
                        setToggle(!toggle)
                    }}>
                        <span><i className={selected}></i> {selected}</span>
                        <b>{icons.length}</b>
                        <i className='las la-angle-down'></i>
                    </div>
                }
            </div>
        }
        {
            !selected && <div className='unselected' onClick={() => {
                setToggle(!toggle)
            }}>
                Seleccionar <b>{icons.length}</b>
                <i className='las la-angle-down'></i>
            </div>
        }
        {
            toggle && <div className='search'>
                <input type='text' placeholder='Buscar' value={search} onChange={(e) => {
                    setSearch(e.target.value)
                }} />
            </div>
        }
        {
            toggle && <div className='list'>
                {
                    // filter search
                    icons.filter((item: any) => {
                        console.log(search)
                        return item.toLowerCase().includes(search.toLowerCase())
                    }).map((item: any, index: number) => {

                        return (<div key={index} className='item' onClick={() => {
                            setSelected(item)
                            props.onSelect(item)
                            setToggle(!toggle)
                        }}>
                            <i className={item}></i>
                        </div>)
                    })
                }
            </div>
        }
        {
            error && <div className='error'>
                {error}
            </div>
        }
        {
            loading && <div className="Loading" >

            </div>
        }
    </div>)
}

export default connect(mapStateToProps, mapDispatchToProps)(Select)

