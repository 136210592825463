


type Props = {
    component: any,
    document: any
}

export default function Team(props: Props) {



    return <div className='Team'>
        {
            props?.component && props?.component?.content && props?.component?.content.map((person: any, index: number) => {
                return <div className="person" key={index}>
                    <div className={'face face-' + person?.person} style={{ backgroundImage: `url(/graphics/images/people/${person?.person}/selfie.jpg)` }}></div>
                    <div className="name">{person?.name}</div>
                    <div className="position">{person?.position}</div>
                    <div className="company">{props.document?.project?.brand?.title} {props.document?.project?.brand?.subtitle}</div>
                </div>
            })
        }
    </div>
}
