


type Props = {
    document: any
}

export default function Specifications(props: Props) {

    // order specifications by position
    const specifications = props?.document?.project?.specifications.sort((a: any, b: any) => {
        // convert string in int
        let aPosition = (a.position) + 1
        let bPosition = (b.position) + 1
        if (a.position === 'None') aPosition = 0;
        if (b.position === 'None') bPosition = 0;
        
        return aPosition - bPosition
    })

    return <div className='Specifications x'>
        <ul>
            {
                specifications?.map((file: any, index: number) => {
                    return <li key={index}>
                        <b>{file.name}</b>
                        <p>{file.description}</p>

                    </li>
                })
            }
        </ul>
    </div>
}
