

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Meets() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header icon="las la-video" title="Reuniones" description="Listado de eventos agendados con clientes" buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        path={`/meet?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    path={'/meet'}
                    method={'post'}
                    header={
                        {
                            icon: "las la-universal-access",
                            title: "Nueva Reunión",
                            description: "Este texto es una prueba"
                        }
                    }
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                                placeholder: 'Título de la Reunión',
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                description: "Requiere incorporar una descripción de la reunión",
                                type: 'textarea'
                            },
                            {
                                name: 'organization_id',
                                title: "Organización",
                                description: "La reunión puede no estar asociada a un proyecto",
                                type: 'select',
                                path: '/organization',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'project_id',
                                title: "Projecto",
                                description: "La reunión puede no estar asociada a un proyecto",
                                type: 'select',
                                path: '/project',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },

                            {
                                type: 'divisor',
                                title: 'Fechas'
                            },
                            {
                                name: 'meeting_start',
                                title: "Fecha de Reunión",
                                type: 'datetime'
                            },
                            {
                                name: 'meeting_finish',
                                title: "Fecha de Reunión",
                                type: 'datetime'
                            },
                            {
                                type: 'divisor',
                                title: 'Asistentes'
                            },
                            {
                                name: 'contacts',
                                title: "Invitados",
                                type: 'select',
                                multiple: true,
                                path: '/contact',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                        ]
                    } />}
                {update && <Form
                    path={'/meet?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'project_id',
                                title: "Projecte",
                                type: 'select',
                                path: '/project',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'meeting_start',
                                title: "Fecha de Reunión",
                                type: 'datetime'
                            },
                            {
                                name: 'meeting_finish',
                                title: "Fecha de Reunión",
                                type: 'datetime'
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                                placeholder: 'Título de la Reunión',
                            },

                            {
                                name: 'description',
                                title: "Descripción",
                                type: 'textarea'
                            },
                            {
                                name: 'conclusion',
                                title: "Conclusión",
                                type: 'textarea'
                            }
                        ]
                    } />}
                <Widget >
                    <Table reload={tableKey}
                        path={'/meet'}
                        project={true}
                        title="Listado de Reuniones"
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    name: 'project.name',
                                    title: "Compañía",
                                    width: 300

                                },
                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 300

                                },
                                {
                                    width: 100,

                                    name: 'contacts',
                                    title: "Contactos",
                                    type: 'select',
                                    render: (item: any) => {
                                        return item?.tickets?.length
                                    }

                                },
                                {
                                    name: 'meeting_start',
                                    title: "Fecha de Reunión",
                                    width: 200,
                                    type: 'datetime'
                                },
                                {
                                    name: 'meeting_start',
                                    title: "",
                                    width: 200,
                                    type: 'since'
                                },


                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Meets
