

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Contacts() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header title="Contactos" icon="las la-user-tag" description="Equipo asociado a Organizaciones" buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/contact?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    path={'/contact'}
                    method={'post'}
                    header={{
                        title: 'Nuevo contacto',
                        icon: 'las la-pen'
                    }}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'organization_id',
                                title: "Organizatione",
                                type: 'select',
                                path: '/organization',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'surname',
                                title: "Apellido",
                                type: 'text',
                            },
                            {
                                type: 'tab',
                                title: 'Datos de Contacto',
                                inputs: [
                                    {
                                        name: 'email',
                                        title: "Email",
                                        type: 'text',
                                    },
                                    {
                                        name: 'phone',
                                        title: "Teléfono",
                                        type: 'text',
                                    }
                                ]
                            },
                            {
                                type: 'tab',
                                title: 'Información Complementaria',
                                inputs: [
                                    {
                                        name: 'job',
                                        title: "Cargo",
                                        type: 'text',
                                    },
                                    {
                                        name: 'personal',
                                        title: "Información Personal",
                                        type: 'text',
                                    }
                                ]
                            }


                        ]
                    } />}
                {update && <Form
                    path={'/contact?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'organization_id',
                                title: "Organizatione",
                                type: 'select',
                                path: '/organization',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'surname',
                                title: "Apellido",
                                type: 'text',
                            },
                            {
                                name: 'email',
                                title: "Email",
                                type: 'text',
                            },
                            {
                                name: 'phone',
                                title: "Teléfono",
                                type: 'text',
                            },
                            {
                                name: 'job',
                                title: "Cargo",
                                type: 'text',
                            },
                            {
                                name: 'personal',
                                title: "Información Personal",
                                type: 'text',
                            }
                        ]
                    } />}
                <Widget >
                    <Table reload={tableKey}
                        path={'/contact'}
                        method={'get'}
                        title="Listado de Contactos"
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    name: 'organization.name',
                                    title: "Compañía",
                                    width: 100

                                },
                                {
                                    name: 'commision',
                                    title: "Nombre y Apellido",
                                    width: 200,
                                    render: (item: any) => {
                                        // eslint-disable-next-line jsx-a11y/alt-text
                                        return `${item.name} ${item.surname}`
                                    }
                                },

                                {
                                    name: 'email',
                                    title: "Email",
                                    width: 300

                                },

                                {
                                    name: 'creation',
                                    title: "Creación",
                                    type: 'date',
                                    width: 200
                                },

                                {
                                    name: 'creation',
                                    type: 'since',
                                    title: "Creación",
                                    width: 200
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Contacts
