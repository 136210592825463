import './select.css'
import { connect } from "react-redux";
import {  useState } from 'react';

import Select from './select'


const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
// function login props redux connect

interface DispatchProps {
    default: any,
    user: any,
    paths: any,
    project: any,
    endpoint: string,
    environment: string,
    onSelect: (item: any) => void
    columns: {
        onPress?: (data: any) => void
        width: number
        name: string,
        title?: string
        icon?: string
    }[],
    multiple?: boolean,
    options?: any,
    reload: string,
    value?: any,
    path?: string,
    itemRetrieve?: any,
    items?: any[]
}
type Props = DispatchProps

const Hierarchy: React.FC<Props> = (props) => {
    const [dataSelected, setDataSelected] = useState<any>({});
    const [position, setPosition] = useState<number>(0);

    return (< div className='Hierarchy' >
        {
            props.paths.map((path: any, index: any) => {
                // añadimos al path del input todos los campos:
                let path_to_call = path.path;
                // have "?" in path_to_call
                if (path_to_call.indexOf('?') > -1) {


                }
                else {
                    path_to_call = path_to_call + '?';
                }
                dataSelected && Object.keys(dataSelected).map((key: any) => {
                    path_to_call = path_to_call + '' + key + '=' + dataSelected[key].id + '&';
                    return null
                })
                console.log('resultado:', path_to_call)


                if (position >= index) {


                    return <div >
                        <div className='Hierarchy-title'>
                            {path.title}
                        </div>
                        <div style={{ display: 'none' }}>
                            <input defaultValue={props.default && props.default[path.name]} name={path.name} placeholder={path.placeholder} />
                        </div>
                        <Select
                            itemRetrieve={props.default && props.default[path.name.replace('_id', '')]}
                            value={props.default && props.default[path.name]}
                            multiple={path.multiple}
                            onOpen={
                                () => {
                                    setPosition(index);
                                }
                            }
                            onSelect={(item: any) => {
                                let cloneDataSelected = { ...dataSelected }
                                cloneDataSelected[path.name] = item
                                setDataSelected(cloneDataSelected)
                                if (path.multiple) {
                                    console.log('Save multiple')
                                    let inputElement: any = document.getElementsByName(path.name)[0];
                                    inputElement.items = item
                                }
                                else {
                                    let inputElement: any = document.getElementsByName(path.name)[0];
                                    inputElement.value = item.id
                                }
                                setPosition(index + 1);

                            }}
                            method={'get'}
                            options={path.options}
                            path={path_to_call}
                            columns={path.columns} />
                    </div>

                }
                return null
            })
        }

    </div >
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Hierarchy)
