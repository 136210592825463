import  { useEffect, useState } from 'react'
import Column from './column'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import './index.scss'
import { connect } from 'react-redux'
import axios from 'axios'
const mapStateToProps = (state: any, ownProps: any) => (state)


interface DragerColumnContentType {
    title: string
    color: string
    description: string
    id: string,
    list?: any[]
}
interface DragerColumnType {
    [key: string]: DragerColumnContentType
}
interface DragerProps {
    user: any,
    project: any,
    method: any,
    endpoint: string,
    environment: string,
    path: string
    columns: DragerColumnType
}
function DragerComponent(props: DragerProps) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [columns, setColumns] = useState<DragerColumnType>(props.columns)
    const [search, setSearch] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {
        getDataColumns()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getDataColumns = async () => {

        let temporalColumns: any = {}
        let columnsArray = Object.keys(props.columns)
        console.log(columnsArray)
        for (let iColumn = 0; iColumn < columnsArray.length; iColumn++) {
            // eslint-disable-next-line no-loop-func
            await getData(columnsArray[iColumn]).then(res => {
                if (res) {
                    temporalColumns = ({ ...temporalColumns, [columnsArray[iColumn]]: { ...props.columns[columnsArray[iColumn]], list: res } })

                }
            })
        }
        setLoading(false)
        setColumns(temporalColumns)
    }

    const getData = async (status: string, pager: any = 1, order = '', direction = '') => {
        return new Promise((resolve, reject) => {




            let url = props.endpoint + props.path
            if (props.environment === 'dev') {
                url = 'http://127.0.0.1:5000' + props.path
            }
            url = url + '?mode=recovery&page=' + pager
            if (props.project) {
                url = url + '&project=' + props.project.id
            }

            if (status) {
                url = url + '&status=' + status
            }
            if (order) {
                url = url + '&order=' + order
            }
            if (direction) {
                url = url + '&direction=' + direction
            }
            if (search) {
                url = url + '&search=' + search
            }
            axios({
                method: props.method || 'get',
                url: url,
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${props.user.access_token}`
                }
            }).then(response => {
                if (response.data) {
                    if (response.data.items && Array.isArray(response.data.items)) {
                        resolve(response.data.items)
                    }


                }


            }
            ).catch(error => {
                console.log('Error')
                reject()

            })
        })

    }
    const onDragEnd = ({ source, destination }: DropResult) => {
        console.log(source)
        // Make sure we have a valid destination
        if (destination === undefined || destination === null) return null

        // Make sure we're actually moving the item
        if (
            source.droppableId === destination.droppableId &&
            destination.index === source.index
        )
            return null

        // Set start and end variables
        const start = columns[source.droppableId]
        const end = columns[destination.droppableId]


        // If start is the same as end, we're in the same column
        if (start === end) {
            // Move the item within the list
            // Start by making a new list without the dragged item
            const newList = start.list && start.list.filter(
                (_: any, idx: number) => idx !== source.index
            )
            start.list && updateStatus(start.list[source.index].id, end.id)

            // Then insert the item at the right location
            start.list && newList && newList.splice(destination.index, 0, start.list && start.list[source.index])

            // Then create a new copy of the column object
            const newCol = {
                id: start.id,
                title: start.title,
                color: start.color,
                description: start.description,
                list: newList
            }
            console.log(newList)

            // Update the state
            setColumns((state: any) => ({ ...state, [newCol.id]: newCol }))
            return null
        } else {
            // If start is different from end, we need to update multiple columns
            // Filter the start list like before
            const newStartList = start.list && start.list.filter(
                (_: any, idx: number) => idx !== source.index
            )

            // Create a new start column
            const newStartCol = {
                id: start.id,
                color: start.color,
                title: start.title,
                description: start.description,
                list: newStartList
            }

            // Make a new end list array
            const newEndList = end.list
            start.list && updateStatus(start.list[source.index].id, end.id)
            // Insert the item into the end list
            newEndList && newEndList.splice(destination.index, 0, start.list && start.list[source.index])
            // Create a new end column
            const newEndCol = {
                id: end.id,
                color: end.color,
                title: end.title,
                description: end.description,
                list: newEndList
            }

            // Update the state
            setColumns((state: any) => ({
                ...state,
                [newStartCol.id]: newStartCol,
                [newEndCol.id]: newEndCol
            }))
            return null
        }
    }

    const updateStatus = (id: string, status: string) => {

        // axios put

        let url = props.endpoint + props.path
        if (props.environment === 'dev') {
            url = 'http://127.0.0.1:5000' + props.path
        }
        url = url + '?id=' + id
        if (props.project) {
            url = url + '&project=' + props.project.id
        }
        axios({
            method: 'put',
            url: url,
            data: {
                status: status
            },
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${props.user.access_token}`
            }
        }).then(response => {
        })
    }
    if (loading) {
        return <div>Loading...</div>
    }
    return (
        <>
                    <div style={{display:'none'}}>
                <input type='text' placeholder='Search' onChange={(e) => setSearch(e.target.value)} />
                    </div>

        <div className='Drager'>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className='Drager-columns'>
                    {
                        Object.keys(columns).map((columnItem: any) => {

                            return <Column col={columns[columnItem]} key={columnItem} />
                        })
                    }
                </div>
            </DragDropContext>

            </div></>
    )
}
export default connect(mapStateToProps,)(DragerComponent)

