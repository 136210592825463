import {
  SET_USER,
  CLEAR,
  SET_SCHEME,
  SET_REPOSITORY,
  SET_ORGANIZATION,
  SET_PROFILE,
  SET_LANGUAGES,
  SET_LANGUAGE,
  SET_LANGUAGES_GITHUB,
  SET_PROFILE_GITHUB,
  SET_SCHEME_GITHUB,
  SET_MODE,
  SET_THEME,
  SET_AUDIO,
  SET_ENVIRONMENT,
  SET_ROLE,
  SET_PROJECT

} from '../constants/actions';

export function setOrganization(payload) {
  return {
    type: SET_ORGANIZATION,
    payload
  };
}
export function setUser(payload) {
  return {
    type: SET_USER,
    payload
  };
}


export function setScheme(payload) {




  return {
    type: SET_SCHEME,
    payload
  };
}

export function setRepository(payload) {
  return {
    type: SET_REPOSITORY,
    payload
  };
}


export function setLanguage(payload) {
  return {
    type: SET_LANGUAGE,
    payload
  };
}

export function clear(payload) {
  return {
    type: CLEAR,
    payload
  };
}

export function setProfile(payload) {
  return {
    type: SET_PROFILE,
    payload
  };
}

export function setLanguages(payload) {
  return {
    type: SET_LANGUAGES,
    payload
  };
}

export function setLanguagesGithub(payload) {
  return {
    type: SET_LANGUAGES_GITHUB,
    payload
  };
}

export function setProfileGithub(payload) {
  return {
    type: SET_PROFILE_GITHUB,
    payload
  };
}

export function setSchemeGithub(payload) {
  return {
    type: SET_SCHEME_GITHUB,
    payload
  };
}

export function setMode(payload) {
  return {
    type: SET_MODE,
    payload
  };
}

export function setTheme(payload) {
  return {
    type: SET_THEME,
    payload
  };
}

export function setAudio(payload) {
  return {
    type: SET_AUDIO,
    payload
  };
}

export function setEnvironment(payload) {
  return {
    type: SET_ENVIRONMENT,
    payload
  };
}

export function setRole(payload) {
  return {
    type: SET_ROLE,
    payload
  };
}


export function setProject(payload) {
  return {
    type: SET_PROJECT,
    payload
  };
}