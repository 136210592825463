

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";
import Document from "../../../../componets/Document";
import { connect } from "react-redux";



const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
// function login props redux connect

interface DispatchProps {
    project: any
}
type Props = DispatchProps

const Audits: React.FC<Props> = (props) => {
    const [form, setForm] = useState(false)
    const [audit, setAudit] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [document, setDocument] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')

    const tabs = validations.map((item, index) => {
        return {
            title: item.label,
            type: 'tab',
            inputs: [
                {
                    name: `audit_id_${item.id}`,
                    type: 'checkbox',
                    label: item.label
                },
                {
                    name: `audit_${item.id}_name`,
                    title: "Archivo",
                    type: 'text',
                },
                {
                    name: `audit_${item.id}_description`,
                    title: "Mensaje Específico",
                    type: 'textarea'
                },
                {
                    name: `audit_${item.id}_priority`,
                    title: "Prioridad",
                    type: 'select',
                    placeholder: 'Método del primer contacto',
                    columns: [
                        {
                            name: 'label',
                            title: 'Nombre',
                        }
                    ],
                    options: [
                        {
                            id: 'ok',
                            label: 'Bien!'
                        },
                        {
                            id: '|',
                            label: 'Mal'
                        },
                        {
                            id: '||',
                            label: 'Alarmante'
                        },
                        {
                            id: '|||',
                            label: 'Peligroso'
                        }
                    ]
                },
            ]
        }
    })
    console.log('tabs:', tabs)

    return (

        <>
            {document && <Document documentId={document} type="audits" close={() => { setDocument(false) }} />}

            <Header icon="las la-sliders-h" title="Requerimientos" description="Definición de los requerimientos del software" buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }, {
                onClick: () => {
                    setAudit(true)
                },
                icon: 'las la-shield-alt',
                text: 'Auditoria'
            }]} />

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        path={`/audit?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {audit && <Form
                    path={'/audit/all'}
                    method={'post'}
                    header={
                        {
                            icon: "las la-shield-alt",
                            title: "Nueva Auditoria",
                        }
                    }

                    backgroundClick={() => {
                        setAudit(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'project_id',
                                title: "Proyecto",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'mode',
                                title: "Disposición",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'punctual',
                                        label: 'Puntual'
                                    },
                                    {
                                        id: 'general',
                                        label: 'General'
                                    }
                                ]
                            },
                            {
                                name: 'capture',
                                title: "Donde se ha encontrado",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'repository',
                                        label: 'Código de un respositorio'
                                    }
                                ]
                            },
                            {
                                name: 'source',
                                title: "Fuente",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'client',
                                        label: 'Cliente'
                                    },
                                    {
                                        id: 'we',
                                        label: 'Nosotros'
                                    }
                                ]
                            },
                            {
                                name: 'catchment',
                                title: "Captación de la Auditoría",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'inheritance',
                                        label: 'Auditoria de código heredado'
                                    },
                                    {
                                        id: 'documentation',
                                        label: 'Documentación'
                                    },
                                    {
                                        id: 'development',
                                        label: 'Desarrollo'
                                    }
                                ]
                            },
                            ...tabs




                        ]
                    } />}
                {form && <Form
                    path={'/audit'}
                    method={'post'}
                    header={
                        {
                            icon: "las la-universal-access",
                            title: "Nuevo Requerimiento",
                        }
                    }
                    default={{
                        position: '0'
                    }}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'project_id',
                                title: "Proyecto",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'type',
                                title: "Tipo de Auditoria",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: validations
                            },
                            {
                                name: 'position',
                                title: "Posición",
                                description: "La posición -1 estaría encima de la posición 3",
                                type: 'number',
                            },
                            {
                                name: 'section',
                                title: "Sección",
                                description: "Concepto superior opcional",
                                type: 'text',
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                description: "Información pública sobre la especificación",
                                type: 'textarea'
                            },
                            {
                                name: 'priority',
                                title: "Prioridad",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'ok',
                                        label: 'Bien!'
                                    },
                                    {
                                        id: '|',
                                        label: 'Mal'
                                    },
                                    {
                                        id: '||',
                                        label: 'Alarmante'
                                    },
                                    {
                                        id: '|||',
                                        label: 'Peligroso'
                                    }
                                ]
                            },

                            {
                                name: 'mode',
                                title: "Disposición",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'punctual',
                                        label: 'Puntual'
                                    },
                                    {
                                        id: 'general',
                                        label: 'General'
                                    }
                                ]
                            },
                            {
                                name: 'capture',
                                title: "Donde se ha encontrado",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'repository',
                                        label: 'Código de un respositorio'
                                    }
                                ]
                            },
                            {
                                name: 'source',
                                title: "Fuente",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'client',
                                        label: 'Cliente'
                                    },
                                    {
                                        id: 'we',
                                        label: 'Nosotros'
                                    }
                                ]
                            },
                            {
                                name: 'catchment',
                                title: "Captación de la Auditoría",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'inheritance',
                                        label: 'Auditoria de código heredado'
                                    },
                                    {
                                        id: 'documentation',
                                        label: 'Documentación'
                                    },
                                    {
                                        id: 'development',
                                        label: 'Desarrollo'
                                    }
                                ]
                            },
                        ]
                    } />}
                {update && <Form
                    path={'/audit?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'project_id',
                                title: "Proyecto",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {

                                name: 'position',
                                title: "Posición",
                                description: "La posición -1 estaría encima de la posición 3",
                                type: 'number',
                            },
                            {
                                name: 'section',
                                title: "Sección",
                                description: "Concepto superior opcional",
                                type: 'text',
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                description: "Información pública sobre la especificación",
                                type: 'textarea'
                            },
                            {
                                name: 'priority',
                                title: "Prioridad",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'ok',
                                        label: 'Bien!'
                                    },
                                    {
                                        id: '|',
                                        label: 'Mal'
                                    },
                                    {
                                        id: '||',
                                        label: 'Alarmante'
                                    },
                                    {
                                        id: '|||',
                                        label: 'Peligroso'
                                    }
                                ]
                            },
                            {
                                name: 'type',
                                title: "Tipo de Requisito",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: validations
                            },
                            {
                                name: 'mode',
                                title: "Disposición",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'punctual',
                                        label: 'Puntual'
                                    },
                                    {
                                        id: 'general',
                                        label: 'General'
                                    }
                                ]
                            },
                            {
                                name: 'capture',
                                title: "Momento de Captura",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'repository',
                                        label: 'Código de un respositorio'
                                    }
                                ]
                            },
                            {
                                name: 'source',
                                title: "Fuente",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'client',
                                        label: 'Cliente'
                                    },
                                    {
                                        id: 'we',
                                        label: 'Nosotros'
                                    }
                                ]
                            },
                            {
                                name: 'catchment',
                                title: "Captación del Requisito",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'meet',
                                        label: 'Reunión'
                                    },
                                    {
                                        id: 'documentation',
                                        label: 'Documentación'
                                    },
                                    {
                                        id: 'development',
                                        label: 'Desarrollo'
                                    }
                                ]
                            },
                        ]
                    } />}
                <Widget>
                    <Table reload={tableKey}
                        path={'/audit'}
                        title="Listado de Requerimientos"
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },

                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 400

                                }, {
                                    name: 'description',
                                    title: "Descripción",
                                    width: 400

                                },
                                // {
                                //     name: 'priority',
                                //     title: "Prioridad",
                                //     width: 120

                                // },

                                {
                                    name: 'type',
                                    title: "Tipo",
                                    width: 90
                                },
                                //  {
                                //     name: 'mode',
                                //     title: "Modo",
                                //     width: 90
                                // }, 
                                // {
                                //     name: 'capture',
                                //     title: "Captura",
                                //     width: 90
                                // }, {
                                //     name: 'source',
                                //     title: "Recurso",
                                //     width: 90
                                // }, {
                                //     name: 'catchment',
                                //     title: "catchment",
                                //     width: 90
                                // },
                                {
                                    name: 'creation',
                                    title: "Fecha",
                                    width: 100,
                                    type: 'since'
                                },

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Audits)


const validations = [
    {
        id: 'language',
        label: 'Diferentes idiomas en el código'
    },
    {
        id: 'comments',
        label: 'No hay comentarios en el código'
    },
    {
        id: 'documentation',
        label: 'No hay documentación el código'
    },
    {
        id: 'nomenclature',
        label: 'La nomenclatura del código no es correcta y es variable'
    },
    {
        id: 'mix',
        label: 'Mezcla de lenguajes'
    },
    {
        id: 'big',
        label: 'Archivos demasiado largos'
    },
    {
        id: 'empty',
        label: 'Archivos vacíos'
    },
    {
        id: 'tests',
        label: 'El código no tiene tests'
    },
    {
        id: 'quality',
        label: 'La calidad del código no es correcta'
    },
    {
        id: 'order',
        label: 'El código esta desordenado'
    },
    {
        id: 'style',
        label: 'El código no hace uso de estandares de estilo'
    },
    {
        id: 'readme',
        label: 'Archivos Readme.md inexistentes o poco completos'
    },

    {
        id: 'files',
        label: 'Archivos que no pintan nada en el código'
    },

    {
        id: 'break',
        label: 'Uso indiscrimado de saltos de línea'
    },

    {
        id: 'monolith',
        label: 'Comportamiento monolítico'
    },
    {
        id: 'separation',
        label: 'No separa las diferentes piezas del software, por ejemplo entre front y back'
    },
    {
        id: 'imports',
        label: 'Importaciones no requeridas, penalizando el rendimiento'
    },
    {
        id: 'deploy',
        label: 'No ofrece despliegues automáticos'
    },
    {
        id: 'w3c',
        label: 'El HTML no cumple con las normas W3C'
    },
    {
        id: 'pep',
        label: 'El Python no cumple las normas PEP'
    },
    {
        id: 'filename',
        label: 'Los nombres de los archivos son incorrectos'
    },
    {
        id: 'foldername',
        label: 'Los nombres de los carpetas son incorrectos'
    },
    {
        id: 'organization',
        label: 'La organización del código no tiene un orden visual'
    },
    {
        id: 'redundant',
        label: 'Archivos o funcionalidades redundantes'
    },
    {
        id: 'translation',
        label: 'Carece de sistema de traducciones'
    },
    {
        id: 'translation_mix',
        label: 'Diferentes idiomas en la parte visual al usuario'
    },

    {
        id: 'html_class',
        label: 'Las clases o identificadores del HTML no siguen ningún estándar'
    },
    {
        id: 'html_icons',
        label: 'Los iconos no están abstraidos del código, es decir, se definen a pelo'
    },
    {
        id: 'files_in_dev',
        label: 'Archivos abandonados o en desarrollo'
    },
    {
        id: 'mix_number_letter',
        label: 'Mezcla de variables claramente numéricas a strings'
    },
    {
        id: 'type',
        label: 'No se hace uso de tipos de datos, osea, no se tipifican las variables'
    },
    {
        id: 'error_monitoring',
        label: 'No se hace de una monitorización de errores'
    },
    {
        id: 'free_exceptions',
        label: 'No se manejan los errores'
    },
    {
        id: 'class',
        label: 'No se agrupan funcionaliades en clases'
    },
    {
        id: 'comments_bad_quality',
        label: 'Existen comentarios no profesionales o cripticos'
    },
    {
        id: 'bungling',
        label: 'Encontramos chapuzas en el código'
    },
    {
        id: 'routing_language',
        label: 'Rutas de API en varios idiomas'
    },
    {
        id: 'routing_rest',
        label: 'No se hacen uso de los métodos Rest tipo PUT, POST...'
    },
    {
        id: 'code_in_comments',
        label: 'Código comentado de desarrollo en producción'
    },
    {
        id: 'db_models_english',
        label: 'Los modelos de la base de datos no están en inglés'
    },
    {
        id: 'db_models_enum',
        label: 'Los modelos de la base de datos no tienen enumeraciones'
    },
    {
        id: 'db_models_size',
        label: 'El tamaño de los modelos de la base de datos son demasiado largos'
    },
    {
        id: 'db_models_size',
        label: 'El tamaño de los modelos de la base de datos son demasiado largos'
    },
    {
        id: 'js_constants',
        label: 'Javascript no hace  uso de constantes '
    }
]