import './index.css'
import { connect } from "react-redux";
import { useEffect, useState } from 'react';
import axios, { Method } from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import useSound from 'use-sound';
var objectPath = require("object-path");



const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
// function login props redux connect

interface DispatchProps {
    user: any
    title?: string,
    environment: string,
    project: any,
    endpoint: string,
    search: any,
    searchPlaceholder?: string,
    sound: boolean
    columns: {
        order?: string,
        operator?: string,
        onPress?: (data: any) => void
        width: number
        name: string,
        title?: string
        paddingLeft?: number
        icon?: string
    }[],
    reload: string,
    path?: string,
    method?: Method,
    items?: any[]
}
type Props = DispatchProps

const Table: React.FC<Props> = (props) => {
    const [error, setError] = useState<any>(false);

    const [loading, setLoading] = useState<any>(false);
    const [total, setTotal] = useState<any>(0);
    const [page, setPage] = useState<any>(1);
    const [searching, setSearching] = useState<string>('');
    const [order, setOrder] = useState<string>('creation');
    const [direction, setDirection] = useState<string>('desc');
    const [limit, setLimit] = useState<any>(10);
    const navigate = useNavigate();

    // data
    let { search } = useLocation();


    const [paginationClick] = useSound(
        '/sounds/ui_tap-variant-03.wav',
        { volume: 1 }
    );
    const [paginationHover] = useSound(
        '/sounds/pop-up-on.mp3',
        { volume: 0.03 }

    );

    const [openProject] = useSound(
        '/sounds/navigation_transition-left.wav',
        { volume: 0.7 }

    );
    const [items, setItems] = useState<any[]>([]);
    // get data with axios
    // reload if change
    useEffect(() => {

        if (props.reload) {

            getData();
        }
    }, [props.reload]) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async (pager: any = false, orderr = '', directionr = '') => {
        if (pager === false) pager = page
        let order_send = order
        let direction_send = direction
        if (orderr !== '') order_send = orderr
        if (directionr !== '') direction_send = directionr
        setPage(pager)
        setLoading(true);
        setItems([]);
        setTimeout(() => {


            let url = props.endpoint + props.path
            if (props.environment === 'dev') {
                url = 'http://127.0.0.1:5000' + props.path
            }
            url = url + '?mode=table&page=' + pager
            if (props.project) {
                url = url + '&project=' + props.project.id
            }
            if (searching) {
                url = url + '&search=' + searching
            }
            if (order_send) {
                url = url + '&order=' + order_send
            }
            if (direction_send) {
                url = url + '&direction=' + direction_send
            }
            // get params url
            // @ts-ignore
            if (search.indexOf('?') > -1) {
                // @ts-ignore
                let params = search.split('?')[1]
                url = url + '&' + params
            }
            axios({
                method: props.method || 'get',
                url: url,
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${props.user.access_token}`
                }
            }).then(response => {
                if (response.data) {
                    if (response.data.items && Array.isArray(response.data.items)) {
                        setItems(response.data.items);
                    }
                    if (response.data.total) {
                        setTotal(response.data.total);
                    }
                    if (response.data.page) {
                        setPage(response.data.page)
                    }
                    if (response.data.limit) {
                        setLimit(response.data.limit)
                    }

                }


                setLoading(false);
            }
            ).catch(error => {
                setError(error);
                setLoading(false);
            })
        }, 200);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
    const priceFormat = (price: any) => {

        // format string to price dots
        let price_ = (price * 1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
        return price_;
    }
    const dotnotation = (notation: any, content: any): any => {
        return objectPath.get(content, notation);  //returns "m"


    }

    const sinceDate = (date: any) => {

        let date_ = new Date(date);
        let now = new Date();
        let diff = now.getTime() - date_.getTime();
        let seconds = Math.floor(diff / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let time = '';
        if (days > 0) {
            time = days + ' days ago';
        }
        else if (hours > 0) {
            time = hours + ' hours ago';
        }
        else if (minutes > 0) {
            time = minutes + ' minutes ago';
        }
        else if (seconds > 0) {
            time = seconds + ' seconds ago';
        }
        else {
            time = 'just now';
        }
        return time;
    }

    const renderDate = (d: string) => {
        let r = new Date(d).toISOString().slice(0, 10);

        return r


    }
    const renderDateTime = (dx: any) => {
        var d: any = new Date(dx); /* midnight in China on April 13th */
        return d.toLocaleString('en-US', { timeZone: 'Europe/Madrid' });
    }
    return (< div className='Table' >
        {
            error && <div className='Table-error-general'><i className="las la-info-circle"></i>Se ha producido un error</div>

        }

        {
            <div className='Table-header'>
                <strong> {
                    props.title ? props.title : 'Listado'
                }</strong>
                <div className='Table-header-title'>
                    Página <b>{page}</b>
                </div>
                <div className='Table-header-title'>
                    Límite <b>{limit}</b>
                </div>
                <div className='Table-header-title'>
                    Total <b>{total}</b>
                </div>
                <div className='Table-header-title'>
                    Orden <b>{order}</b>
                </div>
                <div className='Table-header-title'>
                    Dirección <b>{direction}</b>
                </div>
            </div>

        }
        {
            props.search !== false &&

            <div className='Table-searcher'>
                <i className='las la-search'></i>
                <input type="text" placeholder={props.searchPlaceholder ? props.searchPlaceholder : 'Buscar...'} value={searching} onChange={(e) => {
                    setSearching(e.target.value)

                }}
                    // detect return
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            getData(1)
                        }
                    }}
                />
                <button onClick={() => { getData(); }}>
                    buscar
                </button>
            </div>
        }
        {
            loading && <div className="Loading" >

            </div>
        }
        {!loading && <ul className='Table-head' >{
            props.columns && props.columns.map((item: any, index) => {
                return (<li className={item.order ? 'ordering' : ''} style={{ minWidth: item.width, maxWidth: item.width, paddingLeft: item.title ? 16 : 0, backgroundColor: item.order && item.order === order ? '#eee' : '#fff' }} key={index}

                    onClick={() => {
                        if (item.order) {
                            let directioner = 'asc'
                            setOrder(item.order)
                            if (order === item.order) {

                                if (direction === 'asc') {
                                    setDirection('desc')
                                    directioner = 'desc'

                                }
                                else {
                                    setDirection('asc')
                                    directioner = 'asc'
                                }
                            }
                            else {
                                directioner = 'desc'
                                setDirection('desc')
                            }
                            getData(1, item.order, directioner)

                        }

                    }}
                >

                    {item.title && <b>{item.title}</b>}
                    {
                        item.order && order === item.order && direction === 'asc' && <i className="las la-angle-up" ></i>
                    }
                    {
                        item.order && order === item.order && direction === 'desc' && <i className="las la-angle-down" ></i>
                    }
                    {
                        item.order && order !== item.order && <i className="las la-angle-down" ></i>
                    }
                </li>)
            })

        }</ul>}

        {
            !loading && items && items.map((item: any, index) => {

                return (<ul key={index} >

                    {
                        props.columns && props.columns.map((column: any, index) => {
                            return (<li onClick={() => {
                                if (column.onPress) {

                                    column.onPress(item)
                                }
                                if (column.path) {
                                    openProject()
                                    navigate(`${column.path}${item.id}`);
                                }
                            }} className={column.onPress && 'Table-touch'} style={{ textAlign: column.type === 'price' ? 'right' : 'left', maxWidth: column.width, minWidth: column.width, paddingLeft: column.paddingLeft !== undefined ? column.paddingLeft : column.title ? 16 : 0 }} key={index}>

                                {
                                    column.operator === 'project' && props.project?.id === item.id && <i className={column.iconActive}></i>
                                }
                                {
                                    column.operator === 'project' && props.project?.id !== item.id && <i className={column.iconDesactive}></i>
                                }
                                {!column.operator && column.icon && <i className={column.icon}></i>}
                                {
                                    // get name dot notation
                                    column.type === 'price' && column.name && item && priceFormat(dotnotation(column.name, item))

                                }
                                {
                                    // get name dot notation
                                    column.type === 'since' && column.name && item && sinceDate(dotnotation(column.name, item))

                                }
                                {
                                    // get name dot notation
                                    column.type === 'rating' && column.name && item && <div className='Table-rating'>
                                        {
                                            // render stars 0 to 5
                                            Array(dotnotation(column.name, item)).fill(0).map((item, index) => {
                                                return (<i key={index} className="las la-star"></i>)
                                            })
                                        }
                                        {
                                            // render stars 0 to 5
                                            Array(5 - dotnotation(column.name, item)).fill(0).map((item, index) => {
                                                return (<i key={index} className="las la-star Table-rating-desactive"></i>)
                                            })
                                        }
                                    </div>

                                }
                                {
                                    // get name dot notation
                                    column.type === 'date' && column.name && item && renderDate(dotnotation(column.name, item))

                                }
                                {
                                    // get name dot notation
                                    column.type === 'datetime' && column.name && item && renderDateTime(dotnotation(column.name, item))

                                }
                                {
                                    // get name dot notation
                                    column.type === 'dot' && column.name && item && dotnotation(column.name, item)

                                }
                                {
                                    // get name dot notation
                                    column.type === 'dot-date' && column.name && item && renderDate(dotnotation(column.name, item))

                                }
                                {
                                    // get name dot notation
                                    column.type === 'dot-datetime' && column.name && item && renderDateTime(dotnotation(column.name, item))

                                }
                                {
                                    // get name dot notation
                                    column.type === 'dot-rating' && column.name && item && <>
                                        {
                                            // render stars 0 to 5
                                            Array(dotnotation(column.name, item)).fill(0).map((item, index) => {
                                                return (<i key={index} className="las la-star"></i>)
                                            })

                                        }
                                    </>

                                }

                                {
                                    // get name dot notation
                                    !column.type && !column.render && column.name && item && <div className='ellipsis'>{dotnotation(column.name, item)}</div>

                                }
                                {
                                    // get name dot notation
                                    column.render && column.render(item)

                                }
                                {
                                    column.path && <i className='link-icon las la-angle-right'></i>
                                }
                                {
                                    column.type === 'price' && ' €'
                                }
                            </li>)
                        })

                    }     </ul>)
            })
        }
        {
            !loading && total > 0 && <div className='Paginator'>
                {
                    // minus is posible
                    page > 1 && <div className='Paginator-button' onClick={() => {
                        getData(page - 1)
                    }}>
                        <i className='las la-angle-left'></i>
                    </div>
                }
                {
                    Array.from(Array(Math.ceil(total / limit)).keys()).map((item: any, index: any) => {
                        return (<div className={page === item + 1 ? 'Paginator-button Paginator-button-active' : 'Paginator-button'} onClick={() => {
                            getData(item + 1);
                            paginationClick();
                        }} key={index}
                            onMouseEnter={() => {
                                paginationHover();
                            }}
                        >
                            {item + 1}
                        </div>)
                    })
                }

                {
                    // more is posible
                    page < Math.ceil(total / limit) && <div className='Paginator-button' onClick={() => {
                        getData(page + 1)
                    }}>
                        <i className='las la-angle-right'></i>
                    </div>
                }
            </div>
        }



    </div >
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Table)
