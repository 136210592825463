
import ReactMarkdown from 'react-markdown'
import Replacer from '../../../functions/replacer'


type Props = {
    component: any,
    document: any
}

export default function P(props: Props) {



    return <div className='P'>
        <ReactMarkdown>{Replacer(props.component.content, props.document, '')}</ReactMarkdown>

    </div>
}
