import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

type Props = {
    component: any,
    type?: 'lines' | 'bars'
}

export default function Chart(props: Props) {
    const type = props.type || 'bars';
    const labels = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'J', 'F', 'M'];

    let options: any = {
        responsive: true,
        layout: {
            autoPadding: false,
            padding: 0
        },

        scales: {
            y: {
                display: false,
                ticks: {
                    display: false,
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
            title: {
                display: false,
            },
        },
    };
    if (props.type === 'lines') {
        options = {}
    }
    let data: any = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: labels.map(() => Math.floor(Math.random() * 100 + 10)),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Dataset 2',
                data: labels.map(() => Math.floor(Math.random() * 100 + 10)),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    if (type === 'lines') {
        data = {
            labels,
            datasets: [
                {
                    label: 'Dataset 1',
                    data: labels.map(() => Math.floor(Math.random() * 100 + 10)),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ],
        };
    }
    return <div style={{ padding: 20, paddingBottom: 0, backgroundColor: '#333' }}>
        {
            type === 'lines' &&
            <Line options={options} data={data} />
        }
        {
            (type === 'bars' || !type) &&
            <Bar options={options} data={data} />
        }
    </div>
}
