


type Props = {
    document: any
    pages: any
}

export default function Splash(props: Props) {
    let pagesUsedTitles: any = [];
    let pagesUsed: any = [];
    props.pages.map((page: any, index: any) => {
        if (!pagesUsedTitles.includes(page.title)) {
            pagesUsedTitles.push(page.title)
            pagesUsed.push({ title: page.title, page: index })
        }
        return page
    })

    return <div className='Index'>
        <ul>
            {
                pagesUsed?.map((item: any, index: number) => {
                    if (!item.title) {
                        return null
                    }
                    return <li key={index}>
                        <b>{item.title}</b>
                        <i>{item.page}</i>
                    </li>
                })

            }
        </ul>
    </div>
}
