

type Props = {
    document: any,
    component: any,
    requerimentStart: number,
    requerimentEnd: number,
}


export default function Audits(props: Props) {
    console.log('audit:::', props)
    let requerimentsSelected: any = []
    if (props?.document?.project?.audits) {
        requerimentsSelected = props?.document?.project?.audits.sort((a: any, b: any) => {
            if (a.position < b.position) return -1;
            if (a.position > b.position) return 1;
            return 0;
        })
        console.log('Sele:', requerimentsSelected)

    }
    else {
        console.log('Sin auditorias')
        return null
    }

    let renderRequerimentCounter = 0
    return <div className='Requeriments'>
        <style>
            {`
            .Requeriments ul {
                margin-left: -80px;
                margin-right: -80px;
                overflow:hidden;
            }
            
            .Requeriments ul li {
                font-size: 0; height:30px;
                display:flex;border-bottom:1px solid #eee
            }
            
            .Requeriments .definition {text-overflow:ellipsis; overflow:hidden;padding-left:10px;box-sizing:border-box; max-width:400px;min-width:400px}
            .Requeriments .priority {padding-left:10px; box-sizing:border-box;min-width:90px;max-width:90px;box-sizing:border-box}
            .Requeriments .priority-mild{background-color:#7361a5}
            .Requeriments .priority-serious{background-color:#a5619e}
            .Requeriments .priority-dangerous{background-color:#a56179}
            .Requeriments .priority-mild,.Requeriments .priority-dangerous, .Requeriments .priority-serious
{border-radius:3px;margin:5px;height:20px;line-height:21px;font-weight:300;
    min-width:80px;max-width:80px;
    letter-spacing:1px;font-size:9px; color:#fff; text-transform:uppercase; text-align:center; padding-left:0px;}

            .Requeriments .mode {padding-left:10px;box-sizing:border-box;min-width:70px;max-width:70px}
            .Requeriments .source {padding-left:10px;box-sizing:border-box;border-right:none;min-width:70px;max-width:100px}
            .Requeriments ul .id {
                border-radius:0px 4px 4px 0px;
                background: #222;
                border-right:none;
                width: 80px;
                padding-right:10px;
                box-sizing:border-box;
                color: #ffffff8b;
                font-size: 10px;
                text-align:right;
                text-transform:uppercase;
                font-weight: 100;
            }
            .Requeriments ul .id span{margin:0 0px 0 2px; color:#6498ff}
            .Requeriments ul .id b{margin-left:0px; color:#fff; }

            .Requeriments ul li div p{margin-left:10px;overflow:hidden; color:#888; display:inline}
            .Requeriments ul li div {
                font-size:12px;
                display: inline-block;
                line-height:30px; height:30px;
                border-right: 1px solid #ddd;
            }
            .Requeriments .sectioner{ background:#eee; font-size:15px;padding:10px 15px;margin-top:10px;margin-bottom:10px; line-height:1;height:auto}
            .Requeriments .sectioner b{ margin-left:10px}
            .Requeriments .head{font-size:4px; letter-spacing:1px;text-transform:uppercase; font-weight:100}
            .Requeriments .head div{background:none; color:#666;font-size:9px; padding-left:0; text-align:center}
           `}
        </style>
        <ul>

            <li className="head">
                <div className="id">
                    ID
                </div>
                <div className="definition">
                    DEFINITION
                </div>
                <div className="priority">
                    GRAVITY
                </div>
                <div className="mode">
                    MODE
                </div>
                <div className="source  ">
                    TYPE
                </div>
            </li>
            {


                requerimentsSelected && requerimentsSelected.map((file: any, index: number) => {

                    renderRequerimentCounter++;
                    console.log('Render:', renderRequerimentCounter)

                    if (renderRequerimentCounter !== 0 && props.requerimentStart > renderRequerimentCounter) return null
                    console.log('Render:', renderRequerimentCounter)

                    if (props.requerimentEnd <= renderRequerimentCounter - 1) return null

                    let ShowSectionInLine = false
                    // if (showSection) {
                    //     showSection = false;
                    //     ShowSectionInLine = true;
                    // }
                    return <>
                        {ShowSectionInLine && <li className="sectioner">{props.component.detail} <b>{index}</b></li>}
                        <li key={index} style={{ background: '#aaaaaa35' }}>
                            <div className='id'>
                                #<span>{file.type.substring(0, 3)}</span><b>{index + 1}</b>
                            </div>
                            <div className="definition">
                                <b>{file.name ? file.name : 'Aspecto Global del Repositorio'}</b>

                            </div>
                            <div className={`priority priority-${file.priority === '|||' ? 'dangerous' : file.priority === '||' ? 'serious' : 'mild'}`}>
                                {file.priority && file.priority === '|' && 'mild'}
                                {file.priority && file.priority === '||' && 'serious'}
                                {file.priority && file.priority === '|||' && 'danger'}
                            </div>
                            <div className="mode">{file.mode}</div>
                            <div className="source">{file.type}</div>

                        </li>
                        <div>
                            <div style={{ backgroundColor: '#fff', padding: 20, paddingTop: 10, paddingLeft: 90 }}>
                                <div style={{ fontSize: 15, marginBottom: 4, fontWeight: '400' }}>{messages[file.type]?.title}</div>
                                <div style={{ maxWidth: '50%', lineHeight: 1.4, fontSize: 12, color: '#555', fontWeight: 100 }}>{messages[file.type]?.description}</div>
                                <div style={{ display: 'inline-block', fontSize: 10, borderRadius: 3, color: '#5e5e5e', marginTop: 10, background: '#ffd4d47f', position: 'relative', overflow: 'hidden', padding: 10, maxWidth: '50%', paddingLeft: 35, paddingTop: 7, paddingBottom: 7 }}><i style={{ borderRadius: 3, position: 'absolute', left: 0, top: 0, color: '#682a2aac', bottom: 0, fontSize: 12, width: 26, lineHeight: '25px', textAlign: 'center', backgroundColor: '#fd919167' }} className="la la-info-circle"></i> {file.description}{file.description === '' && messages[file.type]?.[file.priority]}</div>
                            </div>
                        </div>
                    </>
                })



            }
        </ul>
    </div >
}

let messages: any = {
    language: {
        title: 'Idiomas del Código Fuente',
        description: 'El código de un repositorio debe hacer uso de un único idioma en todo su código fuente.',
        '|': 'Se han encontrado diferentes idiomas en el código fuente del proyecto.',
    },
    comments: {
        title: 'Comentarios Embebidos',
        description: 'Resulta fundamental que un código contenga comentarios explicativos.',
        '|': 'No se han encontrado comentarios o los encontrados son de baja calidad.',
        '||': 'La mayor parte del cógido carece de comentarios.',
    },
    documentation: {
        title: 'Documentación Embebida',
        description: 'Un código sin documentación implica, en una gran parte de los softwares, que no se puede entender el código fuente.',
        '|': 'No se ha encontrado una documentación profesional.',
        '||': 'Carece de una documentación profesional.',
    },
    nomenclature: {
        title: 'Nomenclatura',
        description: 'Un código de calidad hace uso de reglas al definir los nombres de las diferentes partes del software.',
        '|': 'No se respeta ninguna regla de nomenclatura.',
    },
    mix: {
        title: 'Mezcla de Lenguajes de Programación',
        description: 'La combinación de lenguajes de programación debe realizarse de manera adecuada para que el software sea entendible.',
        '|': 'Se ha localizado mezcla de diferentes lenguajes.',
        '||': 'Hemos encontrado una gran cantidad de mezcla de diferentes lenguajes.',
    },
    big: {
        title: 'Tamaño de los archivos',
        description: 'Resulta importante que un código tienda a contener archivos que no superen las 300 líneas de código.',
        '|': 'Archivos demasiado largos y difíciles de leer.',
        '||': 'Archivos demasiado largos imposibilitando su comprensión.',
        '|||': 'Archivos absurdamente largos imposibilitando su comprensión e iteración.',
    },
    empty: {
        title: 'Archivos Vacíos',
        description: 'Un código vacío implica que no se ha escrito nada en él, por lo tanto, carece de sentido encontrarlo en un código fuente.',
        '|': 'Se han encontrado algunos archivos vacíos.',
        '||': 'Hemos localizado muchos archivos vacíos.',
        '|||': 'Hemos encontrado multitud de archivos vacíos.',
    },
    tests: {
        title: 'Test Unitarios',
        description: 'La calidad del un código se puede validar por su número de test unitarios, permitiendo su ejecución y validar su comportamiento.',
        '|': 'Exiten test unitarios, pero no los suficientes para validar la calidad del código.',
        '||': 'Existe algún test, pero no tienen un alcance de aceptación válido o son defectuosos.',
        '|||': 'No existen test unitarios',
    },
    quality: {
        title: 'Calidad del Código',
        description: 'La calidad del código es un factor determinante para la eficiencia y la eficacia del software.',
        '|': 'El código cuenta con partes de baja calidad.',
        '||': 'El código cuenta con piezas de baja calidad.',
        '|||': 'Existen partes del código de muy baja calidad.',
    },
    order: {
        title: 'Orden del Código',
        description: 'El orden del código permite su iteración de forma eficada y eficiente. Permitiendo localizar cada funcionalidad sin requerir una búsqueda exhaustiva.',
        '|': 'El código cuenta con cierto orden, pero no el suficiente.',
        '||': 'No existe ningún orden.',
        '|||': 'El código está totalmente desordenado.',
    },
    style: {
        title: 'Guía de Estilos',
        description: 'La elegancia del código es definida por la elección de una guía de estilos consecuente con el lenguaje y aplicada en todo el conjunto del software.',
        '|': 'Se hace uso de diferentes estilos.',
        '||': 'No se hace uso de ningún estilo.',
        '|||': 'El estilo del código es inexistente y no es bello.',
    },
    readme: {
        title: 'Archivos Readme',
        description: 'Los archivos Readme.md permiten a los desarrolladores identificar la funcionalidad de cada pieza del software mediante archivos literarios y permitiendo al equipo de desarrollo entender todo el repositorio.',
        '|': 'Existen algunas definiciones, aunque no las suficientes.',
        '||': 'La calidad de los archivos Readme.md es mala.',
        '|||': 'No existen archivos Readme.md o son de muy baja calidad.',
    },
    files: {
        title: 'Archivos Estáticos',
        description: 'Los archivos no funcionales de un software deben ser tratados siguiendo una estrategia de mantenimiento y deben ser eliminados de forma permanente del código.',
        '|': 'Hemos localizado archivos estáticos dispersos por el repositorio.',
        '||': 'Existen multitud de archivos estáticos por el repositorio.',
        '|||': 'No existe ningún tipo de control sobre los archivos estáticos.',
    },
    break: {
        title: 'Saltos de Línea',
        description: 'Resulta imprescindible valorar al salto de línea con el fin de que el software sea legible y entendible. Un salto de línea permite separar diferentes partes del código y, por lo tanto, su valor es importante.',
        '|': 'El código carece o incorpora de forma incorrecta saltos de línea.',
        '||': 'El código hace un uso incorrecto de los saltos de línea de forma endémica.',
        '|||': 'El código hace uso de saltos de línea de forma muy incorrecta en todo el repositorio.',
    },
    monolith: {
        title: 'Tendencia al Monolíto',
        description: 'La separación del código en módulos permite que el software sea más modular y que sea más fácil de mantener. A su vez separar la lógica del negocio de la lógica de presentación y de la lógica de datos resulta fundamental.',
        '|': 'Tendencia leve al monolíto.',
        '||': 'Tendencia moderada al monolíto.',
        '|||': 'Tendencia fuerte al monolíto.',
    },
    separation: {
        title: 'Separación Funcional',
        description: 'Las diferentes partes del software deben estar separadas por su funcionalidad y así permitir su iteración y su mantenimiento.',
        '|': 'Mezcla de piezas leve.',
        '||': 'Mezcla moderada de piezas',
        '|||': 'Mezcla fuerte de piezas.',
    },
    imports: {
        title: 'Importaciones Gratuitas',
        description: 'Un codigo de calidad debe priorizar su performance y no eludir el costo de las importaciones de código no utilizado.',
        '|': 'El código contiene importaciones gratuitas.',
        '||': 'El código contiene importaciones sin ningún control.',
        '|||': 'El código tiene un nulo control sobre las importaciones y su repercusión en la velocidad.',
    },
    deploy: {
        title: 'Despliegue Automático',
        description: 'La implementación debe estar sujeta a despliegues automáticos para permitir su validación, mantenimiento y actualización.',
        '|': 'El código no tiene un despliegue automático.',
        '||': 'El código tiene un despliegue automático, pero no es eficiente.',
        '|||': 'El código carece de control sobre el despliegue automático.',
    },
    w3c: {
        title: 'Estándar W3C',
        description: 'Los estándares Web W3C son protocolos, pautas y tecnologías inter-operativas e internacionales creadas con la finalidad de guiar la Web a un mejor rendimiento y mejorar la funcionalidad operativa para los navegadores y usuarios.        ',
        '|': 'El código no supera la validación W3C',
        '||': 'El código no supera la validación W3C y contiene numerosos errores graves.',
        '|||': 'El código no supera la validación W3C y contiene numerosos errores graves y endémicos.',
    },
    pep: {
        title: 'Guía de Estilo para Python',
        description: 'La comunidad de Python hace uso de documentos que brindan convenciones de codificación para la biblioteca de distribución principal de Python. Principalmente PEP8 y PEP257.',
        '|': 'Cumple parcialmente con la guía de estilo de Python.',
        '||': 'No cumple la guía de estilo de Python.',
        '|||': 'No cumple la guía de estilo de Python y contiene numerosos errores graves de diseño.',
    },
    filename: {
        title: 'Nombres de Archivos',
        description: 'El nombre de los archivos debe ser coherente con el código y debe ser entendible para permitir la identificación de su funcionalidad.',
        '|': 'El nombre de los archivos no es coherente.',
        '||': 'El nombre de los archivos carece de coherencia y diferentes tipos de nomenclaturas.',
        '|||': 'No existe ningún tipo de coherencia y sus nomenclaturas contienen errores.',
    },
    foldername: {
        title: 'Nombres de Carpetas',
        description: 'El nombre de las carpetas debe ser coherente con el código y debe ser entendible para permitir la identificación de su contenido.',
        '|': 'El nombre de las carpetas no es coherente.',
        '||': 'El nombre de las carpetas carece de coherencia y diferentes tipos de nomenclaturas.',
        '|||': 'No existe ningún tipo de coherencia y sus nomenclaturas contienen errores.',
    },
    organization: {
        title: 'Organización del Código',
        description: 'La correcta organización del árbol de archivos que componen un software permite particionar conceptualmente su código y, por lo tanto, crear un mapa conceptual de su aplicación.',
        '|': 'La organización del código es defectuosa.',
        '||': 'La organización del código es inexistente.',
        '|||': 'La organización del código es inexistente o defectuosa.',

    },
    redundant: {
        title: 'Código Redundante',
        description: 'Término de programación que indica el código fuente que calcula un resultado previamente calculado y ya disponible, por lo que el resultado de la computación repetida es redundante. Si dicho código puede ser identificado, entonces puede ser eliminado, reduciendo así el costo de cálculo del software.',
        '|': 'Localizado levemente',
        '||': 'Localizado por múltiples archivos.',
        '|||': 'Localizado de forma endémica en todo el repositorio.',
    },
    translation: {
        title: 'Sistema de Traducciones',
        description: 'Un software requiere en la mayor parte de los casos de un sistema de traducciones que permita ofrecer el software en diferentes idiomas. Generalmente carecer de un sistema de traducciones degenera el código con la incorporación de condicionales y constantes embebidas sin control.',
        '|': 'El código tiene un sistema condicional del traducciones',
        '||': 'El código no tiene un sistema de traducciones.',
        '|||': 'El código mezcla sin control sus versiones idiomáticas.',
    },
    html_class: {
        title: 'Clases o Identificadores de HTML',
        description: 'Las clases definidas en el código HTML debe seguir una nomenclatura clara y consecuente con su funcionalidad.',
        '|': 'Las clases no tienen ninguna organización.',
        '||': 'Las clases tienen errores de nomenclatura',
        '|||': 'Existen diversos errores de nomenclatura en las clases y los identificadores.',
    },
    html_icons: {
        title: 'Librería de Iconos',
        description: 'La implementación de librerías gráficas de iconos debe hacerse abstrayendo la librería seleccionada y permitiendo su sustitución por otra librería sin necesidad de iterar todo el código del repositorio.',
        '|': 'Iconos embebidos mediante clase única.',
        '||': 'Iconos embebidos directamente.',
        '|||': 'No existe ningún control de los iconos.',
    },
    files_in_dev: {
        title: 'Archivos de Desarrollo',
        description: 'El código fuente almacenado en un repositorio no debe contener archivos en desarrollo, de dudosa calidad o simplemente archivos olvidados.',
        '|': 'Archivos localizados.',
        '||': 'Múltiples archivos localizados.',
        '|||': 'Gran cantidad de archivos de desarrollo localizados en el repositorio.',
    },
    mix_number_letter: {
        title: 'Tipificación Numérica',
        description: 'Un código fuente debe tener identificados los valores numéricos como números y evitar el uso de valores numéricos como strings.',
        '|': 'Valores numéricos localizados como strings.',
        '||': 'Valores numéricos localizados como strings y números.',
        '|||': 'Valores numéricos localizados como strings sin control.',
    },
    type: {
        title: 'Tipificación de Variables',
        description: 'Las variables deben ser tipificadas para mejorar la integridad del software e invertir el tiempo necesario en la tipificación de cada objeto del mismo.',
        '|': 'Tipificación leve encontrada.',
        '||': 'No se ha encontrado ningún tipo de tipificación.',
        '|||': 'La tipificación es incorrecta o simplemente inexistente.',
    },
    error_monitoring: {
        title: 'Monitorización de Errores',
        description: 'Un software debe estar monitoreado mediante herramientas programáticas para identificar sus posibles bugs.',
        '|': 'No existe monitorización.',
        '||': 'No existe monitorización.',
        '|||': 'No existe monitorización.',
    },
    free_exceptions: {
        title: 'Manejo de Errores',
        description: 'Las excepciones de un software deben ser controladas por manejadores asociados a las diferentes funcionalidades del software, permitiendo su identificación y reduciendo el alcance del error sobre el resto de partes del software.',
        '|': 'Existen manejos de errores, pero no suficientes',
        '||': 'Existen manejos de errores en ciertas funciones, pero no en todas.',
        '|||': 'No existe manejadores de errores o su covertura es mínima.',
    },
    comments_bad_quality: {
        title: 'Calidad de los Comentarios',
        description: 'Los comentarios de un software deben ser claros y concisos, cualquier otra forma de comentar debe ser reemplazada o eliminada.',
        '|': 'Comentarios poco claros.',
        '||': 'Comentarios de mala calidad',
        '|||': 'Comentarios de muy mala calidad',
    },
    db_models_size: {
        title: 'Tamaño de los Modelos',
        description: 'Del mismo modo que el tamaño de los archivos, la dimensión de los modelos debe tender a ser reducida, permitiendo su rápida comprensión y mejorando el performance de todo el software respecto a la velocidad de procesamiento en la base de datos.',
        '|': 'Modelos desproporcionados.',
        '||': 'Modelos con tamaños excesivos.',
        '|||': 'Modelos con tamaños excesivos y funcionalidades crípticas.',
    },
    db_models_english: {
        title: 'Modelos en Inglés',
        description: 'La construcción de los modelos debe ser en inglés, considerando que su construcción desencadenará la definición de la base de datos.',
        '|': 'Los modelos no se encuentran en inglés.',
        '||': 'Los modelos no se encuentran en inglés.',
        '|||': 'Los modelos no se encuentran en inglés.',
    },
    code_in_comments: {
        title: 'Comentarios en Versión de Usuario ',
        description: 'El código renderizado por el usuario no puede contener comentarios de desarrollo, tanto por el performance en la carga de los mismos, como por no ser profesional ofrecer dicha información al usuario final.',
        '|': 'Comentarios localizados en la aplicación de usuario.',
        '||': 'Comentarios localizados en la aplicación de usuario.',
        '|||': 'Comentarios localizados en la aplicación de usuario.',
    },
    routing_rest: {
        title: 'Métodología REST',
        description: 'Una API de tipo REST debe hacer uso de los estándares definidos en sus diferentes versiones, haciendo uso de forma recurrente de sus métodos y recursos.',
        '|': 'Cumple levemente el estándar REST.',
        '||': 'No cumple el estándar REST ',
        '|||': 'No hace uso de los recusos REST',
    }
    ,
    bungling: {
        title: 'Baja Calidad',
        description: 'Un código de baja calidad debe ser reemplazado por uno de alta calidad.',
        '|': 'Código localizado de mala calidad.',
        '||': 'Código localizado de muy mala calidad.',
        '|||': 'Código localizado de muy mala calidad de forma general.',
    },
    routing_language: {
        title: 'Nomenclatura de Rutas Rest',
        description: 'Resulta natural encontrar APIs con servicios en el idioma de su mercado comercial. Lo que no es asumible es encontrar metodologías en varios idiomas, en ese caso, se debería optar por traducirla al inglés.',
        '|': 'Rutas en múltiples idiomas.',
        '||': 'Rutas en múltiples idiomas.',
        '|||': 'Rutas en múltiples idiomas.',
    },
    js_constants: {
        title: 'Constantes en Javascript',
        description: 'Un código de Javascript debe distinguir el tratamiento entre constantes y variables de forma clara.',
        '|': 'No usa constantes o las usa incorrectamente.',
        '||': 'Errores sobre constantes.',
        '|||': 'El software no respeta ninguna convención sobre el uso de constantes.',
    },
    'class': {
        title: 'Uso de Clases',
        description: 'El uso de Clases permite encapsular, abstraer y heredar funcionalidades del software.',
        '|': 'Utiliza clases, pero de forma residual.',
        '||': 'Utiliza clases, pero de forma residual e incorrectamente.',
        '|||': 'No hace uso de clases o lo hace de forma residual e incorrectamente.',
    },
    db_models_enum: {
        title: 'Modelos de Enumeraciones',
        description: 'Los modelos de enumeraciones deben ser definidos en forma de objetos, permitiendo su reutilización y la facilidad de su extracción.',
        '|': 'Modelos de enumeraciones no definidos.',
        '||': 'Modelos de enumeraciones no definidos.',
        '|||': 'Modelos de enumeraciones no definidos.',
    },
    translation_mix: {
        title: 'Traducciones en Interface de Usuario',
        description: 'Puede ser natural y comprensible encontrar backends en idiomas diferentes al inglés, lo que no debería ser posible es encontrar interfaces de usuario con textos incrustados en el código del interface de usuario.',
        '|': 'Múltiples textos entre el código y la interfaz de usuario.',
        '||': 'Uso excesivo de textos entre el código y la interfaz de usuario.',
        '|||': 'Uso indiscrimado de textos entre el código y la interfaz de usuario.',
    }
}