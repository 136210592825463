


type Props = {
    component: any,
    document: any
}

export default function P(props: Props) {



    return <div className='Responsible'>

        <ul>
            <li>
                <em style={{ backgroundImage: `url(${props.document?.project?.technical?.selfie})` }}></em>
                <b>{props.document?.project?.technical?.name} {props.document?.project?.technical?.surname}</b>
                <span>Responsable Técnico</span>
                <i>{props.document?.project?.brand?.name} </i>
            </li>
            <li>
                <em style={{ backgroundImage: `url(${props.document?.project?.manager?.selfie})` }}></em>
                <b>{props.document?.project?.manager?.name} {props.document?.project?.manager?.surname}</b>
                <span>Proyecto Manager</span>
                <i>{props.document?.project?.brand?.name} </i>
            </li>
            <li>
                <em style={{ backgroundImage: `url(${props.document?.project?.organization?.manager?.selfie})` }}></em>
                <b>{props.document?.project?.organization?.manager?.name} {props.document?.project?.organization?.manager?.surname}</b>
                <span>Director Comercial</span>
                <i>{props.document?.project?.brand?.name} </i>
            </li>
            <li>
                <em style={{ backgroundImage: `url(${props.document?.project?.organization?.commercial?.selfie})` }}></em>
                <b>{props.document?.project?.organization?.commercial?.name} {props.document?.project?.organization?.commercial?.surname}</b>
                <span>Responsable Comercial</span>
                <i>{props.document?.project?.brand?.name} </i>
            </li>
            <li>
                <em style={{ backgroundImage: `url(${props.document?.project?.organization?.accounter?.selfie})` }}></em>
                <b>{props.document?.project?.organization?.accounter?.name} {props.document?.project?.organization?.accounter?.surname}</b>
                <span>Accounting Manager</span>
                <i>{props.document?.project?.brand?.name} </i>
            </li>
        </ul>
    </div>
}
