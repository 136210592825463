import './select.css'
import { connect } from "react-redux";
import { useEffect, useState } from 'react';
import axios, { Method } from 'axios';

var objectPath = require("object-path");


const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
// function login props redux connect

interface DispatchProps {
    user: any,
    placeholder:string,
    project: any,
    endpoint: string,
    environment: string,
    onSelect: (item: any) => void
    onOpen?: () => void
    columns: {
        onPress?: (data: any) => void
        width: number
        name: string,
        title?: string
        icon?: string
    }[],
    multiple?: boolean,
    options?: any,
    reload: string,
    value?: any,
    path?: string,
    method?: Method,
    itemRetrieve?: any,
    items?: any[]
}
type Props = DispatchProps

const Select: React.FC<Props> = (props) => {
    const [error, setError] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);
    const [total, setTotal] = useState<any>(0);
    const [page, setPage] = useState<any>(1);
    const [selected, setSelected] = useState<any>(null);
    const [limit, setLimit] = useState<any>(10);
    const [toggle, setToggle] = useState<boolean>(false);

    // data
    const [items, setItems] = useState<any[]>([]);
    // get data with axios
    // reload if change
    useEffect(() => {

        if (props.reload) {
            // props.path && getData();
        }
    }, [props.reload]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {

        if (props.options) {
            setItems(props.options);
        }
        if (props.value && props.options) {
            // search in items:
            let item = props.options.find((item: any) => item.id === props.value)
            setSelected(item)
        }
        else if (props.itemRetrieve) {
            console.log(props.itemRetrieve)
            setSelected(props.itemRetrieve)
        }
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps
    const getData = async (pager: any = false, search: string = '') => {
        if (props.path === '/project') {
            // miramos si trae en redux el project
            if (props.project) {
                setSelected(props.project)
                props.onSelect(props.project)
            }
        }
        console.log('si')
        if (pager === false) pager = page
        setPage(pager)
        setLoading(true);
        let url = props.endpoint + props.path
        if (props.environment === "dev") {
            url = 'http://127.0.0.1:5000' + props.path
        }
        if (url.indexOf('?') > -1) {


        }
        else {
            url = url + '?';
        }
        url = url + 'page=' + pager + '&mode=basic'
        if (search) {
            url = url + '&search=' + search
        }
        console.log('2', url)

        axios({
            method: props.method || 'get',
            url: url,
            data: {},
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${props.user.access_token}`
            }
        }).then(response => {
            if (response.data) {
                if (response.data.items && Array.isArray(response.data.items)) {
                    setItems(response.data.items);
                }
                if (response.data.total) {
                    setTotal(response.data.total);
                }
                if (response.data.page) {
                    setPage(response.data.page)
                }
                if (response.data.limit) {
                    setLimit(response.data.limit)
                }

            }


            setLoading(false);
        }
        ).catch(error => {
            setError(error);
            setLoading(false);
        })
    }
    useEffect(() => {
        props.path && getData(1);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const dotnotation = (notation: any, content: any): any => {
        return objectPath.get(content, notation);  //returns "m"


    }
    return (< div className='Select' >
        {
            selected && <div className='selected'>
                {
                    <div className='selections' onClick={() => {
                        if (!toggle && props.path) {
                            setItems([])
                            getData(1)
                        }
                        props.onOpen?.();
                        setToggle(!toggle)
                    }}>
                        {
                            props.multiple && props.columns &&
                            selected.map((x: any, inx: any) => {
                                return <div className='multiple-item' key={inx} onClick={() => {
                                    // remove
                                    let newSelected = selected.filter((y: any) => {
                                        return y.id !== x.id
                                    })
                                    setSelected(newSelected)


                                }}>
                                    {props.columns.map((column: any, index) => {

                                        return (<span key={index} >
                                            {column.render ? column.render(x) : dotnotation(column.name,x)}</span>)
                                    })}
                                </div>
                            })

                        }
                        <div className='single-item'>
                            {
                                !props.multiple && props.columns && props.columns.map((column: any, index) => {

                                    return (<span key={index} >
                                        {column.render ? column.render(selected) : dotnotation(column.name,selected)}</span>)

                                })
                            }
                        </div>
                        {total > 0 && <b>{total ? total : 0} opciones</b>}
                        <i className='las la-angle-down'></i>
                    </div>
                }
            </div>
        }
        {
            !selected && <div className='unselected' onClick={() => {
                if (!toggle && props.path) {
                    setItems([])
                    getData(1)
                }
                props.onOpen?.();
                setToggle(!toggle)
            }}>
                {
                props.placeholder || 'Seleccionar'}
                {total > 0 && <b>{total ? total : 0} opciones</b>}


                <i className='las la-angle-down'></i>
            </div>
        }

        {
            error && <div className="Error" >
                Se ha producido un error
            </div>
        }
        {toggle && <div className='options'>
            {
                loading && <div className="Loading" >

                </div>
            }
            {
                props.path && <div className='search'>
                    <input type='text' autoFocus placeholder='Buscar' onKeyDown={(e: any) => {
                        console.log(e.key)
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation()
                            let value = e.target.value
                            if (value.length > 2) {
                                getData(1, value)
                            }
                        }
                    }} />
                </div>
            }
            {
                toggle && items && items.map((item: any, index) => {

                    return (<ul key={index} onClick={() => {

                        if (props.multiple) {
                            let selecteds = [];
                            if (selected) selecteds = selected
                            selecteds.push(item)
                            props.onSelect(selecteds)
                            setSelected(selecteds)
                        }
                        else {
                            setSelected(item)
                            props.onSelect(item)
                        }

                    }} >
                        {
                            props.columns && props.columns.map((column: any, index) => {
                                return (<li onClick={() => {


                                    setToggle(!toggle)
                                }} style={{ width: column.width }} key={index}>
                                    {column.icon && <i className={column.icon}></i>}
                                    {column.render ? column.render(item) : dotnotation(column.name,item)}</li>)
                            })

                        }     </ul>)
                })
            }
            {
                toggle && Math.ceil(total / limit) > 1 && <div className='Paginator'>
                    {
                        // minus is posible
                        page > 1 && <div className='Paginator-button' onClick={() => {
                            getData(page - 1)
                        }}>
                            <i className='las la-angle-left'></i>
                        </div>
                    }
                    {
                        Array.from(Array(Math.ceil(total / limit)).keys()).map((item: any, index: any) => {
                            return (<div className={page === item + 1 ? 'Paginator-button Paginator-button-active' : 'Paginator-button'} onClick={() => {
                                getData(item + 1);
                            }} key={index}>
                                {item + 1}
                            </div>)
                        })
                    }

                    {
                        // more is posible
                        page < Math.ceil(total / limit) && <div className='Paginator-button' onClick={() => {
                            getData(page + 1)
                        }}>
                            <i className='las la-angle-right'></i>
                        </div>
                    }
                </div>
            }
        </div>}



    </div >
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Select)
