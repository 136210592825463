import moment from 'moment'
import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import ClockComponent from '../Clock'
import 'moment/locale/es';
moment.locale('es'); // aca ya esta en es

interface ItemProps {
    item: any
    index: number
}



const Item: React.FC<ItemProps> = ({ item, index }) => {
    return (
        <Draggable draggableId={item.id} index={index}>
            {provided => (
                <div
                    className='Drager-column-item'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className='Drager-column-item-header'><b>{item.project?.organization?.name}</b> <span>{item.project.name}</span>

                    </div>
                    {/* <ClockComponent timeTillDate={new Date(item.deliveredAt).toISOString()} /> */}
                    <div className='Drager-column-item-title'>
                        <i className={`las ${item.project.icon}`} />
                        {item.name}
                       
                    </div>
                    <div className='Drager-column-item-responsible'>
                        <div style={{ backgroundImage: `url(${item.responsible.selfie})` }} className='Drager-column-item-responsible-selfie'></div>

                        <b>{item.responsible?.name} {item.responsible?.surname}</b>

                    </div>
                    
                    <div className='Drager-column-item-description'>
                        {item.description}
                    </div>
                        
                    <div className='Drager-column-item-time'>
                        <div className='Drager-column-item-time-date' title={item.creation}>
                            <i className='las la-calendar-plus' /> Creado  
                            <b> {
                                moment(new Date(item.creation).toISOString()).fromNow()
                            }</b>
                        </div>
                        <div className='Drager-column-item-time-date'>
                            <i className='las la-calendar-check' /> Entrega  <b> {
                                moment(new Date(item.deliveredAt).toISOString()).fromNow()
                            }</b>
                        </div>
                       
                    </div>
                    <div className='Drager-column-item-author'>
                        Creado por <b>{item.author?.name} {item.author?.surname}</b>
                        </div>
                    <div className='Drager-column-item-content'>
                        </div>
                </div>
            )}
        </Draggable>
    )
}

export default Item
