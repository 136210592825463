
import ReactMarkdown from 'react-markdown'
import Replacer from '../../../functions/replacer'


type Props = {
    component: any,
    document: any
}

export default function List(props: Props) {



    return <div className='List'>
        <ul>
            {
                props.component.content.map((item: any, index: number) => {
                    return <li className='Li' key={index}>
                        <div className='title'><ReactMarkdown>{Replacer(item.title, props.document, '')}</ReactMarkdown></div>
                        <div className='description'><ReactMarkdown>{Replacer(item.description, props.document, '')}</ReactMarkdown></div>
                    </li>
                })
            }</ul>
    </div>
}



