import Flow from "./flow"



type Props = {
    component: any,
    document: any
}

export default function P(props: Props) {
    const objects: any = [];
    let object_i = 0;
    props?.document?.project?.objects.map((item: any) => {
        object_i++;
        if (item.type === props.component?.filter?.type) {
            objects.push({
                "id": "object_" + object_i,
                "type": "box",
                "name": item.name,
                "label": "API",
                "path": "liquid",
                "to": [
                    "microchip",
                    "app",

                ]
            })
        }
        return true
    })
    const productions: any = [];
    let productions_i = 0;

    props?.document?.project?.productions.map((item: any) => {
        productions_i++;
        if (item.type === props.component?.filter?.type) {
            productions.push({
                "id": "technology_" + productions_i,
                "type": "box",
                "name": item.name, "path": "arc",

                "label": item.technology.name,
                "to": [
                    "app"
                ]
            })
        }
        return true
    })
    return <>
        <Flow component={{
            content: [
                [
                    {
                        "child": [
                            {
                                "type": "box",
                                "id": "app",
                                "name": props.document?.project?.organization?.name,
                                "to": [
                                    "api",
                                    "two"
                                ]
                            },
                            {
                                "type": "comment",
                                "name": "Software compuesto por un Backend y un Frontend desplegado automáticamente y con una arquitectura de microservicios."
                            },
                            {
                                "type": "space"
                            },
                            {
                                "type": "box",
                                "id": "monitoring",
                                "name": "Monitoring",
                                "label": "PING",
                                "to": [
                                    "api",
                                    "two"
                                ]
                            },
                            {
                                "type": "comment",
                                "name": "Toda las piezas del software deben estar monitoreadas para la detección de problemas endémicos."
                            }
                        ]
                    },
                    {
                        "child": objects
                    },

                    {
                        "child": [
                            {
                                "to": [
                                    "database",
                                    "News"
                                ],
                                "id": "sentry",
                                "type": "screen",
                                "icon": "la la-bug",
                                "name": "Sentry"
                            },
                            {
                                "type": "comment",
                                "name": "Todos los errores del software son almacenados en Sentry, generando un histórico absoluto y validando el comportamiento de todo el conjunto (frontend y backend)."
                            },
                            {
                                "to": [
                                    "monitoring",
                                    "database",
                                    "service",
                                    "testing",
                                    "cron",
                                    "sentry"
                                ],
                                "id": "microchip",
                                "path": "liquid",
                                "stroke": 1,
                                "type": "icon",
                                "icon": "las la-microchip",
                                "name": "SERVICE API",
                                "description": "BACKEND REST"
                            }
                        ]
                    }
                ],
                [
                    {
                        "child": productions
                    },
                    {
                        "child": [
                            {
                                "id": "service",
                                "to": [
                                    "request"
                                ],
                                "path": "liquid",
                                "type": "box",
                                "name": "Autenticación"
                            },
                            {
                                "to": [
                                    "request"
                                ],
                                "id": "methods",
                                "path": "liquid",
                                "type": "box",
                                "name": "Métodos del Frontend"
                            },
                            {
                                "type": "comment",
                                "name": "Los métodos del Frontend son de tipo REST absolutos. Retornos en formato JSON para todas las peticiones a excepción de los canales de streaming."
                            }
                        ]
                    },
                    {
                        "child": [
                            {
                                "type": "space"
                            },
                            {
                                "type": "space"
                            },
                            {
                                "to": [
                                    "screen1",
                                    "screen2",
                                    "screen3",
                                    "screen4"
                                ],
                                "path": "arc",
                                "id": "request",
                                "type": "box",
                                "label": "JWT",
                                "name": "Request"
                            }
                        ]
                    }
                ],
                [
                    {
                        "child": [
                            {
                                "id": "screen1",
                                "type": "screen",
                                "name": "Frontend",
                                "icon": "las la-user"
                            },
                            {
                                "type": "comment",
                                "name": "Desplegado automáticamente mediante Github Actions."
                            }
                        ]
                    },
                    {
                        "child": [
                            {
                                "id": "screen2",
                                "type": "screen",
                                "name": "Seguridad",
                                "icon": "las la-lock"
                            },
                            {
                                "type": "comment",
                                "name": "Todas las piezas del software se encuentran securizadas."
                            }
                        ]
                    },
                    {
                        "child": [
                            {
                                "id": "testing",
                                "type": "screen",
                                "name": "Testing",
                                "icon": "las la-ghost"
                            },
                            {
                                "type": "comment",
                                "name": "Automatizamos el testing unitario de cada pieza del software."
                            }
                        ]
                    },
                    {
                        "child": [
                            {
                                "id": "cron",
                                "type": "screen",
                                "name": "Cron",
                                "icon": "las la-history"
                            },
                            {
                                "type": "comment",
                                "name": "Tareas automatizadas para supervisar el comportamiento del sistema."
                            }
                        ]
                    }
                ]
            ]
        }} />
    </>
}