

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Meets() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header title="Reuniones" description="Listado de oportunidades de desarrollo" buttons={[{
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        path={`/meet?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    path={'/meet'}
                    method={'post'}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'type',
                                title: "Tipo",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'project',
                                        label: 'Project'
                                    },
                                    {
                                        id: 'banking',
                                        label: 'Comisión Bancaria'
                                    },
                                    {
                                        id: 'unknown',
                                        label: 'Desconocido'
                                    },
                                    {
                                        id: 'material',
                                        label: 'Material'
                                    },
                                    {
                                        id: 'tax',
                                        label: 'Impuesto'
                                    },
                                    {
                                        id: 'supplier',
                                        label: 'Proveedor'
                                    },
                                    {
                                        id: 'social',
                                        label: 'Seguridad Social'
                                    },
                                    {
                                        id: 'insurance',
                                        label: 'Seguros'
                                    },
                                    {
                                        id: 'salary',
                                        label: 'Sueldo'
                                    },
                                    {
                                        id: 'transport',
                                        label: 'Transporte'
                                    }
                                ]
                            },
                            {
                                name: 'organization_id',
                                title: "Organización",
                                type: 'select',
                                path: '/organization',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'project_id',
                                title: "Projecte",
                                type: 'select',
                                path: '/project',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'bill_id',
                                title: "Factura de Proyecto",
                                type: 'select',
                                path: '/bill',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'expense_id',
                                title: "Factura de Gasto",
                                type: 'select',
                                path: '/expense',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'commision_id',
                                title: "Comisión Asociada",
                                type: 'select',
                                path: '/expense',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'concept',
                                title: "Concepto",
                                type: 'text',
                            },
                            {
                                name: 'total',
                                title: "Total",
                                type: 'text',
                            },
                            {
                                name: 'operation',
                                title: "Fecha de Operación",
                                type: 'text',
                            },
                            {
                                name: 'execution',
                                title: "Fecha de Valor (cuando nos quitan la pasta)",
                                type: 'text',
                            },
                            {
                                name: 'code',
                                title: "Código del Banco",
                                type: 'text',
                            },
                            {
                                name: 'balance',
                                title: "Saldo",
                                type: 'text',
                            }
                        ]
                    } />}
                {update && <Form
                    path={'/meet?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'project_id',
                                title: "Projecte",
                                type: 'select',
                                path: '/project',
                                placeholder: 'Nombre de la Empresa S.L',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                                placeholder: 'Nombre del proyecto',
                            }
                        ]
                    } />}
                <Widget title="Proyectos">
                    <Table reload={tableKey}
                        path={'/meet'}
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },
                                {
                                    name: 'project.name',
                                    title: "Compañía",
                                    width: 200

                                },
                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 200

                                },
                                {
                                    name: 'email',
                                    title: "Presupuestado",
                                    width: 300

                                },
                                {
                                    name: 'email',
                                    title: "Aprobado",
                                    width: 300
                                },
                                {
                                    name: 'status',
                                    title: "Estado",
                                    width: 90
                                },
                                {
                                    name: 'creation',
                                    title: "Creación",
                                    width: 90
                                },
                                {
                                    name: 'since',
                                    title: "Hace...",
                                    width: 90
                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Meets
