import './index.css'
type props = {
    children: any,
    title?: string,
    type?: string, className?: string
}


export const Widget = (props: props) => {


    return (<div className={`Widget Widget-${props?.type} ${props.className}`} >
        {props.title && <div className="Widget-title">{props.title}</div>}
        <div className="Widget-content">{props.children}</div>
    </div>
    )
}