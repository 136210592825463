

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Projects() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    const [update, setUpdate] = useState<any>(false)



    return (

        <>
            <Header
                icon={'las la-theater-masks'}
                title="Equipo de trabajo"
                description="El talento gana partidos, pero el trabajo en equipo ganan campeonatos"
                buttons={[{
                    text: "Nuevo usuario",
                    onClick: () => {
                        setForm(true)
                    },
                    icon: 'las la-plus'
                }]}
            />

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        path={`/directory/user?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'collection_id',
                                    title: "Collection",
                                    type: 'select',
                                    path: '/collection',
                                    placeholder: 'Nombre de la Empresa S.L',
                                    columns: [
                                        {
                                            name: 'name',
                                            title: 'Nombre',
                                        }
                                    ]
                                },
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    method="post"
                    path="/directory/user"
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'position_id',
                                title: "Cargo",
                                type: 'select',
                                path: '/directory/position',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'commision',
                                title: "Comisión",
                                type: 'text',
                            },
                            {
                                name: 'surname',
                                title: "Apellido",
                                type: 'text',
                            },
                            {
                                name: 'username',
                                title: "Username",
                                type: 'text',
                            },
                            {
                                name: 'signature',
                                title: "Password",
                                type: 'text',
                            },
                            {
                                name: 'role',
                                title: "Role",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'admin',
                                        label: 'Administrador'
                                    },
                                    {
                                        id: 'accounter',
                                        label: 'Contable'
                                    },
                                    {
                                        id: 'commercial',
                                        label: 'Comercial'
                                    }
                                ]
                            },
                            {
                                name: 'cover',
                                title: "Imagen de Portada",
                                type: 'file'
                            },

                        ]
                    } />}
                {update && <Form
                    path={'/directory/user?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'position_id',
                                title: "Cargo",
                                type: 'select',
                                path: '/directory/position',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'commision',
                                title: "Comisión",
                                type: 'text',
                            },
                            {
                                name: 'surname',
                                title: "Apellido",
                                type: 'text',
                            },
                            {
                                name: 'username',
                                title: "Username",
                                type: 'text',
                            },
                            {
                                name: 'signature',
                                title: "Password",
                                type: 'text',
                            },
                            {
                                name: 'role',
                                title: "Role",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'admin',
                                        label: 'Administrador'
                                    },
                                    {
                                        id: 'accounter',
                                        label: 'Contable'
                                    },
                                    {
                                        id: 'commercial',
                                        label: 'Comercial'
                                    }
                                ]
                            },
                            {
                                name: 'selfie',
                                title: "Selfie",
                                type: 'file'
                            },
                        ]
                    } />}
                <Widget title="Personal Autorizado">
                    <Table reload={tableKey} columns={
                        [
                            {
                                onPress: (data: any) => {
                                    setDestruction(data)
                                },
                                icon: 'las la-trash',
                                width: 50

                            }, {
                                onPress: (data: any) => {
                                    setUpdate(data)
                                },
                                icon: 'las la-pen',
                                width: 50

                            },
                            {
                                title: "",
                                width: 40,
                                paddingLeft: 0,
                                render: (item: any) => {
                                    // eslint-disable-next-line jsx-a11y/alt-text
                                    const selfieUrl = item.selfie+'?cache='+Math.random()
                                    return <span className="avatar" style={{ 
                                        backgroundImage: `url(${selfieUrl})` }} />
                                }
                            },
                            {
                                name: 'commision',
                                title: "Nombre y Apellido",
                                width: 200,
                                render: (item: any) => {
                                    // eslint-disable-next-line jsx-a11y/alt-text
                                    return `${item.name} ${item.surname}`
                                }
                            },

                            {
                                name: 'position',
                                title: "Cargo",
                                width: 200,
                                render: (item: any) => {
                                    return `${item?.position?.name} `
                                }
                            },
                            {
                                name: 'projects',
                                title: "Proyectos",
                                width: 100, type: 'link'

                            },
                            {
                                name: 'contacts',
                                title: "Contactos",
                                width: 100,
                                path: '/directory/users/contacts?users_id=',

                            },
                            {
                                name: 'meets',
                                title: "Reuniones",
                                width: 100,
                                path: '/projects?users_id=',


                            },
                            {
                                name: 'tasks',
                                title: "Tareas",
                                width: 100,
                                path: '/projects?status=true&users_id=',


                            }
                        ]
                    }
                        method="get"
                        path="/directory/user"
                    />
                </Widget>

            </div>
        </>

    );
}

export default Projects
