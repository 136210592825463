

import { Widget } from "../../componets/Widget";
import Table from "../../componets/Table";

function Clients() {
    return (

        <>
            <div className="Widgets">
                <Widget title="Campañas">
                    <Table columns={
                        [

                            {
                                name: 'name',
                                title: "Nombre",
                                width: 100

                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                width: 100

                            },
                            {
                                name: 'projects',
                                title: "Proyectos",
                                width: 100

                            },
                            {
                                name: 'budgets',
                                title: "Presupuestos",
                                width: 100

                            },
                            {
                                name: 'developments',
                                title: "Desarrollos",
                                width: 100

                            }
                        ]
                    } items={[{
                        name: "Software Médico",
                        description: "Campaña de software para la gestión de pacientes médicos",
                        projects: 25,
                        budgets: 20,
                        developments: 10
                    }]} />
                </Widget>

            </div>
        </>

    );
}

export default Clients
