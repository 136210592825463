import Replacer from "../../../functions/replacer"
import ReactMarkdown from 'react-markdown'



type Props = {
    component: any
    document: any
}

export default function H6(props: Props) {



    return <div className='H6'>
        <ReactMarkdown>{Replacer(props.component.content, props.document)}</ReactMarkdown>
    </div>
}
