import Replacer from "../../../functions/replacer"

import ReactMarkdown from 'react-markdown'


type Props = {
    component: any
    document: any
}

export default function H3(props: Props) {



    return <div className='H3'>
        <ReactMarkdown>{Replacer(props.component.content, props.document)}</ReactMarkdown>
    </div >
}
