

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Projects() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    const [update, setUpdate] = useState<any>(false)



    return (

        <>
            <Header title="Brandes" description="Listado de oportunidades de desarrollo" buttons={[{
                text: "Nuevo brande",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/brand?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [

                            ]
                        } />}
                {form && <Form
                    method="post"
                    path="/brand"
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'account_id',
                                title: "Cuenta Bancaria",
                                type: 'select',
                                path: '/account',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    },
                                    {
                                        name: 'bank',
                                        title: 'Banco',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Compañía",
                                type: 'text',
                                placeholder: 'Nombre de la Empresa S.L',
                            },
                            {
                                name: 'description',
                                title: "Descripcion",
                                type: 'text',
                            }, {
                                name: 'title',
                                title: "Titulo",
                                type: 'text',
                            },
                            {
                                name: 'subtitle',
                                title: "Subtitulo",
                                type: 'text',
                            },
                            {
                                name: 'foot',
                                title: "Foot Slogan",
                                type: 'text',
                            }, {
                                name: 'confidential',
                                title: "Mensaje Confidencial",
                                type: 'text',
                            },

                        ]
                    } />}
                {update && <Form
                    path={'/brand?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'account_id',
                                title: "Cuenta Bancaria",
                                type: 'select',
                                path: '/account',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    },
                                    {
                                        name: 'bank',
                                        title: 'Banco',
                                    }
                                ]
                            },
                            {
                                name: 'name',
                                title: "Marca",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripcion",
                                type: 'text',
                            },
                            {
                                name: 'title',
                                title: "Titulo",
                                type: 'text',
                            },
                            {
                                name: 'subtitle',
                                title: "Subtitulo",
                                type: 'text',
                            },
                            {
                                name: 'foot',
                                title: "Foot Slogan",
                                type: 'text',
                            },
                            {
                                name: 'confidential',
                                title: "Mensaje Confidencial",
                                type: 'text',
                            },
                        ]
                    } />}
                <Widget title="Proyectos">
                    <Table reload={tableKey} columns={
                        [
                            {
                                onPress: (data: any) => {
                                    setDestruction(data)
                                },
                                icon: 'las la-trash',
                                width: 50

                            }, {
                                onPress: (data: any) => {
                                    setUpdate(data)
                                },
                                icon: 'las la-pen',
                                width: 50

                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                width: 200,


                            },
                            {
                                name: 'meets',
                                title: "Reuniones",
                                width: 140,
                                path: '/projects/meets?brand_id=',

                            },
                            {
                                name: 'contacts',
                                title: "Organizaciones",
                                width: 140,
                                path: '/organizations?brand_id=',

                            },
                            {
                                name: 'projects',
                                title: "Proyectos",
                                width: 140,
                                path: '/projects?brand_id=',


                            },

                            {
                                name: 'creation',
                                title: "Creación",
                                type: 'date',
                                width: 120
                            },

                            {
                                name: 'creation',
                                type: 'since',
                                title: "Creación",
                                width: 200
                            }

                        ]
                    }
                        method="get"
                        path="/brand"
                    />
                </Widget>

            </div>
        </>

    );
}

export default Projects
