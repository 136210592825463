import {
  SET_USER,
  CLEAR,
  SET_SCHEME,
  SET_REPOSITORY,
  SET_ORGANIZATION,
  SET_PROFILE,
  SET_LANGUAGES,
  SET_LANGUAGE,
  SET_LANGUAGES_GITHUB,
  SET_PROFILE_GITHUB,
  SET_SCHEME_GITHUB,
  SET_MODE,
  SET_AUDIO,
  SET_THEME,
  SET_ENVIRONMENT,
  SET_ROLE,
  SET_PROJECT
} from '../constants/actions';

const initialState = {
  user: false,
  scheme: null,
  endpoint: 'https://organizator-api-dot-organizator-339613.ey.r.appspot.com'
};

//endpoint: 'https://backend-dot-b-onic-production.oa.r.appspot.com'


function rootReducer(state = initialState, action) {
  if (action.type === SET_ORGANIZATION) {
    return Object.assign({}, state, {
      organization: action.payload,
    });
  }
  if (action.type === SET_SCHEME) {

    if (action.payload) {
      //Save(state.user, state.organization.login, state.repository.name, 'configuration.json', action.payload);

    }

    // guardamos en github:


    return Object.assign({}, state, {
      scheme: action.payload,
    });
  }
  if (action.type === SET_REPOSITORY) {
    return Object.assign({}, state, {
      repository: action.payload,
    });
  }
  if (action.type === SET_USER) {
    return Object.assign({}, state, {
      user: action.payload,
    });
  }
  if (action.type === CLEAR) {
    return {};
  }
  if (action.type === SET_PROFILE) {
    return Object.assign({}, state, {
      profile: action.payload,
    });
  }
  if (action.type === SET_LANGUAGES) {
    return Object.assign({}, state, {
      languages: action.payload,
    });
  }
  if (action.type === SET_LANGUAGE) {
    return Object.assign({}, state, {
      language: action.payload,
    });
  }
  if (action.type === SET_LANGUAGES_GITHUB) {
    return Object.assign({}, state, {
      languagesGithub: action.payload,
    });
  }
  if (action.type === SET_PROFILE_GITHUB) {
    return Object.assign({}, state, {
      profileGithub: action.payload,
    });
  }
  if (action.type === SET_SCHEME_GITHUB) {
    return Object.assign({}, state, {
      schemeGithub: action.payload,
    });
  }
  if (action.type === SET_MODE) {
    return Object.assign({}, state, {
      mode: action.payload,
    });
  }

  if (action.type === SET_THEME) {
    return Object.assign({}, state, {
      theme: action.payload,
    });
  }

  if (action.type === SET_AUDIO) {
    return Object.assign({}, state, {
      audio: action.payload,
    });
  }

  if (action.type === SET_ENVIRONMENT) {
    return Object.assign({}, state, {
      environment: action.payload,
    });
  }

  if (action.type === SET_ROLE) {
    return Object.assign({}, state, {
      role: action.payload,
    });
  }

  if (action.type === SET_PROJECT) {
    return Object.assign({}, state, {
      project: action.payload,
    });
  }

  return state;
}

export default rootReducer;