import React from 'react';
// import styles
import { setUser, clear, setRepository } from '../../redux/actions'
import { connect } from "react-redux";
import './styles.css'
import {
    Link,
    useMatch,
    useResolvedPath,
} from "react-router-dom";
import useSound from 'use-sound';

import type { LinkProps } from "react-router-dom";


const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {
    setUser, clear, setRepository
}
// function login props redux connect

interface DispatchProps {
    audio: any,
    tabs: {
        title: string,
        icon: string,
        path: string
    }[]
}
type Props = DispatchProps

const Header: React.FC<Props> = (props) => {

    const [soundClick] = useSound(
        '/sounds/pop.mp3',
        { volume: 0.1 }
    );
    const [soundEnter] = useSound(
        '/sounds/pop-up-on.mp3',
        { volume: 0.03 }
    );
    return (

        <div className="Tabs" >
            {
                props.tabs && props.tabs.map((tab, index) => {
                    return <CustomLink soundEnter={() => { props.audio && soundEnter() }} soundClick={() => { props.audio && soundClick() }} to={tab.path} ><i className={tab.icon}></i> {tab.title} </CustomLink>
                })
            }
        </div>
    );
};


type LinkPropsMore = LinkProps & {
    soundEnter: any,
    soundClick: any
}

function CustomLink({ children, soundEnter, soundClick, to, ...props }: LinkPropsMore) {
    let resolved = useResolvedPath(to);

    // match query and path


    let match = useMatch({ path: resolved.pathname });
    let className: string = 'desactive'

    if (match) className = "active"
    return (
        <Link
            className={className}
            to={to}
            onMouseEnter={() => {
                soundEnter()
            }}
            onClick={() => {
                soundClick()

            }}
            {...props}
        >
            {children}
        </Link>

    );
}
const ShowTheLocationWithRouter = (Header);

export default connect(mapStateToProps, mapDispatchToProps)(ShowTheLocationWithRouter)

