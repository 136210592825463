

type Props = {
    document: any,
    component: any,
    requerimentStart: number,
    requerimentEnd: number,
}


export default function Requeriments(props: Props) {
    let requerimentsSelected = props?.document?.project?.requeriments.sort((a: any, b: any) => {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
    })
    // filter by section
    let requerimentsSelectedGrouped = requerimentsSelected.reduce((r: any, a: any) => {
        if (!a.section) a.section = 'Generales'
        r[a.section] = [...r[a.section] || [], a];
        return r;
    }, {});

    let renderRequerimentCounter = 0
    let currentSection = ''
    return <div className='Requeriments'>
        <style>
            {`
            .Requeriments ul {
                margin-left: -80px;
                margin-right: -80px;
                overflow:hidden;
            }
            
            .Requeriments ul li {
                font-size: 0; height:30px;
                display:flex;border-bottom:1px solid #eee
            }
            
            .Requeriments .definition {overflow:hidden;padding-left:10px;box-sizing:border-box; max-width:500px;min-width:500px}
            .Requeriments .priority {padding-left:10px;box-sizing:border-box;min-width:70px;max-width:70px}
            .Requeriments .mode {padding-left:10px;box-sizing:border-box;min-width:70px;max-width:70px}
            .Requeriments .source {padding-left:10px;box-sizing:border-box;border-right:none;min-width:70px;max-width:70px}
            .Requeriments ul .id {
                border-radius:0px 4px 4px 0px;
                background: #222;
                border-right:none;
                width: 80px;
                padding-right:10px;
                box-sizing:border-box;
                color: #ffffff8b;
                font-size: 10px;
                text-align:right;
                text-transform:uppercase;
                font-weight: 100;
            }
            .Requeriments ul .id span{margin:0 0px 0 2px; color:#6498ff}
            .Requeriments ul .id b{margin-left:0px; color:#fff; }

            .Requeriments ul li div p{margin-left:10px;overflow:hidden; color:#888; display:inline}
            .Requeriments ul li div {
                font-size:12px;
                display: inline-block;
                line-height:30px; height:30px;
                border-right: 1px solid #ddd;
            }
            .Requeriments .sectioner{ background:#eee; font-size:15px;padding:10px 15px;margin-top:10px;margin-bottom:10px; line-height:1;height:auto}
            .Requeriments .sectioner b{ margin-left:10px}
            .Requeriments .head{font-size:4px; letter-spacing:1px;text-transform:uppercase; font-weight:100}
            .Requeriments .head div{background:none; color:#666;font-size:9px; padding-left:0; text-align:center}
           `}
        </style>
        <ul>

            <li className="head">
                <div className="id">
                    ID
                </div>
                <div className="definition">
                    DEFINITION
                </div>
                <div className="priority">
                    PRIORITY
                </div>
                <div className="mode">
                    MODE
                </div>
                <div className="source  ">
                    SOURCE
                </div>
            </li>
            {
                requerimentsSelectedGrouped && Object.keys(requerimentsSelectedGrouped).map((key: any) => {
                    let listRequeriments: any = requerimentsSelectedGrouped[key]
                    let showSection = false;
                    if (key !== currentSection) {
                        showSection = true;
                    }
                    return <>
                        {
                            listRequeriments.map((file: any, index: number) => {
                                if (props.component && props.component?.filter?.type !== file.type) {
                                    return null
                                }
                                renderRequerimentCounter++;
                                console.log('Render:', renderRequerimentCounter)

                                if (renderRequerimentCounter !== 0 && props.requerimentStart > renderRequerimentCounter) return null
                                console.log('Render:', renderRequerimentCounter)

                                if (props.requerimentEnd <= renderRequerimentCounter - 1) return null

                                let ShowSectionInLine = false
                                if (showSection) {
                                    showSection = false;
                                    ShowSectionInLine = true;
                                }
                                return <>
                                    {ShowSectionInLine && <li className="sectioner">{props.component.detail} <b>{key}</b></li>}
                                    <li key={index}>
                                        <div className='id'>
                                            #<span>{file.type.substring(0, 3)}</span>{key.substring(0, 3)}<b>{index + 1}</b>
                                        </div>
                                        <div className="definition">
                                            <b>{file.name}</b>
                                            <p>{file.description}</p>
                                        </div>
                                        <div className="priority">{file.priority}</div>
                                        <div className="mode">{file.mode}</div>
                                        <div className="source">{file.source}</div>

                                    </li>
                                </>
                            })
                        }
                    </>

                })
            }
        </ul>
    </div >
}
