

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";
import { connect } from "react-redux";
import Uml from "../../../../componets/Uml";



const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
// function login props redux connect

interface DispatchProps {
    project: any
}
type Props = DispatchProps

const Stories: React.FC<Props> = (props) => {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [uml, setUml] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            {uml && <Uml />}

            <Header icon="las la-sliders-h" title="Requerimientos" description="Definición de los requerimientos del software" buttons={[{
                text: "Nueva especificación",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }, {
                text: "Product Design Stories",
                onClick: () => {
                    setUml(true)
                },
                icon: 'las la-file'
            }]} />

            <div className="Widgets">

                {
                    destruction && <Form
                        method="delete"
                        path={`/story?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Nombre de la Empresa S.L',
                                }
                            ]
                        } />}
                {form && <Form
                    path={'/story'}
                    method={'post'}
                    header={
                        {
                            icon: "las la-universal-access",
                            title: "Nuevo Requerimiento",
                        }
                    }
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'project_id',
                                title: "Proyecto",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },

                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                description: "Información pública sobre la especificación",
                                type: 'textarea'
                            },
                            {
                                name: 'diagram',
                                title: "Diagrama",
                                type: 'textarea'
                            },
                        ]
                    } />}
                {update && <Form
                    path={'/story?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'project_id',
                                title: "Proyecto",
                                type: 'select',
                                path: '/project',
                                columns: [
                                    {
                                        name: 'name',
                                        title: 'Nombre',
                                    }
                                ]
                            },

                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                description: "Información pública sobre la especificación",
                                type: 'textarea'
                            },
                            {
                                name: 'priority',
                                title: "Prioridad",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'required',
                                        label: 'Obligatorio'
                                    },
                                    {
                                        id: 'desirable',
                                        label: 'Deseable'
                                    },
                                    {
                                        id: 'optional',
                                        label: 'Opcional'
                                    }
                                ]
                            },
                            {
                                name: 'type',
                                title: "Tipo de Requisito",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'general',
                                        label: 'General'
                                    },
                                    {
                                        id: 'functional',
                                        label: 'Funcional'
                                    },
                                    {
                                        id: 'implementation',
                                        label: 'Implementación'
                                    },
                                    {
                                        id: 'performance',
                                        label: 'Rendimiento'
                                    },
                                    {
                                        id: 'usability',
                                        label: 'usabilidad'
                                    }
                                ]
                            },
                            {
                                name: 'mode',
                                title: "Disposición",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'liveness',
                                        label: 'Liveness (hace)'
                                    },
                                    {
                                        id: 'safety',
                                        label: 'Safety (no hace)'
                                    }
                                ]
                            },
                            {
                                name: 'capture',
                                title: "Momento de Captura",
                                type: 'select',
                                placeholder: 'Método del primer contacto',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'early',
                                        label: 'Antes de Iniciar el Desarrollo'
                                    },
                                    {
                                        id: 'late',
                                        label: 'Durante el desarrollo'
                                    }
                                ]
                            },
                            {
                                name: 'source',
                                title: "Fuente",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'client',
                                        label: 'Cliente'
                                    },
                                    {
                                        id: 'we',
                                        label: 'Nosotros'
                                    }
                                ]
                            },
                            {
                                name: 'catchment',
                                title: "Captación del Requisito",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        id: 'meet',
                                        label: 'Reunión'
                                    },
                                    {
                                        id: 'documentation',
                                        label: 'Documentación'
                                    },
                                    {
                                        id: 'development',
                                        label: 'Desarrollo'
                                    }
                                ]
                            },
                        ]
                    } />}
                <Widget title="Listado de Reuniones">
                    <Table reload={tableKey}
                        path={'/story'}
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },

                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 400

                                },
                                {
                                    name: 'priority',
                                    title: "Prioridad",
                                    width: 120

                                },

                                {
                                    name: 'type',
                                    title: "Tipo",
                                    width: 90
                                }, {
                                    name: 'mode',
                                    title: "Modo",
                                    width: 90
                                }, {
                                    name: 'capture',
                                    title: "Captura",
                                    width: 90
                                }, {
                                    name: 'source',
                                    title: "Recurso",
                                    width: 90
                                }, {
                                    name: 'catchment',
                                    title: "catchment",
                                    width: 90
                                },
                                {
                                    name: 'creation',
                                    title: "Fecha",
                                    width: 100,
                                    type: 'since'
                                },

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Stories)
