import './index.css'
type props = {
    items: {
        icon: string,
        detail: string | number,
        title: string
    }[]
}


export const Details = (props: props) => {


    return (< div className='Details' >
        <ul >{
            props.items.map((item, index) => {
                return (<li key={index}>
                    <i className={item.icon}></i>
                    <b>{item.title}</b>
                    <span>{item.detail}</span>
                </li>)
            })

        }</ul>
    </div>
    )
}