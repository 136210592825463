

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Positions() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [update, setUpdate] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')
    return (

        <>
            <Header title="Cargos Laborales" icon="las la-network-wired" description="Si cada uno limpia su acera, la calle estará limpia." buttons={[{
                text: "Nuevo cargo",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/directory/position?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                    placeholder: 'Product la Empresa S.L',
                                },]
                        } />}
                {form && <Form
                    path={'/directory/position'}
                    method={'post'}
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            },

                            {
                                name: 'description',
                                title: "Descripción",
                                type: 'text',
                            }
                        ]
                    } />}
                {update && <Form
                    path={'/directory/position?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [

                            {
                                name: 'name',
                                title: "Nombre",
                                type: 'text',
                            }, {
                                name: 'description',
                                title: "Descripción",
                                type: 'text',
                            }

                        ]
                    } />}
                <Widget title="Listado de Cargos Laborales">
                    <Table reload={tableKey}
                        path={'/directory/position'}
                        method={'get'}
                        columns={
                            [
                                {
                                    onPress: (data: any) => {
                                        setDestruction(data)
                                    },
                                    icon: 'las la-trash',
                                    width: 50

                                },
                                {
                                    onPress: (data: any) => {
                                        setUpdate(data)
                                    },
                                    icon: 'las la-pen',
                                    width: 50

                                },

                                {
                                    name: 'name',
                                    title: "Nombre",
                                    width: 100

                                },
                                {
                                    name: 'description',
                                    title: "Descripción",
                                    width: 300

                                },
                                {
                                    name: 'meets',
                                    title: "Personal",
                                    width: 100,
                                    path: '/users?users_id=',


                                }

                            ]
                        } />
                </Widget>

            </div>
        </>

    );
}

export default Positions
