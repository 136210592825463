function Replacer(string: any, obj: any, start: string = '') {
    if (!string) return ''
    if (!obj) return string
    // replace dotnotation with object multidimensional:
    var s = string;
    for (var key in obj) {
        // multidimensional
        // is array
        if (Array.isArray(obj[key])) {
            let le = 0
            if (obj[key].length) le = obj[key].length;
            s = s.replace(new RegExp('{' + start + key + '.number}', 'g'), le);
        }
        if (typeof obj[key] === 'object') {



            if (obj[key]) {

                s = Replacer(s, obj[key], start + key + '.');
            }
        } else {


            s = s.replace(new RegExp('{' + start + key + '}', 'g'), obj[key]);
        }
    }
    return s;
}

export default Replacer