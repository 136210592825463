
import './index.css'
import { connect } from "react-redux";
import { useEffect, useState } from 'react';



const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
// function login props redux connect

interface DispatchProps {
    user: any
}
type Props = DispatchProps

const Uml: React.FC<Props> = (props) => {
    const [svg] = useState<any>(false);
    useEffect(() => {

    }, [])

    return (<div className='Uml' >
        {
            svg && <div dangerouslySetInnerHTML={{ __html: svg }} />
        }
    </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Uml)
