

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Resources() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')

    const [update, setUpdate] = useState<any>(false)


    return (

        <>
            <Header icon="las la-atom" title="Recursos" description="Listado de Servicios de Alicunde Systems" buttons={[{
                text: "Nuevo",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        path={`/resource?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                        inputs={
                            [
                                {
                                    name: 'name',
                                    title: "Elimiamos?",
                                    type: 'text',
                                }
                            ]
                        } />}
                {form && <Form
                    method="post"
                    path="/resource"
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre del Resourceo",
                                type: 'text',
                            },
                            {
                                name: 'minimum',
                                title: "Precio mínimo",
                                type: 'text',
                                placeholder: 'Coste del Desarrollo',
                            },


                        ]
                    } />}
                {update && <Form
                    path={'/resource?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre del Resourceo",
                                type: 'text',
                            },
                            {
                                name: 'minimum',
                                title: "Precio mínimo",
                                type: 'text',
                                placeholder: 'Coste del Desarrollo',
                            },
                        ]
                    } />}
                <Widget title="Proyectos">
                    <Table reload={tableKey} columns={
                        [
                            {
                                onPress: (data: any) => {
                                    setDestruction(data)
                                },
                                icon: 'las la-trash',
                                width: 50

                            },
                            {
                                onPress: (data: any) => {
                                    setUpdate(data)
                                },
                                icon: 'las la-pen',
                                width: 50

                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                width: 200,


                            },
                            {
                                name: 'minimum',
                                title: "Precio Mínimo",
                                width: 200,
                                type: 'price'
                            }

                        ]
                    }
                        method="get"
                        path="/resource"
                    />
                </Widget>

            </div>
        </>

    );
}

export default Resources
