


type Props = {
    document: any
}

export default function Meets(props: Props) {



    return <div className='Meets'>
        <ul>
            {
                props?.document?.project?.meets?.map((file: any, index: number) => {
                    return <li key={index}>
                        <i className="las la-calendar-alt"></i>
                        <b>{file.name}</b>
                        <p>{file.description}</p>
                        <em>{file.meeting_start.split('T')[0]}</em>
                    </li>
                })
            }
        </ul>
    </div>
}
