import './select.css'
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { useEffect, useState } from 'react';



const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
// function login props redux connect

interface DispatchProps {
    datetime: Date,
    type?: string,
    onSelect: (item: any) => void

}
type Props = DispatchProps

const Select: React.FC<Props> = (props) => {
    const [selected, setSelected] = useState<Date>(new Date());
    useEffect(() => {
        setSelected(props.datetime);
        console.log('fecha:', props.datetime)

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div className='Datetime' >

        <DatePicker
            showTimeSelect={props.type && props.type === 'date' ? false : true}
            dateFormat={props.type && props.type === 'date' ? 'MMMM d, yyyy' : 'MMMM d, yyyy h:mm aa'}
            selected={selected}
            placeholderText={props.type && props.type === 'date' ? 'MM/DD/YYYY' : 'MM/DD/YYYY h:mm aa'}
            onChange={(date: any) => {
                // set value in 
                setSelected(date)
                props.onSelect(date)
            }}


        />



    </div >
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Select)
