

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Technologys() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')

    const [update, setUpdate] = useState<any>(false)


    return (

        <>
            <Header icon="las la-atom" title="Tecnologías" description="Listado de Servicios de Alicunde Systems" buttons={[{
                text: "Nuevo",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        header={
                            {
                                title: 'Eliminar Technologyo',
                                description: 'Esta seguro que desea eliminar el technologyo?',
                                icon: 'las la-trash'

                            }
                        }
                        path={`/technology?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    method="post"
                    path="/technology"
                    header={
                        {
                            title: 'Nuevo Technologyo',
                            description: 'Agregue un nuevo technologyo',
                            icon: "las la-pen"
                        }
                    }
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre de la Tecnología",
                                type: 'text',
                            },
                            {
                                name: 'first_version',
                                title: "Año de Primera Versión",
                                type: 'text',
                            },
                            {
                                name: 'last_version',
                                title: "Año de Última Versión",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                type: 'textarea',
                            },
                            {
                                name: 'price',
                                title: "Tarifa",
                                type: 'text',
                            },
                            {
                                name: 'license',
                                title: "Tipo de licencia",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        label: 'Pública',
                                        id: 'public'
                                    },
                                    {
                                        label: 'Privada',
                                        id: 'private'
                                    }
                                ]
                            },

                        ]
                    } />}
                {update && <Form
                    path={'/technology?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre de la Tecnología",
                                type: 'text',
                            },
                            {
                                name: 'first_version',
                                title: "Año de Primera Versión",
                                type: 'text',
                            },
                            {
                                name: 'last_version',
                                title: "Año de Última Versión",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción",
                                type: 'textarea',
                            },
                            {
                                name: 'price',
                                title: "Tarifa",
                                type: 'text',
                            },
                            {
                                name: 'license',
                                title: "Tipo de licencia",
                                type: 'select',
                                columns: [
                                    {
                                        name: 'label',
                                        title: 'Nombre',
                                    }
                                ],
                                options: [
                                    {
                                        label: 'Pública',
                                        id: 'public'
                                    },
                                    {
                                        label: 'Privada',
                                        id: 'private'
                                    }
                                ]
                            },
                        ]
                    } />}
                <Widget title="Listado de Tecnologías">
                    <Table reload={tableKey} columns={
                        [
                            {
                                onPress: (data: any) => {
                                    setDestruction(data)
                                },
                                icon: 'las la-trash',
                                width: 50

                            },
                            {
                                onPress: (data: any) => {
                                    setUpdate(data)
                                },
                                icon: 'las la-pen',
                                width: 50

                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                width: 200,


                            },
                            {
                                name: 'first_version',
                                title: "Primera Versión",
                                width: 200,
                            },
                            {
                                name: 'last_version',
                                title: "Última Versión",
                                width: 200,
                            }

                        ]
                    }
                        method="get"
                        path="/technology"
                    />
                </Widget>

            </div>
        </>

    );
}

export default Technologys
