export const SET_USER = 'SET_USER';
export const CLEAR = 'CLEAR';
export const SET_SCHEME = 'SET_SCHEME';
export const SET_SCHEME_GITHUB = 'SET_SCHEME_GITHUB';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_REPOSITORY = 'SET_REPOSITORY';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_GITHUB = 'SET_PROFILE_GITHUB';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_LANGUAGES_GITHUB = 'SET_LANGUAGES_GITHUB';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_MODE = 'SET_MODE';
export const SET_THEME = 'SET_THEME';
export const SET_AUDIO = 'SET_AUDIO';
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const SET_ROLE = 'SET_ROLE';
export const SET_PROJECT = 'SET_PROJECT';