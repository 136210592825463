
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


type Props = {
    document: any,
    component: any
}

export default function AuditsChart(props: Props) {
    // audits
    const options = {
        indexAxis: 'y' as const,
        elements: {
            bar: {
                borderWidth: 0,
            },
        },
        legend: {
            display: false,
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'right' as const,
            },
            title: {
                display: false,
                text: 'Chart.js Horizontal Bar Chart',
            },
        },
    };

    let labels: any = [];
    let data: any = [];
    let backgroundColor: any = [];

    if (props?.document?.project?.audits) {
        // count audits with gravity |
        const audits1 = props.document.project.audits.filter((audit: any) => {
            if (audit.priority === '|') return audit;
            return false
        }
        );
        const audits2 = props.document.project.audits.filter((audit: any) => {
            if (audit.priority === '||') return audit;
            return false
        }
        );
        const audits3 = props.document.project.audits.filter((audit: any) => {
            if (audit.priority === '|||') return audit;
            return false
        }
        );
        if (audits1) {
            console.log('audu:', audits1.length)
            labels.push('Leve');
            data.push(audits1.length);
            backgroundColor.push('#7361a5');
        }
        if (audits2) {
            console.log('audu:', audits2.length)
            labels.push('Grave');
            data.push(audits2.length);
            backgroundColor.push('#a5619e');
        }
        if (audits3) {
            console.log('audu:', audits3.length)
            labels.push('Peligroso');
            data.push(audits3.length);
            backgroundColor.push('#a56179');
        }
    }
    console.log('doc:', props.document)
    return <div className='Audits-chart'>
        <p className='P'>Se han encontrado <b>{props.document.project.audits.length - 1} problemas</b> en el código facilitado por <b>{props.document?.project?.organization?.name}</b>. El número de errores localizados suele estar limitado a la calidad del código y a las horas invertidas por el equipo técnico en el análisis del código fuente. Por ello, tener menor o mayor número de problemas, no son un indicativo de la calidad del código. Generalmente las auditorias son detenidas al localizar errores indiscutibles o de gran gravedad.</p>
        <Bar
            height={50}
            options={options}
            data={{
                labels: labels,
                datasets: [{
                    data: data,
                    backgroundColor: backgroundColor,
                }]
            }} />
    </div>
}