import './tab.css'
import { useState } from 'react';

// function login props redux connect

interface DispatchProps {
    title: string
    errors: any
    inputs: any
}
type Props = DispatchProps

const Tab: React.FC<Props> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    let errorsCounter = 0
    if (props.errors) {
        if (props.inputs) {
            for (let i in props.inputs) {
                if (props.inputs[i].name && props.errors[props.inputs[i].name]) {
                    errorsCounter++
                }
            }
        }
    }

    return (< div className={open ? 'Tab Tab-active' : 'Tab Tab-desactive'} >
        <div className={open ? 'Tab-title Tab-title-active' : 'Tab-title '} onClick={() => setOpen(!open)}>
            {open ? <i className="las la-angle-up"></i> : <i className="las la-angle-down"></i>}
            {props.title}
            {errorsCounter > 0 && <span className="Tab-errors">{errorsCounter} errores</span>}
        </div>
        {
            <div className="Tab-content" style={{ display: open ? 'block' : 'none' }}>
                {props.children}
            </div>
        }


    </div >
    )
}

export default Tab
