

import { Details } from "../../../../componets/Details";
import { Widget } from "../../../../componets/Widget";

function Clients() {
    return (
        <>
            <div className="Widgets">
                <div className="Widgets">
                    <Widget title="Facturación del Año">
                        <Details
                            items={
                                [
                                    {
                                        title: "Enero Ingresos",
                                        detail: "Movimientos positivos",
                                        icon: "las la-home",
                                    },
                                    {
                                        title: "Gastos Movimientos negativos",
                                        detail: "Todo lo que entra",
                                        icon: "las la-home",
                                    },

                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Tesoria">
                        <Details
                            items={
                                [
                                    {
                                        title: "Enero",
                                        detail: "Dinero que debería haber en la cuenta",
                                        icon: "las la-home",
                                    },

                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Tesoria Captura">
                        <Details
                            items={
                                [
                                    {
                                        title: "Enero",
                                        detail: "Dinero que hay en la cuenta",
                                        icon: "las la-home",
                                    },

                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Ingresos y Gastos">
                        <Details
                            items={
                                [
                                    {
                                        title: "Enero",
                                        detail: "2",
                                        icon: "las la-home",
                                    },


                                ]
                            }
                        />

                    </Widget>


                </div>
                <div className="Widgets">
                    <Widget title="Facturas pendientes">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Facturas emitidas">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Facturas Retraso">
                        <Details
                            items={
                                [
                                ]
                            }
                        />

                    </Widget>



                </div>
                <div className="Widgets">
                    <Widget title="Gastos Tarta, gastos de servicio y gastos operativos">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Facturación Historica">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>




                </div><div className="Widgets">
                    <Widget title="Gasto en Salarios por Meses">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Empleados Contratados">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Sueldo Medio">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>




                </div>
                <div className="Widgets">
                    <Widget title="Comisiones por Meses">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Comisión Media por Empleado">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Comisiones históricas">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>




                </div>
                <div className="Widgets">
                    <Widget title="Impuestos por Meses IVA- IRPF-IS - Gasto de Seguridad social">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Impuestos por año">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>
                    <Widget title="Impuestos históricas">
                        <Details
                            items={
                                [


                                ]
                            }
                        />

                    </Widget>




                </div>
            </div>
        </>
    );
}

export default Clients
