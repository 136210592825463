


type Props = {
    pages: any,
    document: any
}

export default function P(props: Props) {

    return <div className='Card'>

        <div className="title">{props.document?.project?.name}</div>
        <div className="name">{props.document?.name}</div>
        <div className="code">{props.document?.id.substring(0, props.document?.id.length - 10)}</div>
    </div>
}