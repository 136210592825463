
import { useState } from 'react'
import { createPortal } from 'react-dom'

export const IFrame = ({
    children,
    ...props
}) => {
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (<div className='iframe'>
        <iframe title='Landing' id='iframe' {...props} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe></div>
    )
}