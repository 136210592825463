

import { Widget } from "../../../../componets/Widget";
import Table from "../../../../componets/Table";
import Header from "../../../../componets/Header";
import Form from "../../../../componets/Form";
import { useState } from "react";

function Products() {
    const [form, setForm] = useState(false)
    const [destruction, setDestruction] = useState<any>(false)
    const [tableKey, setTableKey] = useState('default')

    const [update, setUpdate] = useState<any>(false)


    return (

        <>
            <Header icon="las la-atom" title="Productos" description="Listado de Servicios de Alicunde Systems" buttons={[{
                text: "Nuevo",
                onClick: () => {
                    setForm(true)
                },
                icon: 'las la-plus'
            }]} />

            <div className="Widgets">
                {
                    destruction && <Form
                        method="delete"
                        header={
                            {
                                title: 'Eliminar Producto',
                                description: 'Esta seguro que desea eliminar el producto?',
                                icon: 'las la-trash'

                            }
                        }
                        path={`/product?id=${destruction.id}`}
                        backgroundClick={() => {
                            setDestruction(false)
                        }}
                        onSuccess={() => {
                            setTableKey(Math.random().toString(36).substring(7))
                        }}
                    />}
                {form && <Form
                    method="post"
                    path="/product"
                    header={
                        {
                            title: 'Nuevo Producto',
                            description: 'Agregue un nuevo producto',
                            icon: "las la-pen"
                        }
                    }
                    backgroundClick={() => {
                        setForm(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre del Producto",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción Comercial",
                                type: 'textarea',
                            },
                            {
                                name: 'minimum',
                                title: "Precio mínimo",
                                type: 'text',
                                placeholder: 'Coste del Desarrollo',
                            },


                        ]
                    } />}
                {update && <Form
                    path={'/product?id=' + update.id}
                    method={'put'}
                    default={update}
                    backgroundClick={() => {
                        setUpdate(false)
                    }}
                    onSuccess={() => {
                        setTableKey(Math.random().toString(36).substring(7))
                    }}
                    inputs={
                        [
                            {
                                name: 'name',
                                title: "Nombre del Producto",
                                type: 'text',
                            },
                            {
                                name: 'description',
                                title: "Descripción Comercial",
                                type: 'textarea',
                            },
                            {
                                name: 'minimum',
                                title: "Precio mínimo",
                                type: 'text',
                                placeholder: 'Coste del Desarrollo',
                            },
                        ]
                    } />}
                <Widget title="Listado de Productos">
                    <Table reload={tableKey} columns={
                        [
                            {
                                onPress: (data: any) => {
                                    setDestruction(data)
                                },
                                icon: 'las la-trash',
                                width: 50

                            },
                            {
                                onPress: (data: any) => {
                                    setUpdate(data)
                                },
                                icon: 'las la-pen',
                                width: 50

                            },
                            {
                                name: 'name',
                                title: "Nombre",
                                width: 200,


                            },
                            {
                                name: 'minimum',
                                title: "Precio Mínimo",
                                width: 200,
                                type: 'price'
                            }

                        ]
                    }
                        method="get"
                        path="/product"
                    />
                </Widget>

            </div>
        </>

    );
}

export default Products
