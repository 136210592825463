import './excel.css'
import { connect } from "react-redux";
import { useEffect, useState } from 'react';
import { Method } from 'axios';


const mapStateToProps = (state: any, ownProps: any) => (state)

const mapDispatchToProps = {

}
// function login props redux connect

interface DispatchProps {
    user: any,
    defaultValue: any,
    project: any,
    environment: string,
    onUpdate: (item: any) => void
    columns: {
        onPress?: (data: any) => void
        width: number
        name: string,
        title?: string
        icon?: string
    }[],
    options?: any,
    reload: string,
    path?: string,
    method?: Method,
    items?: any[]
}
type Props = DispatchProps

const Select: React.FC<Props> = (props) => {
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        if (props.defaultValue) {
            let json = JSON.parse(props.defaultValue)
            setData(json)
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div className='Excel' >
        {data && data.length > 0 &&
            <ol>
                {
                    props.columns.map((column: any, index: number) => {
                        return <li key={index} style={{ minWidth: column.width, maxWidth: column.width }}>
                            {
                                column.title
                            }
                        </li>
                    }
                    )
                }
            </ol>}
        <ul>
            {
                data.map((item: any, index: number) => {
                    let indexx = index
                    return <li>
                        <div className='remove' onClick={() => {
                            let newData = data.filter((item: any, index: number) => {
                                return index !== indexx
                            })
                            setData(newData)
                            props.onUpdate(JSON.stringify(newData))
                        }}><i className='las la-times'></i></div>
                        {
                            props.columns.map((column: any, index: number) => {
                                return <div style={{ minWidth: column.width, maxWidth: column.width }}>
                                    <input onChange={(e) => {
                                        const dataClone = [...data]
                                        console.log(column.name)
                                        dataClone[indexx][column.name] = e.target.value
                                        setData(dataClone)

                                        props.onUpdate(dataClone)
                                    }} value={item[column.name]} />
                                </div>
                            })
                        }
                    </li>
                })
            }
        </ul>
        <div className='add' onClick={() => {
            const dataClone = [...data]
            dataClone.push({})
            setData(dataClone)
            props.onUpdate(dataClone)
        }}>
            + Nueva fila
        </div>
    </div>
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(Select)

