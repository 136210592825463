


type Props = {
    document: any
}

export default function Files(props: Props) {



    return <div className='Files'>
        <ul>
            {
                props?.document?.project?.files?.map((file: any, index: number) => {
                    return <li key={index}>
                        <b>{file.name}</b>
                        <b>{file.description}</b>
                        <em>{file.extension}</em>
                        <span>{file.received}</span>
                        <p>{file.author.name} {file.author.surname}</p>
                    </li>
                })
            }
        </ul>
    </div>
}
